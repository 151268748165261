import React, {
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useState,
} from 'react';
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
} from 'react-calendar-timeline';
import { ConfirmDialog } from '../../ConfirmDialog';
// import "react-calendar-timeline/lib/Timeline.css";
import '../../../../css/reactCalenderTimeLine.css';
import moment from 'moment';
import 'moment/locale/ja';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ScheduleEditDialog } from '../../ScheduleEditDialog';
import Tooltip from '@material-ui/core/Tooltip';
import {
  TransmissionTypes,
  DriveSystem,
  ShiftLever,
  ParkingBrake,
  WithOrWithout,
  KeyType,
  WeightType,
  FreightType,
} from '../../../constant/common';
import Modal from '@material-ui/core/Modal';
import LockIcon from '@material-ui/icons/LockTwoTone';
import ErrorOutlineOutlined from '@material-ui/icons/ErrorOutlineOutlined';
import CarListItem from '../../calenderComponents/CarListItem';
import withWidth from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import ReactResizeDetector from 'react-resize-detector';
import isTouchDevice from 'is-touch-device';
import { isThisScheduleConflicting } from '../../../validater/scheduleValidater';
import { FilterList, Sort } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { FilterFacilityDialog } from '../../FilterFacilityDialog';
import IconButton from '@material-ui/core/IconButton';
import { SortFacilityDialog } from '../../SortFacilityDialog';

const appBarHeight = 48;
export const BUFFER = 3;

moment.locale('ja');

const Schedule = (props) => {
  const {
    previousCanvasTimeStart,
    previousCanvasTimeEnd,
    updateCanvasTime,
    getSchedules,
    userId,
    facilities,
    customers,
    schedules,
    staffs,
    setting,
    rentalReasonMasters,
    isLoading,
    newSchedule,
    editSchedule,
    addSchedule,
    saveSchedule,
    deleteSchedule,
    confirmDeleteSchedule,
    cancelDeleteSchedule,
    deleteConfirmId,
    editedSchedule,
    changeSelectedCustomer,
    changeSelectedFacility,
    changeFreeFirstName,
    changeFreeLastName,
    changeCustomersCar,
    changeSelectedStaff,
    cancelEditSchedule,
    changeSelectedRentalReason,
    changeStart,
    changeEnd,
    changeMemo,
    changeWarning,
    isFilterFacilityDialogOpen,
    closeFilterFacilityDialog,
    openFilterFacilityDialog,
    filterState,
    changeFilterState,
    isSortFacilityDialogOpen,
    closeSortFacilityDialog,
    openSortFacilityDialog,
    sortState,
    changeSortState,
    defaultTimeStart,
    defaultTimeEnd,
    changeDefaultStartEnd,
    changeRequestedCarType,
    changeRequestedWeightType,
    changeRequestedFreightType,
    changeRequestedDriveSystem,
    changeRequestedShiftLever,
    changeRequestedParkingBrake,
    changeRequestedKeyType,
    changeRequestedWithAirConditioner,
    width,
    openScheduleCardContext,
    closeScheduleCardContext,
    itemContextObject,
    openCanvasContext,
    closeCanvasContext,
    canvasContextObject,
    copySchedule,
    copiedSchedule,
    messages,
  } = props;

  const reference = useRef(null);

  const useStyle = makeStyles((theme) => ({
    context: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      top: canvasContextObject === null ? 0 : canvasContextObject.pageY,
      left: canvasContextObject === null ? 0 : canvasContextObject.pageX,
    },
    scheduleContext: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      top: itemContextObject === null ? 0 : itemContextObject.pageY,
      left: itemContextObject === null ? 0 : itemContextObject.pageX,
    },
    boxOnHeader: {
      position: 'fixed',
      top: appBarHeight,
      zIndex: 1000,
      width: '100%',
      backgroundColor: 'white',
    },
    boxOnHeaderPart: {
      height: '100%',
    },
    rentalReasonLegendBox: {
      marginLeft: 10,
      marginTop: 16,
    },
    rentalReasonLegendItem: {
      height: 30,
      textAlign: 'center',
      padding: 4,
      marginLeft: 4,
    },
  }));

  const classes = useStyle();

  const unScrappedFacilities = facilities.filter((f) => !f.isScrapped);
  const unRetireStaffs = staffs.filter((s) => !s.retire);
  const [boxSizeOnHeader, setBoxSizeOnHeader] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    reference.current.addEventListener(
      'touchstart',
      (e) => {
        if (e.touches.length >= 2) {
          e.preventDefault();
        }
      },
      { passive: false }
    );
  }, []);

  const groups = useMemo(
    () =>
      unScrappedFacilities
        .filter((f) =>
          filterState.transmissionType === -1
            ? true
            : f.carType === filterState.transmissionType
        )
        .filter((f) =>
          filterState.weightType === -1
            ? true
            : f.weightType === filterState.weightType
        )
        .filter((f) =>
          filterState.use === -1 ? true : f.use === filterState.use
        )
        .sort((a, b) => {
          if (sortState.sortType === 1) {
            if (
              a.registrationNumberNumber === '' &&
              b.registrationNumberNumber === ''
            )
              return 0;
            else if (
              a.registrationNumberNumber === '' &&
              b.registrationNumberNumber !== ''
            )
              return 1;
            else if (
              a.registrationNumberNumber !== '' &&
              b.registrationNumberNumber === ''
            )
              return -1;

            const numberA = Number(a.registrationNumberNumber);
            const numberB = Number(b.registrationNumberNumber);
            return numberA - numberB;
          }

          if (sortState.sortType === 2) {
            return a.weightType - b.weightType;
          }

          if (Number(a.no) < Number(b.no)) {
            return -1;
          }
          if (Number(a.no) > Number(b.no)) {
            return 1;
          }
          return 0;
        })
        .map((f) => ({
          id: f.facilityId,
          facility: f,
          title: f.name,
          expireDate: f.expireDate,
        })),
    [
      filterState.transmissionType,
      filterState.weightType,
      filterState.use,
      sortState.sortType,
      unScrappedFacilities,
    ]
  );

  const touchDevice = isTouchDevice();

  const items = useMemo(
    () =>
      schedules.map((s) => {
        const customer = customers.find((st) => st.customerId === s.customerId);
        const facility = facilities.find((f) => f.facilityId === s.facilityId);
        const staff = staffs.find((st) => st.staffId === s.staffId);
        const rentalReason = rentalReasonMasters.find(
          (m) => m.code === s.rentalReason
        );
        const requestedCarType = TransmissionTypes.find(
          (t) => t.value === s.requestedCarType
        );
        const requestedWeightType = WeightType.find(
          (t) => t.value === s.requestedWeightType
        );
        const requestedFreightType = FreightType.find(
          (t) => t.value === s.requestedFreightType
        );
        const requestedDriveSystem = DriveSystem.find(
          (d) => d.value === s.requestedDriveSystem
        );
        const requestedShiftLever = ShiftLever.find(
          (l) => l.value === s.requestedShiftLever
        );
        const requestedParkingBrake = ParkingBrake.find(
          (b) => b.value === s.requestedParkingBrake
        );
        const requestedKeyType = KeyType.find(
          (k) => k.value === s.requestedKeyType
        );
        const requestedWithAirConditioner = WithOrWithout.find(
          (w) => w.value === s.requestedWithAirConditioner
        );

        return {
          id: s.scheduleId,
          group: s.facilityId,
          facilityName: typeof facility === 'undefined' ? '' : facility.name,
          facility: typeof facility === 'undefined' ? null : facility,
          expireDate:
            typeof facility === 'undefined' ? '' : facility.expireDate,
          customerId: s.customerId,
          title:
            s.customerId === -1
              ? `${s.freeLastName} ${s.freeFirstName}`
              : typeof customer === 'undefined'
              ? ''
              : customer.name,
          freeFirstName: s.freeFirstName,
          freeLastName: s.freeLastName,
          customersCar: s.customersCar,
          staffId: s.staffId,
          staffName: typeof staff === 'undefined' ? '' : staff.name,
          start: s.start,
          end: s.end,
          memo: s.memo,
          rentalReason: s.rentalReason,
          rentalReasonName:
            typeof rentalReason === 'undefined' ? '' : rentalReason.name,
          warning: s.warning,
          canMove: touchDevice
            ? setting.isCardMovableSp && !s.warning
            : setting.isCardMovablePc && !s.warning,
          canResize: touchDevice
            ? setting.isCardResizableSp && !s.warning
              ? 'both'
              : false
            : setting.isCardResizablePc && !s.warning
            ? 'both'
            : false,
          requestedCarType:
            requestedCarType === undefined ? '' : requestedCarType.key,
          requestedWeightType:
            requestedWeightType === undefined ? '' : requestedWeightType.key,
          requestedFreightType:
            requestedFreightType === undefined ? '' : requestedFreightType.key,
          requestedDriveSystem:
            requestedDriveSystem === undefined ? '' : requestedDriveSystem.key,
          requestedShiftLever:
            requestedShiftLever === undefined ? '' : requestedShiftLever.key,
          requestedParkingBrake:
            requestedParkingBrake === undefined
              ? ''
              : requestedParkingBrake.key,
          requestedKeyType:
            requestedKeyType === undefined ? '' : requestedKeyType.key,
          requestedWithAirConditioner:
            requestedWithAirConditioner === undefined
              ? ''
              : requestedWithAirConditioner.key,
        };
      }),
    [
      schedules,
      customers,
      facilities,
      staffs,
      rentalReasonMasters,
      touchDevice,
      setting.isCardMovableSp,
      setting.isCardMovablePc,
      setting.isCardResizableSp,
      setting.isCardResizablePc,
    ]
  );

  const minutePerUnit =
    setting.dragSnapUnit === 'minute'
      ? 1
      : setting.dragSnapUnit === 'hour'
      ? 60
      : 60 * 24;
  const dragSnapMinute = setting.dragSnapValue * minutePerUnit;

  const [itemIdInDoubleTap, setItemIdInDoubleTap] = useState(null);

  const itemRenderer = useCallback(
    ({ item, itemContext, getItemProps, getResizeProps }) => {
      const {
        left: leftResizeProps,
        right: rightResizeProps,
      } = getResizeProps();
      const car = item.facility;
      const format = 'YYYY.M.D(dd) H:m';
      const schedule = schedules.find((s) => s.scheduleId === item.id);

      const rentalReasonMaster = rentalReasonMasters.find(
        (r) => r.code === item.rentalReason
      );

      const color =
        item.expireDate < item.end
          ? 'crimson'
          : rentalReasonMaster === undefined
          ? 'grey'
          : rentalReasonMaster.colorCode;

      const style = {
        border: '1px solid ' + color,
        background: color,
        opacity: itemContext.selected ? 0.5 : 1,
        color: 'black',
      };

      const itemProps = getItemProps({
        style: style,
        onTouchStart: () => {
          if (itemIdInDoubleTap === item.id) {
            onItemDoubleClick();
            setItemIdInDoubleTap(null);
          } else {
            setItemIdInDoubleTap(item.id);
          }
          setTimeout(function () {
            setItemIdInDoubleTap(null);
          }, 500);
        },
      });

      const onItemDoubleClick = () => {
        if (typeof schedule === 'undefined') {
          return;
        }

        editSchedule(schedule);
      };

      const cardContents = [
        {
          show: setting.showStaffInCard,
          content: setting.labelOfStaffInCard + item.staffName,
        },
        {
          show: setting.showCustomerInCard,
          content: setting.labelOfCustomerInCard + item.title,
        },
        {
          show: setting.showCustomersCarInCard,
          content: setting.labelOfCustomersCarInCard + item.customersCar,
        },
      ];

      const itemBox =
        <div
          key={itemProps.key}
          ref={itemProps.ref}
          className={itemProps.className}
          onMouseDown={itemProps.onMouseDown}
          onMouseUp={itemProps.onMouseUp}
          onTouchStart={itemProps.onTouchStart}
          onTouchEnd={itemProps.onTouchEnd}
          onDoubleClick={onItemDoubleClick}
          onContextMenu={e => {
            openScheduleCardContext({ itemId: item.id, pageX: e.pageX, pageY: e.pageY });
            e.preventDefault();
          }}
          style={{ ...itemProps.style, borderRadius: 0 }}
        >
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

          {(itemContextObject !== null && itemContextObject.itemId === item.id) &&
          <Modal
            open={true}
            onClose={closeScheduleCardContext}
            BackdropProps={{
              onMouseDown: closeScheduleCardContext
            }}
          >
            <div
              className={classes.scheduleContext}
              onClick={() => {
                copySchedule(schedules.find(s => s.scheduleId === item.id));
                closeScheduleCardContext();
              }}>
              <MenuItem>
                コピー
              </MenuItem>
            </div>
          </Modal>
          }

          <div className="rct-item-content" style={{ lineHeight: 1.1, fontSize: 14, color: 'black' }}>
            {cardContents.filter(c => c.show).map((c, i) => <span key={c.content + i}>{i > 0 && <br/>}{c.content}</span>)}
            {item.warning &&
            <span style={{ verticalAlign: 'middle' }}>
            <LockIcon style={{ color: 'black', fontSize: 14 }}/>
          </span>}
            {isThisScheduleConflicting(schedule, schedules) &&
            <span style={{ verticalAlign: 'middle' }}>
            <ErrorOutlineOutlined style={{ color: 'red', fontSize: 14 }}/>
          </span>
            }
          </div>

          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}

        </div>;

      if (!setting.showScheduleHintInCard) return itemBox;

      const title = (
        <span
          key={item.id}
          style={{ fontSize: 16, lineHeight: '20px', whiteSpace: 'pre-wrap' }}
        >
          【担当者】{item.staffName}
          <br />
          【お客様名】{item.title}
          <br />
          【車種】{item.customersCar}
          <br />
          【貸出理由】{item.rentalReasonName}
          <br />
          【貸出期間】
          <div style={{ paddingLeft: 32 }}>
            (開始){item.start.format(format)}
            <br />
            (終了){item.end.format(format)}
            <br />
          </div>
          【代車情報】
          <div style={{ paddingLeft: 32 }}>
            {`${car.no}. ${car.name}`}
            <br />
            {car.registrationNumberPlace +
              car.registrationNumberMobileType +
              car.registrationNumberMobileUses +
              car.registrationNumberNumber}
          </div>
          【貸出希望】
          <div style={{ paddingLeft: 32 }}>
            {`${item.requestedWeightType} ${item.requestedFreightType} ${item.requestedCarType} ${item.requestedDriveSystem}`}
            <br />
            {`${item.requestedShiftLever} ${item.requestedParkingBrake} ${item.requestedKeyType}`}
            <br />
            {item.requestedWithAirConditioner === ''
              ? ''
              : 'エアコン' + item.requestedWithAirConditioner}
          </div>
          【メモ】
          <br />
          <div style={{ color: 'red', paddingLeft: 32 }}>{item.memo}</div>
        </span>
      );

      return <Tooltip title={title}>{itemBox}</Tooltip>;
    },
    [
      classes.scheduleContext,
      closeScheduleCardContext,
      copySchedule,
      editSchedule,
      itemContextObject,
      itemIdInDoubleTap,
      openScheduleCardContext,
      rentalReasonMasters,
      schedules,
      setting.labelOfCustomerInCard,
      setting.labelOfCustomersCarInCard,
      setting.labelOfStaffInCard,
      setting.showCustomerInCard,
      setting.showCustomersCarInCard,
      setting.showScheduleHintInCard,
      setting.showStaffInCard,
    ]
  );

  const keys = {
    groupIdKey: 'id',
    groupTitleKey: 'title',
    groupRightTitleKey: 'rightTitle',
    itemIdKey: 'id',
    itemTitleKey: 'title',
    itemDivTitleKey: 'title',
    itemGroupKey: 'group',
    itemTimeStartKey: 'start',
    itemTimeEndKey: 'end',
  };

  const deleteTargetItem = items.filter((i) => i.id === deleteConfirmId);

  const datetimeFormat = 'YYYY年MM月DD日(ddd) HH:mm ';

  const onItemMove = useCallback(
    (scheduleId, start, groupIndex) => {
      const newStart = moment(start).local();
      const schedule = schedules.find((s) => s.scheduleId === scheduleId);
      schedule.facilityId = groups[groupIndex].id;
      schedule.end = moment(newStart + schedule.end - schedule.start);
      schedule.start = newStart;
      saveSchedule(schedule);
    },
    [schedules, groups, saveSchedule]
  );

  const onItemResize = useCallback(
    (scheduleId, time, edge) => {
      const newTime = moment(time).local();
      const schedule = schedules.find((s) => s.scheduleId === scheduleId);

      schedule.start = edge === 'left' ? newTime : schedule.start;
      schedule.end = edge === 'left' ? schedule.end : newTime;

      saveSchedule(schedule);
    },
    [schedules, saveSchedule]
  );

  const onCanvasDoubleClick = useCallback(
    (facilityId, start, e) => {
      const facility = unScrappedFacilities.find(
        (f) => f.facilityId === facilityId
      );
      if (typeof facility === 'undefined') {
        return;
      }

      const startTime = moment(start).local().startOf('day');
      const endTime = moment(start).local().startOf('day').add(1, 'days');
      newSchedule(
        userId,
        facilityId,
        facility.name,
        startTime,
        endTime,
        setting.checkWarningAsInitialValue
      );
    },
    [
      newSchedule,
      setting.checkWarningAsInitialValue,
      unScrappedFacilities,
      userId,
    ]
  );

  const moveResizeValidator = (action, item, time, resizeEdge) => {
    const thisSchedule = items.find((i) => i.id === item.id);
    const currentFacility = unScrappedFacilities.find(
      (f) => f.facilityId === thisSchedule.group
    );
    const otherSchedules = items.filter(
      (i) => i.group === currentFacility.facilityId && i.id !== item.id
    );
    const span = thisSchedule.end.valueOf() - thisSchedule.start.valueOf();
    const newEnd = time + span;

    if (action === 'move') {
      if (
        otherSchedules.some(
          (i) => i.start.valueOf() < time && time < i.end.valueOf()
        )
      ) {
        const conflictingSchedule = otherSchedules.find(
          (i) => i.start.valueOf() < time && time < i.end.valueOf()
        );
        return conflictingSchedule.end.valueOf();
      } else if (
        otherSchedules.some(
          (i) => i.start.valueOf() < newEnd && newEnd < i.end.valueOf()
        )
      ) {
        const conflictingSchedule = otherSchedules.find(
          (i) => i.start.valueOf() < newEnd && newEnd < i.end.valueOf()
        );
        return conflictingSchedule.start.valueOf() - span;
      } else if (
        otherSchedules.some(
          (i) => time < i.start.valueOf() && i.end.valueOf() < newEnd
        )
      ) {
        const conflictingSchedule = otherSchedules.find(
          (i) => time < i.start.valueOf() && i.end.valueOf() < newEnd
        );
        const result =
          Math.abs(time - conflictingSchedule.start.valueOf()) >
          Math.abs(newEnd - conflictingSchedule.end.valueOf())
            ? conflictingSchedule.start.valueOf() - span
            : conflictingSchedule.end.valueOf();
        return result;
      }
      return time;
    } else if (action === 'resize') {
      if (
        otherSchedules.some(
          (i) => i.start.valueOf() < time && time < i.end.valueOf()
        )
      ) {
        const conflictingSchedule = otherSchedules.find(
          (i) => i.start.valueOf() < time && time < i.end.valueOf()
        );
        const newTime =
          resizeEdge === 'right'
            ? conflictingSchedule.start.valueOf()
            : conflictingSchedule.end.valueOf();
        return newTime;
      } else if (
        resizeEdge === 'right' &&
        otherSchedules.some(
          (i) =>
            thisSchedule.start.valueOf() < i.start.valueOf() &&
            i.end.valueOf() < time
        )
      ) {
        const conflictingSchedule = otherSchedules.find(
          (i) =>
            thisSchedule.start.valueOf() < i.start.valueOf() &&
            i.end.valueOf() < time
        );
        const newTime = conflictingSchedule.start.valueOf();
        return newTime;
      } else if (
        resizeEdge === 'left' &&
        otherSchedules.some(
          (i) =>
            time < i.start.valueOf() &&
            i.end.valueOf() < thisSchedule.end.valueOf()
        )
      ) {
        const conflictingSchedule = otherSchedules.find(
          (i) =>
            time < i.start.valueOf() &&
            i.end.valueOf() < thisSchedule.end.valueOf()
        );
        const newTime = conflictingSchedule.end.valueOf();
        return newTime;
      }
      return time;
    }
  };

  const groupRenderer = useCallback(
    ({ group, isRightSidebar }) => {
      if (isRightSidebar) return <></>;

      const car = group.facility;

      const carBox = (
        <div style={{ height: '100%', margin: '0 -4px  0 -4px' }}>
          <CarListItem car={car} />
        </div>
      );

      if (!setting.showCarHintInCalendar) return carBox;

      const driveSystem = DriveSystem.find((s) => s.value === car.driveSystem);
      const driveSystemName =
        driveSystem === undefined ? '--' : driveSystem.key;
      const transmissionType = TransmissionTypes.find(
        (t) => t.value === car.carType
      );
      const transmissionTypeName =
        transmissionType === undefined ? '--' : transmissionType.key;
      const shiftLever = ShiftLever.find((l) => l.value === car.shiftLever);
      const shiftLeverName = shiftLever === undefined ? '--' : shiftLever.key;
      const parkingBrake = ParkingBrake.find(
        (b) => b.value === car.parkingBrake
      );
      const parkingBrakeName =
        parkingBrake === undefined ? '--' : parkingBrake.key;
      const withAirConditioner = WithOrWithout.find(
        (w) => w.value === car.withAirConditioner
      );
      const withAirConditionerName =
        withAirConditioner === undefined ? '--' : withAirConditioner.key;
      const keyType = KeyType.find((k) => k.value === car.keyType);
      const keyTypeName = keyType === undefined ? '--' : keyType.key;
      const carNumber =
        car.registrationNumberPlace +
        car.registrationNumberMobileType +
        car.registrationNumberMobileUses +
        car.registrationNumberNumber;

      const intel = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {
        era: 'long',
      });
      const expireDate = intel.format(car.expireDate.toDate());
      const firstRegistrationDate =
        car.firstRegistrationDate === null
          ? ''
          : intel.format(car.firstRegistrationDate.toDate());

      const title = (
        <span
          key={car.id}
          style={{ fontSize: 16, lineHeight: '20px', whiteSpace: 'pre-wrap' }}
        >
          【代車番号】{car.no}
          <br />
          【車名】{car.name}
          <br />
          【登録番号】{carNumber}
          <br />
          【初年度】{firstRegistrationDate}
          <br />
          【車検期限】{expireDate}
          <br />
          【駆動方式】{driveSystemName}
          <br />
          【変速機】{transmissionTypeName}
          <br />
          【シフトレバー】{shiftLeverName}
          <br />
          【パーキングブレーキ】{parkingBrakeName}
          <br />
          【エアコン】{withAirConditionerName}
          <br />
          【キータイプ】{keyTypeName}
          <br />
          【メモ】
          <br />
          <div style={{ color: 'red', paddingLeft: 32 }}>{car.memo}</div>
        </span>
      );

      return <Tooltip title={title}>{carBox}</Tooltip>;
    },
    [setting.showCarHintInCalendar]
  );

  const sidebarRenderer = useCallback(
    ({ getRootProps, data }) => {
      const isLargeWindow = width === 'lg' || width === 'xl';
      return (
        <div
          {...getRootProps()}
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 10,
            color: 'white',
            width: isLargeWindow ? 350 : 120,
          }}
        >
          {isLargeWindow ? (
            <>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FilterList />}
                onClick={() => openFilterFacilityDialog()}
                size="small"
              >
                絞込み
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Sort />}
                onClick={() => openSortFacilityDialog()}
                size="small"
              >
                並べ替え
              </Button>
            </>
          ) : (
            <>
              <IconButton
                color="inherit"
                onClick={() => openFilterFacilityDialog()}
              >
                <FilterList />
              </IconButton>
              <IconButton
                color="inherit"
                onClick={() => openSortFacilityDialog()}
              >
                <Sort />
              </IconButton>
            </>
          )}

          <FilterFacilityDialog
            {...{
              isFilterFacilityDialogOpen,
              closeFilterFacilityDialog,
              filterState,
              changeFilterState,
            }}
          />
          <SortFacilityDialog
            {...{
              sortState,
              changeSortState,
              isSortFacilityDialogOpen,
              closeSortFacilityDialog,
            }}
          />
        </div>
      );
    },
    [
      width,
      isFilterFacilityDialogOpen,
      closeFilterFacilityDialog,
      filterState,
      changeFilterState,
      sortState,
      changeSortState,
      isSortFacilityDialogOpen,
      closeSortFacilityDialog,
      openFilterFacilityDialog,
      openSortFacilityDialog,
    ]
  );

  const primaryDateHeaderFormatter = useCallback(
    ([startTime, endTime], unit, labelWidth, formatOptions) => {
      const formatOption = {
        yearShort: 'YY',
        yearLong: 'YYYY',
        monthShort: 'MM/YY',
        monthMedium: 'MM/YYYY',
        monthMediumLong: 'MM/YYYY',
        monthLong: 'MM/YYYY',
        dayShort: 'L(dd)',
        dayLong: 'L(dd)',
        hourShort: 'HH',
        hourMedium: 'HH:00',
        hourMediumLong: 'L, HH:00',
        hourLong: 'dddd, LL, HH:00',
        time: 'LLL',
      };

      if (unit === 'year') {
        return startTime.format(
          labelWidth < 46 ? formatOption.yearShort : formatOption.yearLong
        );
      } else if (unit === 'month') {
        return startTime.format(
          labelWidth < 65
            ? formatOption.monthShort
            : labelWidth < 75
            ? formatOption.monthMedium
            : labelWidth < 120
            ? formatOption.monthMediumLong
            : formatOption.monthLong
        );
      } else if (unit === 'day') {
        return startTime.format(
          labelWidth < 150 ? formatOption.dayShort : formatOption.dayLong
        );
      } else if (unit === 'hour') {
        return startTime.format(
          labelWidth < 50
            ? formatOption.hourShort
            : labelWidth < 130
            ? formatOption.hourMedium
            : labelWidth < 150
            ? formatOption.hourMediumLong
            : formatOption.hourLong
        );
      } else {
        return startTime.format(formatOption.time);
      }
    },
    []
  );

  const dateHeaderFormatter = useCallback(
    ([startTime, endTime], unit, labelWidth, formatOptions) => {
      const formatOption = {
        yearShort: 'YY',
        yearLong: 'YYYY',
        monthShort: 'MM',
        monthMedium: 'MMM',
        monthLong: 'MMM',
        dayShort: 'D',
        dayMedium: 'dd D',
        dayMediumLong: 'ddd, Do',
        dayLong: 'dddd, Do',
        hourShort: 'A',
        hourLong: 'A',
        minuteShort: 'mm',
        minuteLong: 'HH:mm',
      };

      if (unit === 'year') {
        return startTime.format(
          labelWidth < 46 ? formatOption.yearShort : formatOption.yearLong
        );
      } else if (unit === 'month') {
        return startTime.format(
          labelWidth < 37
            ? formatOption.monthShort
            : labelWidth < 85
            ? formatOption.monthMedium
            : formatOption.monthLong
        );
      } else if (unit === 'day') {
        return startTime.format(
          labelWidth < 47
            ? formatOption.dayShort
            : labelWidth < 80
            ? formatOption.dayMedium
            : labelWidth < 120
            ? formatOption.dayMediumLong
            : formatOption.dayLong
        );
      } else if (unit === 'hour') {
        return startTime.format(
          labelWidth < 50 ? formatOption.hourShort : formatOption.hourLong
        );
      } else if (unit === 'minute') {
        return startTime.format(
          labelWidth < 60 ? formatOption.minuteShort : formatOption.minuteLong
        );
      } else {
        return startTime.get(unit);
      }
    },
    []
  );

  const onBoundsChange = useCallback(
    (start, end) => {
    if (setting.settingId === -1) return;
    const invisibleCanvasWidth =
      ((previousCanvasTimeEnd - previousCanvasTimeStart) * (BUFFER - 1)) /
      BUFFER /
      2;
    const difference = Math.abs(previousCanvasTimeStart - start);
    const differenceRate = difference / invisibleCanvasWidth;
    if (differenceRate < 0.5) return;

    console.log({ start: moment(start).format(), end: moment(end).format() });
    updateCanvasTime(start, end);
    getSchedules(moment(start), moment(end));
  },
  [
    getSchedules,
    updateCanvasTime,
    previousCanvasTimeEnd,
    previousCanvasTimeStart,
    setting.settingId,
  ]
);

  const carBoxHeight =
    16 *
    (Number(setting.showStaffInCard) +
      Number(setting.showCustomerInCard) +
      Number(setting.showCustomersCarInCard));

  return (
    <div ref={reference}>
      {groups.length > 0 && setting.settingId > -1 ? (
        <>
          {isLoading ? (
            <CircularProgress
              color="primary"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                zIndex: 1400,
              }}
            />
          ) : (
            false
          )}

          {deleteConfirmId > -1 && (
            <ConfirmDialog
              title="確認"
              content={
                <span>
                  以下のスケジュールを削除します。
                  <br />
                  よろしいですか？
                  <br />
                  {deleteTargetItem.map((i) => (
                    <span key={i.id}>
                      代車:{i.facilityName}
                      <br />
                      担当者:{i.staffName}
                      <br />
                      お客様:{i.title}
                      <br />
                      <br />
                      期間:{i.start.format(datetimeFormat)}&nbsp;〜&nbsp;
                      {i.end.format(datetimeFormat)}
                    </span>
                  ))}
                </span>
              }
              cancelButtonText="キャンセル"
              okButtonText="OK"
              isOpen={deleteConfirmId > -1}
              ok={(e) => deleteSchedule(userId, deleteTargetItem[0].id)}
              cancel={(e) => cancelDeleteSchedule(deleteTargetItem.id)}
            />
          )}

          {editedSchedule !== null && editedSchedule.scheduleId >= 0 && (
            <ScheduleEditDialog
              title="スケジュール編集"
              userId={userId}
              isOpen={editedSchedule !== null && editedSchedule.scheduleId >= 0}
              schedule={editedSchedule}
              facility={
                editedSchedule !== null
                  ? facilities.find(
                      (f) => f.facilityId === editedSchedule.facilityId
                    )
                  : null
              }
              facilities={unScrappedFacilities}
              changeSelectedFacility={changeSelectedFacility}
              setting={setting}
              rentalReasonMasters={rentalReasonMasters}
              changeSelectedRentalReason={changeSelectedRentalReason}
              changeStart={changeStart}
              changeEnd={changeEnd}
              customers={customers}
              changeSelectedCustomer={changeSelectedCustomer}
              changeFreeFirstName={changeFreeFirstName}
              changeFreeLastName={changeFreeLastName}
              changeCustomersCar={changeCustomersCar}
              staffs={unRetireStaffs}
              changeSelectedStaff={changeSelectedStaff}
              cancel={cancelEditSchedule}
              saveSchedule={saveSchedule}
              confirmDeleteSchedule={confirmDeleteSchedule}
              addSchedule={null}
              changeMemo={changeMemo}
              changeWarning={changeWarning}
              {...{
                changeRequestedCarType,
                changeRequestedWeightType,
                changeRequestedFreightType,
                changeRequestedDriveSystem,
                changeRequestedShiftLever,
                changeRequestedParkingBrake,
                changeRequestedKeyType,
                changeRequestedWithAirConditioner,
              }}
              copySchedule={copySchedule}
              copiedSchedule={copiedSchedule}
              messages={messages}
            />
          )}

          {editedSchedule !== null && editedSchedule.scheduleId < 0 && (
            <ScheduleEditDialog
              title="新規スケジュール"
              userId={userId}
              isOpen={editedSchedule !== null && editedSchedule.scheduleId < 0}
              schedule={editedSchedule}
              facility={
                editedSchedule !== null
                  ? facilities.find(
                      (f) => f.facilityId === editedSchedule.facilityId
                    )
                  : null
              }
              facilities={unScrappedFacilities}
              changeSelectedFacility={changeSelectedFacility}
              setting={setting}
              rentalReasonMasters={rentalReasonMasters}
              changeSelectedRentalReason={changeSelectedRentalReason}
              changeStart={changeStart}
              changeEnd={changeEnd}
              customers={customers}
              changeSelectedCustomer={changeSelectedCustomer}
              changeFreeFirstName={changeFreeFirstName}
              changeFreeLastName={changeFreeLastName}
              changeCustomersCar={changeCustomersCar}
              staffs={unRetireStaffs}
              changeSelectedStaff={changeSelectedStaff}
              cancel={cancelEditSchedule}
              addSchedule={addSchedule}
              confirmDeleteSchedule={null}
              saveSchedule={null}
              changeMemo={changeMemo}
              changeWarning={changeWarning}
              {...{
                changeRequestedCarType,
                changeRequestedWeightType,
                changeRequestedFreightType,
                changeRequestedDriveSystem,
                changeRequestedShiftLever,
                changeRequestedParkingBrake,
                changeRequestedKeyType,
                changeRequestedWithAirConditioner,
              }}
              copySchedule={null}
              copiedSchedule={copiedSchedule}
              messages={messages}
            />
          )}

          {canvasContextObject !== null && (
            <Modal
              open={canvasContextObject !== null}
              onClose={closeCanvasContext}
              BackdropProps={{
                onMouseDown: closeCanvasContext,
              }}
            >
              <div className={classes.context}>
                <MenuItem
                  disabled={copiedSchedule === null}
                  onClick={() => {
                    const newFacility = facilities.find(
                      (f) => f.facilityId === canvasContextObject.group
                    );
                    const startTime = moment(canvasContextObject.time).startOf(
                      'day'
                    );
                    const endTime = moment(canvasContextObject.time)
                      .startOf('day')
                      .add(1, 'days');
                    addSchedule({
                      ...copiedSchedule,
                      facilityId: newFacility.facilityId,
                      facilityName: newFacility.name,
                      start: startTime,
                      end: endTime,
                    });
                    closeCanvasContext();
                  }}
                >
                  貼り付け
                </MenuItem>
              </div>
            </Modal>
          )}

          <div>
            <div className={classes.boxOnHeader}>
              <ReactResizeDetector
                handleWidth
                handleHeight
                onResize={(width, height) => {
                  setBoxSizeOnHeader({ width, height });
                }}
              />
              <Grid container>
                <Grid item xs={12}>
                  <Grid container className={classes.rentalReasonLegendBox}>
                    {rentalReasonMasters
                      .filter((m) => !m.isDeleted)
                      .sort((a, b) => a.sortNo - b.sortNo)
                      .map((m) => (
                        <Grid
                          item
                          key={m.rentalReasonMasterId}
                          style={{ backgroundColor: m.colorCode }}
                          className={classes.rentalReasonLegendItem}
                        >
                          {m.name}
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </div>

            <Timeline
              style={{
                opacity: isLoading ? 0.3 : 1,
                paddingTop: boxSizeOnHeader.height,
              }}
              groups={groups}
              items={groups.length === 0 ? [] : items}
              keys={keys}
              sidebarWidth={width === 'lg' || width === 'xl' ? 350 : 120}
              dragSnap={dragSnapMinute * 60 * 1000}
              lineHeight={
                width === 'lg' || width === 'xl'
                  ? carBoxHeight < 28
                    ? 28
                    : carBoxHeight
                  : 66
              }
              timeSteps={{
                hour: setting.headerFormat === 'AMPM' ? 12 : 1,
                minute: 1,
                day: 1,
                month: 1,
                year: 1,
              }}
              itemsSorted
              itemTouchSendsClick={false}
              itemHeightRatio={carBoxHeight >= 32 ? 0.94 : 0.8}
              showCursorLine
              useResizeHandle={true}
              defaultTimeStart={moment().local().startOf('day')}
              defaultTimeEnd={moment()
                .local()
                .startOf('day')
                .add(setting.defaultCalenderSpan, 'day')}
              onItemMove={onItemMove}
              onItemResize={onItemResize}
              onBoundsChange={onBoundsChange}
              onCanvasDoubleClick={onCanvasDoubleClick}
              stackItems={!setting.banDoubleBook}
              moveResizeValidator={
                setting.banDoubleBook ? moveResizeValidator : undefined
              }
              onCanvasContextMenu={(group, time, e) => {
                openCanvasContext({
                  group,
                  time,
                  pageX: e.pageX,
                  pageY: e.pageY,
                });
              }}
              itemRenderer={itemRenderer}
              groupRenderer={groupRenderer}
              clickTolerance={1}
            >
              <TimelineHeaders
                style={{
                  position: 'sticky',
                  top: boxSizeOnHeader.height + appBarHeight,
                  zIndex: 1000,
                  backgroundColor: '#3f51b5',
                }}
              >
                <SidebarHeader>{sidebarRenderer}</SidebarHeader>
                {setting.headerFormat === 'AMPM' ? (
                  <>
                    <DateHeader
                      unit="primaryHeader"
                      labelFormat={primaryDateHeaderFormatter}
                    />
                    <DateHeader
                      labelFormat={dateHeaderFormatter}
                      intervalRenderer={({
                        getIntervalProps,
                        intervalContext,
                        data,
                      }) => {
                        return (
                          <div
                            {...getIntervalProps({
                              style: {
                                backgroundColor: 'white',
                                textAlign: 'center',
                                borderLeft: '1px solid #bbb',
                                height: '100%',
                              },
                            })}
                          >
                            {intervalContext.intervalText}
                          </div>
                        );
                      }}
                    />
                  </>
                ) : (
                  <>
                    <DateHeader unit="primaryHeader" />
                    <DateHeader
                      intervalRenderer={({
                        getIntervalProps,
                        intervalContext,
                        data,
                      }) => {
                        return (
                          <div
                            {...getIntervalProps({
                              style: {
                                backgroundColor: 'white',
                                textAlign: 'center',
                                borderLeft: '1px solid #bbb',
                                height: '100%',
                              },
                            })}
                          >
                            {intervalContext.intervalText}
                          </div>
                        );
                      }}
                    />
                  </>
                )}
              </TimelineHeaders>
            </Timeline>
          </div>
        </>
      ) : (
        <Timeline
          style={{
            opacity: isLoading ? 0.3 : 1,
            paddingTop: boxSizeOnHeader.height,
          }}
          groups={[]}
          items={[]}
          keys={keys}
          sidebarWidth={width === 'lg' || width === 'xl' ? 350 : 120}
          dragSnap={dragSnapMinute * 60 * 1000}
          lineHeight={
            width === 'lg' || width === 'xl'
              ? carBoxHeight < 28
                ? 28
                : carBoxHeight
              : 66
          }
          timeSteps={{
            hour: setting.headerFormat === 'AMPM' ? 12 : 1,
            minute: 1,
            day: 1,
            month: 1,
            year: 1,
          }}
          itemsSorted
          itemTouchSendsClick={false}
          itemHeightRatio={carBoxHeight >= 32 ? 0.94 : 0.8}
          showCursorLine
          useResizeHandle={true}
          defaultTimeStart={moment().local().startOf('day')}
          defaultTimeEnd={moment()
            .local()
            .startOf('day')
            .add(setting.defaultCalenderSpan, 'day')}
          onItemMove={onItemMove}
          onItemResize={onItemResize}
          onBoundsChange={onBoundsChange}
          onCanvasDoubleClick={onCanvasDoubleClick}
          stackItems={!setting.banDoubleBook}
          moveResizeValidator={
            setting.banDoubleBook ? moveResizeValidator : undefined
          }
          onCanvasContextMenu={(group, time, e) => {
            openCanvasContext({ group, time, pageX: e.pageX, pageY: e.pageY });
          }}
          itemRenderer={itemRenderer}
          groupRenderer={groupRenderer}
          clickTolerance={1}
        >
          <TimelineHeaders
            style={{
              position: 'sticky',
              top: boxSizeOnHeader.height + appBarHeight,
              zIndex: 1000,
              backgroundColor: '#3f51b5',
            }}
          >
            <SidebarHeader>{sidebarRenderer}</SidebarHeader>
            {setting.headerFormat === 'AMPM' ? (
              <>
                <DateHeader
                  unit="primaryHeader"
                  labelFormat={primaryDateHeaderFormatter}
                />
                <DateHeader
                  labelFormat={dateHeaderFormatter}
                  intervalRenderer={({
                    getIntervalProps,
                    intervalContext,
                    data,
                  }) => {
                    return (
                      <div
                        {...getIntervalProps({
                          style: {
                            backgroundColor: 'white',
                            textAlign: 'center',
                            borderLeft: '1px solid #bbb',
                            height: '100%',
                          },
                        })}
                      >
                        {intervalContext.intervalText}
                      </div>
                    );
                  }}
                />
              </>
            ) : (
              <>
                <DateHeader unit="primaryHeader" />
                <DateHeader
                  intervalRenderer={({
                    getIntervalProps,
                    intervalContext,
                    data,
                  }) => {
                    return (
                      <div
                        {...getIntervalProps({
                          style: {
                            backgroundColor: 'white',
                            textAlign: 'center',
                            borderLeft: '1px solid #bbb',
                            height: '100%',
                          },
                        })}
                      >
                        {intervalContext.intervalText}
                      </div>
                    );
                  }}
                />
              </>
            )}
          </TimelineHeaders>
        </Timeline>
      )}
    </div>
  );
};

export default withWidth()(Schedule);
