import React from 'react';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { ExitToApp } from '@material-ui/icons';
import moment from 'moment';
import { StaffEditDialog } from '../../StaffEditDialog';
import { ConfirmDialog } from '../../ConfirmDialog';
import { Error } from '../../message/Error';

export const Staff = ({
  userId, staffs,
  isLoading,
  editingStaff,
  changeStaffName, changeStaffMemo,
  newStaff, cancelEditStaff,
  addStaff,
  editStaff,
  saveStaff,
  confirmDeleteId, confirmDeleteStaff, deleteStaff, cancelDeleteStaff,
  confirmRetireId, confirmRetireStaff, cancelRetireStaff,
  messages,
}) => {
  const unRetireStaffs = staffs.filter((s) => !s.retire);
  const deleteTarget = unRetireStaffs.find((f) => f.staffId === confirmDeleteId);
  const retireTarget = unRetireStaffs.find((f) => f.staffId === confirmRetireId);
  return (
    <div style={{ textAlign: 'center' }}>
      <Grid container spacing={10} style={{ opacity: isLoading ? 0.3 : 1 }}>
        <Grid item xs={12} style={{ position: 'relative' }}>

          {isLoading
            ? (
              <CircularProgress
                color="primary"
                style={{
                  position: 'fixed', top: '50%', left: '50%', zIndex: 1400,
                }}
              />
            )
            : false}

          <Error visible={messages.length > 0} message={messages} />

          {typeof deleteTarget === 'undefined'
            ? false
            : (
              <ConfirmDialog
                title="確認"
                content={(
                  <span style={{ whiteSpace: 'pre-wrap' }}>
                    以下のスタッフを削除します。
                    <br />
                    よろしいですか？
                    <br />
                    ※スタッフが退職する場合は退職ボタンから退職処理を行ってください。
                    <br />
                    氏名：
                    {deleteTarget.name}
                    <br />
                    メモ：
                    {deleteTarget.memo}
                  </span>
              )}
                cancelButtonText="キャンセル"
                okButtonText="OK"
                isOpen
                ok={(e) => deleteStaff(userId, deleteTarget.staffId)}
                cancel={(e) => cancelDeleteStaff()}
              />
            )}

          {typeof retireTarget === 'undefined'
            ? false
            : (
              <ConfirmDialog
                title="確認"
                content={(
                  <span style={{ whiteSpace: 'pre-wrap' }}>
                    以下のスタッフを退職済みにします。
                    <br />
                    よろしいですか？
                    <br />
                    氏名：
                    {retireTarget.name}
                    <br />
                    メモ：
                    {retireTarget.memo}
                  </span>
              )}
                cancelButtonText="キャンセル"
                okButtonText="OK"
                isOpen
                ok={(e) => {
                  saveStaff({ ...retireTarget, retire: true, retireDate: moment() });
                  cancelRetireStaff();
                }}
                cancel={(e) => cancelRetireStaff()}
              />
            )}

          {unRetireStaffs.map((s) => (
            <Card style={{ opacity: isLoading ? 0.3 : 1, margin: 4 }} key={s.staffId}>
              <CardHeader
                title={s.name}
                style={{ textAlign: 'left' }}
                action={(
                  <div>
                    <IconButton
                      variant="contained"
color="primary"
                      onClick={(e) => editStaff({
                          staffId: s.staffId,
                          name: s.name,
                          memo: s.memo,
                          userId: s.user_id,
                          retire: s.retire,
                          retireDate: s.retire_date === null ? null : moment(s.retire_date),
                          isDeleted: s.is_deleted,
                      })}
                    >
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      variant="contained"
color="default"
                      onClick={(e) => confirmRetireStaff(s.staffId)}
                    >
                      <ExitToApp />
                    </IconButton>

                    <IconButton
                      variant="contained"
color="secondary"
                      onClick={(e) => confirmDeleteStaff(s.staffId)}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </div>
                )}
              />
              <CardContent style={{ textAlign: 'left' }} />
            </Card>
          ))}
        </Grid>
      </Grid>

      <StaffEditDialog
        userId={userId}
        title="スタッフ編集"
        isOpen={editingStaff !== null && editingStaff.staffId >= 0}
        staff={editingStaff}
        cancelEditStaff={cancelEditStaff}
        addStaff={null}
        saveStaff={saveStaff}
        changeStaffName={changeStaffName}
        changeStaffMemo={changeStaffMemo}
        isLoading={isLoading}
        messages={messages}
      />

      <StaffEditDialog
        userId={userId}
        title="新規スタッフ"
        isOpen={editingStaff !== null && editingStaff.staffId < 0}
        staff={editingStaff}
        cancelEditStaff={cancelEditStaff}
        addStaff={addStaff}
        saveStaff={null}
        changeStaffName={changeStaffName}
        changeStaffMemo={changeStaffMemo}
        isLoading={isLoading}
        messages={messages}
      />

      <Fab
        variant="extended"
        color="primary"
        style={{ bottom: '2%', position: 'fixed' }}
        disabled={isLoading}
        onClick={(e) => newStaff()}
      >
        <AddIcon />
        新規作成
      </Fab>
    </div>

  );
};
