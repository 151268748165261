export const isThisScheduleConflicting = (schedule, allSchedules) => {
  const otherSchedules = allSchedules.filter(s => s.scheduleId !== schedule.scheduleId);

  const isConflicting = otherSchedules.some(s => areConflicting(schedule, s));
  return isConflicting;
};

export const isAnyScheduleConflicting = (schedules) => {
  const isConflicting = schedules.some(s => isThisScheduleConflicting(s, schedules));
  return isConflicting;
};

export const areConflicting = (scheduleA, scheduleB) => {
  const conflict = scheduleB.facilityId === scheduleA.facilityId &&
    (
      scheduleB.start.isBetween(scheduleA.start, scheduleA.end) ||
      scheduleB.end.isBetween(scheduleA.start, scheduleA.end) ||
      (scheduleA.start.isBetween(scheduleB.start, scheduleB.end) && scheduleA.end.isBetween(scheduleB.start, scheduleB.end))
    );
  return conflict;
};
