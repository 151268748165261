import { createAction } from 'redux-actions';
import { ActionTypes as actions } from '../actionTypes/createAccountActionTypes';

export const validateEmail = (email) => ({
  type: actions.VALIDATE_EMAIL,
  payload: { email },
  meta: {},
  error: false,
});
export const finishEmailValidation = (errorMessage) => ({
  type: actions.FINISH_EMAIL_VALIDATION,
  payload: { errorEmailMessage: errorMessage },
  meta: {},
  error: false,
});
export const changeEmail = (email) => ({
  type: actions.CHANGE_EMAIL,
  payload: { email },
  meta: {},
  error: false,
});
export const passwordFocusedOnce = createAction(actions.PASSWORD_FOCUSED_ONCE);
export const validatePassword = (password) => ({
  type: actions.VALIDATE_PASSWORD,
  payload: { password },
  meta: {},
  error: false,
});
export const finishPasswordValidation = (errorMessage) => ({
  type: actions.FINISH_PASSWORD_VALIDATION,
  payload: { errorPasswordMessage: errorMessage },
  meta: {},
  error: false,
});
export const changePassword = (password) => ({
  type: actions.CHANGE_PASSWORD,
  payload: { password },
  meta: {},
  error: false,
});
export const passwordConfirmFocusedOnce = createAction(actions.PASSWORD_CONFIRM_FOCUSED_ONCE);
export const validatePasswordConfirm = createAction(actions.VALIDATE_PASSWORD_CONFIRM);
export const finishPasswordConfirmValidation = (result) => ({
  type: actions.FINISH_PASSWORD_CONFIRM_VALIDATION,
  payload: { result },
  meta: {},
  error: false,
});
export const changePasswordConfirm = (passwordConfirm) => ({
  type: actions.CHANGE_PASSWORD_CONFIRM,
  payload: { passwordConfirm },
  meta: {},
  error: false,
});
export const createAccount = (password, passwordConfirm, email) => ({
  type: actions.CREATE_ACCOUNT,
  payload: { password, passwordConfirm, email },
  meta: {},
  error: false,
});
export const successCreateAccount = (email) => ({
  type: actions.SUCCESS_CREATE_ACCOUNT,
  payload: { email },
  meta: {},
  error: false,
});
export const errorCreateAccount = (result) => ({
  type: actions.ERROR_CREATE_ACCOUNT,
  payload: { result },
  meta: {},
  error: true,
});

export const confirmEmailVerification = (token) => ({
  type: actions.CONFIRM_EMAIL_VERIFICATION,
  payload: { token },
  meta: {},
  error: true,
});
