export const ActionTypes = {

  CHANGE_LOGIN_EMAIL: 'CHANGE_LOGIN_EMAIL',
  VALIDATE_LOGIN_EMAIL: 'VALIDATE_LOGIN_EMAIL',
  FINISH_LOGIN_EMAIL_VALIDATION: 'FINISH_LOGIN_EMAIL_VALIDATION',

  CHANGE_LOGIN_PASSWORD: 'CHANGE_LOGIN_PASSWORD',
  VALIDATE_LOGIN_PASSWORD: 'VALIDATE_LOGIN_PASSWORD',
  FINISH_LOGIN_PASSWORD_VALIDATION: 'FINISH_LOGIN_PASSWORD_VALIDATION',

  FETCH_LOGIN_STATE: 'FETCH_LOGIN_STATE',
  SUCCESS_FETCH_LOGIN_STATE: 'SUCCESS_FETCH_LOGIN_STATE',

  LOGIN: 'LOGIN',
  SUCCESS_LOGIN: 'SUCCESS_LOGIN',
  ERROR_LOGIN: 'ERROR_LOGIN',

  LOGOUT: 'LOGOUT',
  SUCCESS_LOGOUT: 'SUCCESS_LOGOUT',
  ERROR_LOGOUT: 'ERROR_LOGOUT',

  CHANGE_REGISTERED_EMAIL: 'CHANGE_REGISTERED_EMAIL',
  SEND_PASSWORD_RESET: 'SEND_PASSWORD_RESET',
  SUCCESS_SEND_PASSWORD_RESET: 'SUCCESS_SEND_PASSWORD_RESET',
  ERROR_SEND_PASSWORD_RESET: 'ERROR_SEND_PASSWORD_RESET',
  ERROR_EMAIL_IS_NOT_USED: 'ERROR_EMAIL_IS_NOT_USED',
  CHECK_VALIDITY_TO_CHANGE_PASSWORD: 'CHECK_VALIDITY_TO_CHANGE_PASSWORD',
  VALID_TO_CHANGE_PASSWORD: 'VALID_TO_CHANGE_PASSWORD',
  INVALID_TO_CHANGE_PASSWORD: 'INVALID_TO_CHANGE_PASSWORD',

  VALIDATE_NEW_PASSWORD: 'VALIDATE_NEW_PASSWORD',
  FINISH_NEW_PASSWORD_VALIDATION: 'FINISH_NEW_PASSWORD_VALIDATION',
  CHANGE_NEW_PASSWORD: 'CHANGE_NEW_PASSWORD',
  VALIDATE_NEW_PASSWORD_CONFIRM: 'VALIDATE_NEW_PASSWORD_CONFIRM',
  FINISH_NEW_PASSWORD_CONFIRM_VALIDATION: 'FINISH_NEW_PASSWORD_CONFIRM_VALIDATION',
  CHANGE_NEW_PASSWORD_CONFIRM: 'CHANGE_NEW_PASSWORD_CONFIRM',
  REGISTER_NEW_PASSWORD: 'REGISTER_NEW_PASSWORD',
  SUCCESS_REGISTER_NEW_PASSWORD: 'SUCCESS_REGISTER_NEW_PASSWORD',
  ERROR_REGISTER_NEW_PASSWORD: 'ERROR_REGISTER_NEW_PASSWORD',

};
