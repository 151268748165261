export const Messages = {
  InvalidMail: 'メールアドレスの形式が不正です。',
  UsedMail: '入力頂いたメールアドレスはすでに使用されています。',
  InvalidPassWord: 'パスワードの形式が不正です。',
  NotMatchPasswordConfirm: '確認用パスワードが一致しません。',
  ServerError: 'サーバーでエラーが発生しました。',
  InvalidTokenToChangePassword: 'パスワード変更メールの期限が切れたか、トークンが無効です。',
  PasswordUpdated: 'パスワードが更新されました。',
  UnUsedMail: '入力頂いたメールアドレスで登録されたアカウントはありませんでした。',
  ConflictingSchedule: 'スケジュールが重なっています。',
  SomeSchedulesAreConflicting: '重なっているスケジュールがあります。',
  ReverseStartEndScheduleTime: '開始日時が終了日時より後になっています。'
};
