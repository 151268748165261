import { fromJS } from 'immutable';
import { Urls } from '../constant/url';
import { createReducer, updateState } from './utility';
import maxBy from 'lodash/maxBy';
import { v4 } from 'uuid';

export const initialSettingState = {
  setting: {
    settingId: -1,
    dragSnapUnit: '',
    dragSnapValue: '',
    headerFormat: '',
    showStaffInCard: true,
    showCustomerInCard: true,
    showCustomersCarInCard: true,
    labelOfStaffInCard: '',
    labelOfCustomerInCard: '',
    labelOfCustomersCarInCard: '',
    defaultCalenderSpan: 1,
    banDoubleBook: false,
    isCardMovableSp: true,
    isCardResizableSp: true,
    isCardMovablePc: true,
    isCardResizablePc: true
  },
  rentalReasonMasters: [],
  openedColorPalletId: null,
  messages: [],
  isLoading: false
};

const locationChange = (state, action) => {
  if (action.payload.location.pathname === Urls.Staff.path) { return updateState(state, { isLoading: true, messages: [] }); }

  return state;
};

const successGetSetting = (state, action) => {
  const copyState = fromJS(state);
  const newState = copyState
    .set('isLoading', false)
    .set('setting', action.payload.setting)
    .set('rentalReasonMasters', action.payload.rentalReasonMasters)
    .toJS();

  return newState;
};

const successGetSchedules = (state, action) => {
  const copyState = fromJS(state);
  const newState = copyState
    .set('isLoading', false)
    .set('setting', action.payload.setting)
    .set('rentalReasonMasters', action.payload.rentalReasonMasters)
    .toJS();

  return newState;
};

const errorGetSetting =
    (state, action) => updateState(state, { messages: action.payload.messages, isLoading: false });

const changeDragSnapValue =
    (state, action) => fromJS(state).setIn(['setting', 'dragSnapValue'], action.payload.value).toJS();

const changeDragSnapUnit =
    (state, action) => fromJS(state).setIn(['setting', 'dragSnapUnit'], action.payload.unit).toJS();

const changeHeaderFormat = (state, action) => fromJS(state).setIn(['setting', 'headerFormat'], action.payload.format).toJS();

const saveSetting = (state, action) => updateState(state, { isLoading: true });

const successSaveSetting = (state, action) => updateState(state, { isLoading: false });

const errorSaveSetting = (state, action) => updateState(state, { isLoading: false });

const changeShowStaffInCard =
    (state, action) => fromJS(state).setIn(['setting', 'showStaffInCard'], !state.setting.showStaffInCard).toJS();

const changeShowCustomerInCard =
    (state, action) => fromJS(state).setIn(['setting', 'showCustomerInCard'], !state.setting.showCustomerInCard).toJS();

const changeShowCustomersCarInCard =
    (state, action) => fromJS(state).setIn(['setting', 'showCustomersCarInCard'], !state.setting.showCustomersCarInCard).toJS();

const changeLabelOfStaffInCard =
    (state, action) => fromJS(state).setIn(['setting', 'labelOfStaffInCard'], action.payload.label).toJS();

const changeLabelOfCustomerInCard =
    (state, action) => fromJS(state).setIn(['setting', 'labelOfCustomerInCard'], action.payload.label).toJS();

const changeLabelOfCustomersCarInCard =
    (state, action) => fromJS(state).setIn(['setting', 'labelOfCustomersCarInCard'], action.payload.label).toJS();

const changeDefaultCalenderSpan =
  (state, action) => fromJS(state).setIn(['setting', 'defaultCalenderSpan'], action.payload.span).toJS();

const changeShowCarHintInCalendar =
  (state, action) => fromJS(state).setIn(['setting', 'showCarHintInCalendar'], !state.setting.showCarHintInCalendar).toJS();

const changeShowScheduleHintInCard =
  (state, action) => fromJS(state).setIn(['setting', 'showScheduleHintInCard'], !state.setting.showScheduleHintInCard).toJS();

const changeBanDoubleBook =
  (state, action) => fromJS(state).setIn(['setting', 'banDoubleBook'], !state.setting.banDoubleBook).toJS();

const changeIsCardResizableSp =
  (state, action) => fromJS(state).setIn(['setting', 'isCardResizableSp'], !state.setting.isCardResizableSp).toJS();

const changeIsCardMovableSp =
  (state, action) => fromJS(state).setIn(['setting', 'isCardMovableSp'], !state.setting.isCardMovableSp).toJS();

const changeIsCardResizablePc =
  (state, action) => fromJS(state).setIn(['setting', 'isCardResizablePc'], !state.setting.isCardResizablePc).toJS();

const changeIsCardMovablePc =
  (state, action) => fromJS(state).setIn(['setting', 'isCardMovablePc'], !state.setting.isCardMovablePc).toJS();

const changeCheckWarningAsInitialValue =
  (state, action) => fromJS(state).setIn(['setting', 'checkWarningAsInitialValue'], !state.setting.checkWarningAsInitialValue).toJS();

const changeRentalReasonName = (state, action) => {
  const idx = fromJS(state)
    .get('rentalReasonMasters')
    .findIndex(m => m.get('rentalReasonMasterId') === action.payload.rentalReasonMasterId);

  return fromJS(state).setIn(['rentalReasonMasters', idx, 'name'], action.payload.name).toJS();
};

const changeRentalReasonColor = (state, action) => {
  const idx = fromJS(state)
    .get('rentalReasonMasters')
    .findIndex(m => m.get('rentalReasonMasterId') === action.payload.rentalReasonMasterId);

  return fromJS(state).setIn(['rentalReasonMasters', idx, 'colorCode'], action.payload.color.hex).toJS();
};

const moveRentalReasonMaster = (state, action) => {
  const copiedState = fromJS(state);
  const draggedMasterSortNo = state.rentalReasonMasters.find(m => m.rentalReasonMasterId === action.payload.draggedId).sortNo;
  const draggedMasterIndex = copiedState
    .get('rentalReasonMasters')
    .findIndex(m => m.get('rentalReasonMasterId') === action.payload.draggedId);
  const hoveredMasterSortNo = state.rentalReasonMasters.find(m => m.rentalReasonMasterId === action.payload.hoveredId).sortNo;
  const hoveredMasterIndex = copiedState
    .get('rentalReasonMasters')
    .findIndex(m => m.get('rentalReasonMasterId') === action.payload.hoveredId);

  const t = copiedState
    .setIn(['rentalReasonMasters', draggedMasterIndex, 'sortNo'], hoveredMasterSortNo)
    .setIn(['rentalReasonMasters', hoveredMasterIndex, 'sortNo'], draggedMasterSortNo)
    .toJS();
  return t;
};

const openColorPallet = (state, action) =>
  updateState(state, { openedColorPalletId: action.payload.rentalReasonMasterId });

const addNewRentalReasonMaster = (state, action) => fromJS(state)
  .update('rentalReasonMasters', s => s.push({
    rentalReasonMasterId: v4(),
    name: '新規',
    code: state.rentalReasonMasters.length === 0 ? 0 : maxBy(state.rentalReasonMasters, m => m.code).code + 1,
    sortNo: state.rentalReasonMasters.length === 0 ? 0 : maxBy(state.rentalReasonMasters, m => m.sortNo).sortNo + 1,
    colorCode: '#F44336',
    isAddedMaster: true,
    isDeleted: false
  }))
  .toJS();

const deleteRentalReasonMaster = (state, action) => {
  const idx = fromJS(state)
    .get('rentalReasonMasters')
    .findIndex(m => m.get('rentalReasonMasterId') === action.payload.rentalReasonMasterId);

  return fromJS(state).setIn(['rentalReasonMasters', idx, 'isDeleted'], true).toJS();
};

const handlers = {
  '@@router/LOCATION_CHANGE': locationChange,
  CHANGE_DRAG_SNAP_VALUE: changeDragSnapValue,
  CHANGE_DRAG_SNAP_UNIT: changeDragSnapUnit,
  CHANGE_HEADER_FORMAT: changeHeaderFormat,
  SAVE_SETTING: saveSetting,
  SUCCESS_SAVE_SETTING: successSaveSetting,
  SUCCESS_GET_SETTING: successGetSetting,
  SUCCESS_GET_SCHEDULES: successGetSchedules,
  ERROR_SAVE_SETTING: errorSaveSetting,
  ERROR_GET_SETTING: errorGetSetting,
  CHANGE_SHOW_STAFF_IN_CARD: changeShowStaffInCard,
  CHANGE_SHOW_CUSTOMER_IN_CARD: changeShowCustomerInCard,
  CHANGE_SHOW_CUSTOMERS_CAR_IN_CARD: changeShowCustomersCarInCard,
  CHANGE_LABEL_OF_STAFF_IN_CARD: changeLabelOfStaffInCard,
  CHANGE_LABEL_OF_CUSTOMER_IN_CARD: changeLabelOfCustomerInCard,
  CHANGE_LABEL_OF_CUSTOMERS_CAR_IN_CARD: changeLabelOfCustomersCarInCard,
  CHANGE_DEFAULT_CALENDER_SPAN: changeDefaultCalenderSpan,
  CHANGE_SHOW_CAR_HINT_IN_CALENDER: changeShowCarHintInCalendar,
  CHANGE_SHOW_SCHEDULE_HINT_IN_CARD: changeShowScheduleHintInCard,
  CHANGE_BAN_DOUBLE_BOOK: changeBanDoubleBook,
  CHANGE_IS_CARD_MOVABLE_SP: changeIsCardMovableSp,
  CHANGE_IS_CARD_MOVABLE_PC: changeIsCardMovablePc,
  CHANGE_IS_CARD_RESIZABLE_SP: changeIsCardResizableSp,
  CHANGE_IS_CARD_RESIZABLE_PC: changeIsCardResizablePc,
  CHANGE_CHECK_WARNING_AS_INITIAL_VALUE: changeCheckWarningAsInitialValue,
  CHANGE_RENTAL_REASON_NAME: changeRentalReasonName,
  MOVE_RENTAL_REASON_MASTER: moveRentalReasonMaster,
  CHANGE_RENTAL_REASON_COLOR: changeRentalReasonColor,
  OPEN_COLOR_PALLET: openColorPallet,
  ADD_NEW_RENTAL_REASON_MASTER: addNewRentalReasonMaster,
  DELETE_RENTAL_REASON_MASTER: deleteRentalReasonMaster
};

export const settingReducer = createReducer(initialSettingState, handlers);
