import React from 'react';
import { connect } from 'react-redux';
import { SentResetPasswordEmail } from '../../components/pages/SentResetPasswordEmail';

export default connect(
  (state) => ({
    registeredEmail: state.loginReducer.registeredEmail,
    isLoading: state.loginReducer.isLoading,
  }),

  (dispatch) => ({
  }),
)(SentResetPasswordEmail);
