import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import {
  isTablet,
  isMobile,
} from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CreateAccount from '../containers/pages/CreateAccount';
import Login from '../containers/pages/Login';
import Customers from '../containers/pages/member/Customer';
import Staff from '../containers/pages/member/Staff';
import Facility from '../containers/pages/member/Facility';
import Schedule from '../containers/pages/member/Schedule';
import Setting from '../containers/pages/member/Setting';
import Authorization from './authorization/Authorization';
import HeaderForMember from '../containers/HeaderForMember';
import ForgetPassword from '../containers/pages/ForgetPassword';
import SentResetPasswordEmail from '../containers/pages/SentResetPasswordEmail';
import ResetPassword from '../containers/pages/ResetPassword';
import SuccessCreateAccount from '../containers/pages/SuccessCreateAccount';
import ConfirmEmailVerification from '../containers/pages/ConfirmEmailVerification';
import ErrorBoundary from './ErrorBoundary';
import { Urls } from '../constant/url';
import { rootStore } from '../stores/stores';
import { fetchLoginState } from '../actions/loginActions';
import WithTracker from './WithTracker';
import Support from '../containers/pages/Support';
import { SuccessRequestGuestSupport } from './pages/SuccessRequestGuestSupport';
import SupportForMember from '../containers/pages/member/SupportForMember';
import { SuccessRequestMemberSupport } from './pages/member/SuccessRequestMemberSupport';
import {Ga4} from './Ga4';

const theme = createMuiTheme();
const history = createBrowserHistory();
const store = rootStore(history);

class App extends React.Component {
  componentDidMount() {
    store.dispatch(fetchLoginState());
  }

  render() {
    const backend = isTablet || isMobile ? TouchBackend : HTML5Backend;
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <DndProvider backend={backend}>
            <ConnectedRouter history={history}>
              <ThemeProvider theme={theme}>
                <div className="App" style={{ height: '100%' }}>
                  <Switch>
                    <Route exact path={Urls.CreateAccount.path} component={() => <Ga4><CreateAccount /></Ga4>} />
                    <Route path={Urls.SuccessCreateAccount.path} component={() => <Ga4><SuccessCreateAccount /></Ga4>} />
                    <Route path={Urls.ConfirmEmailVerification.path} component={() => <Ga4><ConfirmEmailVerification /></Ga4>} />
                    <Route path={Urls.Login.path} component={() => <Ga4><Login /></Ga4>} />
                    <Route path={Urls.ForgetPassword.path} component={() => <Ga4><ForgetPassword /></Ga4>} />
                    <Route path={Urls.SentResetPasswordEmail.path} component={() => <Ga4><SentResetPasswordEmail /></Ga4>} />
                    <Route path={Urls.ResetPassword.path} component={() => <Ga4><ResetPassword /></Ga4>} />
                    <Route path={Urls.Support.path} component={() => <Ga4><Support /></Ga4>} />
                    <Route path={Urls.SuccessRequestGuestSupport.path} component={() => <Ga4><SuccessRequestGuestSupport /></Ga4>} />

                    <Authorization>
                      <HeaderForMember>
                        <Switch>
                          <Route exact path={Urls.Schedule.path} component={() => <Ga4><Schedule /></Ga4>} />
                          <Route path={Urls.Customer.path} component={() => <Ga4><Customers /></Ga4>} />
                          <Route path={Urls.Staff.path} component={() => <Ga4><Staff /></Ga4>} />
                          <Route path={Urls.Facility.path} component={() => <Ga4><Facility /></Ga4>} />
                          <Route path={Urls.Setting.path} component={() => <Ga4><Setting /></Ga4>} />
                          <Route path={Urls.SupportForMember.path} component={() => <Ga4><SupportForMember /></Ga4>} />
                          <Route path={Urls.SuccessRequestMemberSupport.path} component={() => <Ga4><SuccessRequestMemberSupport /></Ga4>} />
                        </Switch>
                      </HeaderForMember>
                    </Authorization>

                  </Switch>
                </div>
              </ThemeProvider>
            </ConnectedRouter>
          </DndProvider>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
