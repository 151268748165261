import { createAction } from 'redux-actions';
import { ActionTypes as actions } from '../actionTypes/scheduleActionTypes';

export const GET_SCHEDULES = 'GET_SCHEDULES';
export const getSchedules = (
  start,
  end
) => ({
  type: GET_SCHEDULES,
  payload: {
    start,
    end,
  },
});

export const successGetSchedules = (schedules, customers, facilities, staffs, setting, rentalReasonMasters) => ({
  type: actions.SUCCESS_GET_SCHEDULES,
  payload: {
    schedules: schedules,
    customers: customers,
    facilities: facilities,
    staffs,
    setting,
    rentalReasonMasters
  },
  meta: {},
  error: false
});
export const errorGetSchedules = messages => ({
  type: actions.ERROR_GET_SCHEDULES,
  payload: { messages: messages },
  meta: {},
  error: false
});
export const newSchedule = (userId, facilityId, facilityName, start, end, checkWarningAsInitialValue) => ({
  type: actions.NEW_SCHEDULE,
  payload: {
    editedSchedule: {
      scheduleId: -1,
      staffId: -1,
      userId: userId,
      customerId: -1,
      freeFirstName: '',
      freeLastName: '',
      customersCar: '',
      facilityId: facilityId,
      facilityName: facilityName,
      start: start,
      end: end,
      memo: '',
      warning: checkWarningAsInitialValue,
      rentalReason: -1,
      requestedCarType: -1,
      requestedWeightType: -1,
      requestedFreightType: -1,
      requestedDriveSystem: -1,
      requestedShiftLever: -1,
      requestedParkingBrake: -1,
      requestedKeyType: -1,
      requestedWithAirConditioner: -1
    }
  },
  meta: {},
  error: false
});

export const UPDATE_CANVAS_TIME = 'UPDATE_CANVAS_TIME';
export const updateCanvasTime = (start, end) => ({
  type: UPDATE_CANVAS_TIME,
  payload: {
    start,
    end,
  },
});

export const changeSelectedCustomer = customerId => ({
  type: actions.CHANGE_SELECTED_CUSTOMER,
  payload: { customerId: customerId },
  meta: {},
  error: false
});

export const changeSelectedFacility = facilityId => ({
  type: actions.CHANGE_SELECTED_FACILITY,
  payload: { facilityId: facilityId },
  meta: {},
  error: false
});

export const changeFreeFirstName = freeFirstName => ({
  type: actions.CHANGE_FREE_FIRST_NAME,
  payload: { freeFirstName },
  meta: {},
  error: false
});

export const changeFreeLastName = freeLastName => ({
  type: actions.CHANGE_FREE_LAST_NAME,
  payload: { freeLastName },
  meta: {},
  error: false
});

export const changeCustomersCar = customersCar => ({
  type: actions.CHANGE_CUSTOMERS_CAR,
  payload: { customersCar },
  meta: {},
  error: false
});

export const changeSelectedStaff = staffId => ({
  type: actions.CHANGE_SELECTED_STAFF,
  payload: { staffId: staffId },
  meta: {},
  error: false
});
export const changeMemo = memo => ({
  type: actions.CHANGE_MEMO,
  payload: { memo: memo },
  meta: {},
  error: false
});
export const changeSelectedRentalReason = reason => ({
  type: actions.CHANGE_SELECTED_RENTAL_REASON,
  payload: { rentalReason: reason },
  meta: {},
  error: false
});
export const changeStart = start => ({
  type: actions.CHANGE_START,
  payload: { start: start },
  meta: {},
  error: false
});
export const changeEnd = end => ({
  type: actions.CHANGE_END,
  payload: { end: end },
  meta: {},
  error: false
});

export const changeRequestedCarType = requestedCarType => ({
  type: actions.CHANGE_REQUESTED_CAR_TYPE,
  payload: { requestedCarType },
  meta: {},
  error: false
});

export const changeRequestedWeightType = requestedWeightType => ({
  type: actions.CHANGE_REQUESTED_WEIGHT_TYPE,
  payload: { requestedWeightType },
  meta: {},
  error: false
});

export const changeRequestedFreightType = requestedFreightType => ({
  type: actions.CHANGE_REQUESTED_FREIGHT_TYPE,
  payload: { requestedFreightType },
  meta: {},
  error: false
});

export const changeRequestedDriveSystem = requestedDriveSystem => ({
  type: actions.CHANGE_REQUESTED_DRIVE_SYSTEM,
  payload: { requestedDriveSystem },
  meta: {},
  error: false
});

export const changeRequestedShiftLever = requestedShiftLever => ({
  type: actions.CHANGE_REQUESTED_SHIFT_LEVER,
  payload: { requestedShiftLever },
  meta: {},
  error: false
});

export const changeRequestedParkingBrake = requestedParkingBrake => ({
  type: actions.CHANGE_REQUESTED_PARKING_BRAKE,
  payload: { requestedParkingBrake },
  meta: {},
  error: false
});

export const changeRequestedKeyType = requestedKeyType => ({
  type: actions.CHANGE_REQUESTED_KEY_TYPE,
  payload: { requestedKeyType },
  meta: {},
  error: false
});

export const changeRequestedWithAirConditioner = requestedWithAirConditioner => ({
  type: actions.CHANGE_REQUESTED_WITH_AIR_CONDITIONER,
  payload: { requestedWithAirConditioner },
  meta: {},
  error: false
});

export const cancelEditSchedule = createAction(actions.CANCEL_EDIT_SCHEDULE);
export const addSchedule = schedule => ({
  type: actions.ADD_SCHEDULE,
  payload: { schedule },
  meta: {},
  error: false
});
export const successAddSchedule = schedule => ({
  type: actions.SUCCESS_ADD_SCHEDULE,
  payload: { schedule: schedule },
  meta: {},
  error: false
});
export const errorAddSchedule = messages => ({
  type: actions.ERROR_ADD_SCHEDULE,
  payload: { messages: messages },
  meta: {},
  error: false
});
export const editSchedule = schedule => ({
  type: actions.EDIT_SCHEDULE,
  payload: {
    schedule
  },
  meta: {},
  error: false
});
export const saveSchedule = schedule => ({
  type: actions.SAVE_SCHEDULE,
  payload: { schedule },
  meta: {},
  error: false
});

export const openScheduleCardContext = itemContextObject => ({
  type: actions.OPEN_SCHEDULE_CARD_CONTEXT,
  payload: { itemContextObject },
  meta: {},
  error: false
});

export const closeScheduleCardContext = () => ({
  type: actions.CLOSE_SCHEDULE_CARD_CONTEXT,
  payload: {},
  meta: {},
  error: false
});

export const openCanvasContext = canvasContextObject => ({
  type: actions.OPEN_CANVAS_CONTEXT,
  payload: { canvasContextObject },
  meta: {},
  error: false
});

export const closeCanvasContext = () => ({
  type: actions.CLOSE_CANVAS_CONTEXT,
  payload: {},
  meta: {},
  error: false
});

export const copySchedule = schedule => ({
  type: actions.COPY_SCHEDULE,
  payload: { schedule },
  meta: {},
  error: false
});

export const errorSaveSchedule = createAction(actions.ERROR_SAVE_SCHEDULE);
export const successSaveSchedule = schedule => ({
  type: actions.SUCCESS_SAVE_SCHEDULE,
  payload: { schedule: schedule },
  meta: {},
  error: false
});
export const cancelDeleteSchedule = scheduleId => ({
  type: actions.CANCEL_DELETE_SCHEDULE,
  payload: { scheduleId: scheduleId },
  meta: {},
  error: false
});
export const confirmDeleteSchedule = scheduleId => ({
  type: actions.CONFIRM_DELETE_SCHEDULE,
  payload: { scheduleId: scheduleId },
  meta: {},
  error: false
});
export const deleteSchedule = (userId, scheduleId) => ({
  type: actions.DELETE_SCHEDULE,
  payload: { userId: userId, scheduleId: scheduleId },
  meta: {},
  error: false
});
export const successDeleteSchedule = scheduleId => ({
  type: actions.SUCCESS_DELETE_SCHEDULE,
  payload: { scheduleId: scheduleId },
  meta: {},
  error: false
});
export const errorDeleteSchedule = messages => ({
  type: actions.ERROR_DELETE_SCHEDULE,
  payload: { messages: messages },
  meta: {},
  error: false
});

export const closeFilterFacilityDialog = () => ({
  type: actions.CLOSE_FILTER_FACILITY_DIALOG,
  payload: {},
  meta: {},
  error: false
});

export const openFilterFacilityDialog = () => ({
  type: actions.OPEN_FILTER_FACILITY_DIALOG,
  payload: {},
  meta: {},
  error: false
});

export const changeFilterState = filterState => ({
  type: actions.CHANGE_FILTER_STATE,
  payload: { filterState },
  meta: {},
  error: false
});

export const closeSortFacilityDialog = () => ({
  type: actions.CLOSE_SORT_FACILITY_DIALOG,
  payload: {},
  meta: {},
  error: false
});

export const openSortFacilityDialog = () => ({
  type: actions.OPEN_SORT_FACILITY_DIALOG,
  payload: {},
  meta: {},
  error: false
});

export const changeSortState = sortState => ({
  type: actions.CHANGE_SORT_STATE,
  payload: { sortState },
  meta: {},
  error: false
});

export const changeDefaultStartEnd = (start, end) => ({
  type: actions.CHANGE_DEFAULT_TIME_START_END,
  payload: { defaultTimeStart: start, defaultTimeEnd: end },
  meta: {},
  error: false
});

export const changeWarning = () => ({
  type: actions.CHANGE_WARNING,
  payload: {},
  meta: {},
  error: false
});

export const hover = () => ({
  type: actions.HOVER,
  payload: {},
  meta: {},
  error: false
});

export const hoverEnd = () => ({
  type: actions.HOVER_END,
  payload: {},
  meta: {},
  error: false
});

export const hoverOnGroups = () => ({
  type: actions.HOVER_ON_GROUPS,
  payload: {},
  meta: {},
  error: false
});

export const hoverOnGroupsEnd = () => ({
  type: actions.HOVER_ON_GROUPS_END,
  payload: {},
  meta: {},
  error: false
});

export const scrollOnDrag = (scheduleId, scheduleStart, scheduleEnd, visibleTimeStart, visibleTimeEnd) => ({
  type: actions.SCROLL_ON_DRAG,
  payload: {
    scheduleId,
    scheduleStart,
    scheduleEnd,
    visibleTimeStart,
    visibleTimeEnd
  },
  meta: {},
  error: false
});

export const toggleCarList = createAction(actions.TOGGLE_CAR_LIST);
