import React from 'react';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Save from '@material-ui/icons/Save';
import { Error } from '../../message/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { CommonDropDown } from '../../CommonDropDown';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Add } from '@material-ui/icons';
import RentalReasonMasterItem from '../../RentalReasonMasterItem';

export const Setting = ({
  userId,
  isLoading,
  setting,
  rentalReasonMasters,
  saveSetting,
  changeDragSnapValue,
  changeDragSnapUnit,
  changeHeaderFormat,
  changeShowStaffInCard,
  changeShowCustomerInCard,
  changeShowCustomersCarInCard,
  changeLabelOfStaffInCard,
  changeLabelOfCustomerInCard,
  changeLabelOfCustomersCarInCard,
  changeDefaultCalenderSpan,
  changeShowCarHintInCalendar,
  changeShowScheduleHintInCard,
  changeCheckWarningAsInitialValue,
  changeRentalReasonName,
  changeBanDoubleBook,
  changeIsCardMovableSp,
  changeIsCardMovablePc,
  changeIsCardResizableSp,
  changeIsCardResizablePc,
  openColorPallet,
  openedColorPalletId,
  changeRentalReasonColor,
  moveRentalReasonMaster,
  addNewRentalReasonMaster,
  deleteRentalReasonMaster,
  messages
}) => {
  return (
    <div style={{ textAlign: 'center' }}>

      {isLoading &&
            <CircularProgress color="primary" style={{ position: 'fixed', top: '50%', left: '50%', zIndex: 1400 }}/>}

      <Error visible={messages.length > 0} message={messages}/>

      <Grid container spacing={4} style={{ opacity: isLoading ? 0.3 : 1, marginBottom: 40 }}>

        <Grid item xs={3}>
          <Typography variant="subtitle1" gutterBottom>
            カレンダーの初期表示日数
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <TextField type="number" style={{ verticalAlign: 'middle' }}
            label="初期表示期間"
            value={setting.defaultCalenderSpan}
            onChange={e => changeDefaultCalenderSpan(e.target.value)}/>
          <span style={{ verticalAlign: 'middle' }}>日</span>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="subtitle1" gutterBottom>
                        スケジュール時間の最小単位:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <TextField type="number"
            label="時間"
            value={setting.dragSnapValue}
            onChange={e => changeDragSnapValue(e.target.value)}/>
          <CommonDropDown fullWidth={false} label="単位" inputId="select-time-unit"
            disableUnderline={false}
            defaultValue="minute" required={false}
            list={[
              { key: '日', value: 'day' },
              { key: '時間', value: 'hour' },
              { key: '分', value: 'minute' }
            ]}
            value={setting.dragSnapUnit}
            onChange={e => changeDragSnapUnit(e.target.value)}/>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle1" gutterBottom>
                        カレンダーの日付と時間の表示形式:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <CommonDropDown fullWidth={false} label="表示形式" inputId="select-time-unit"
            disableUnderline={false}
            defaultValue="default" required={false}
            list={[
              { key: '通常表示', value: 'default' },
              { key: '午前午後表示', value: 'AMPM' }
            ]}
            value={setting.headerFormat}
            onChange={e => changeHeaderFormat(e.target.value)}/>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="subtitle1" gutterBottom>
            カレンダーの代車一覧に詳細情報ヒントを表示する:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Checkbox
            color="primary"
            checked={setting.showCarHintInCalendar}
            onChange={changeShowCarHintInCalendar}
          />
        </Grid>

        <Grid item xs={3}>
          <Typography variant="subtitle1" gutterBottom>
            台車貸し出し理由の設定:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          {rentalReasonMasters.filter(m => !m.isDeleted).sort((a, b) => a.sortNo - b.sortNo).map(master =>
            <RentalReasonMasterItem
              key={master.rentalReasonMasterId}
              {...{
                master,
                changeRentalReasonName,
                deleteRentalReasonMaster,
                changeRentalReasonColor,
                moveRentalReasonMaster,
                openedColorPalletId,
                openColorPallet
              }}
            />
          )}
          <Grid container={true}>
            <Grid item={true} xs={12} style={{ textAlign: 'center' }}>
              <Fab color="primary" size="small" onClick={e => addNewRentalReasonMaster()}>
                <Add fontSize="small" />
              </Fab>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="subtitle1" gutterBottom>
                        スケジュールカードの内容設定:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <FormControl component="fieldset">
            <FormLabel component="legend">カードに表示する項目</FormLabel>
            <FormGroup row={true}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={setting.showStaffInCard}
                    onChange={changeShowStaffInCard}
                  />}
                label="担当者"
                labelPlacement="start"
              />
              <TextField margin="normal" label="カード上の項目名"
                value={setting.labelOfStaffInCard}
                onChange={e => changeLabelOfStaffInCard(e.target.value)}/>
            </FormGroup>
            <FormGroup row={true}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={setting.showCustomerInCard}
                    onChange={changeShowCustomerInCard}
                  />}
                label="お客様"
                labelPlacement="start"
              />
              <TextField margin="normal" label="カード上の項目名"
                value={setting.labelOfCustomerInCard}
                onChange={e => changeLabelOfCustomerInCard(e.target.value)}/>
            </FormGroup>
            <FormGroup row={true}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={setting.showCustomersCarInCard}
                    onChange={changeShowCustomersCarInCard}
                  />}
                label="車種"
                labelPlacement="start"
              />
              <TextField margin="normal" label="カード上の項目名"
                value={setting.labelOfCustomersCarInCard}
                onChange={e => changeLabelOfCustomersCarInCard(e.target.value)}/>
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <Typography variant="subtitle1" gutterBottom>
            スケジュールカードに詳細情報ヒントを表示する:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Checkbox
            color="primary"
            checked={setting.showScheduleHintInCard}
            onChange={changeShowScheduleHintInCard}
          />
        </Grid>

        <Grid item xs={3}>
          <Typography variant="subtitle1" gutterBottom>
            スケジュールの重なりを禁止する:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Checkbox
            color="primary"
            checked={setting.banDoubleBook}
            onChange={changeBanDoubleBook}
          />
        </Grid>

        <Grid item xs={3}>
          <Typography variant="subtitle1" gutterBottom>
            スケジュールを新規作成時に代車変更不可にチェックを入れる:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Checkbox
            color="primary"
            checked={setting.checkWarningAsInitialValue}
            onChange={changeCheckWarningAsInitialValue}
          />
        </Grid>

        <Grid item xs={3}>
          <Typography variant="subtitle1" gutterBottom>
            ドラッグ・アンド・ドロップを使う（PC）:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <FormControlLabel
            value=""
            control={
              <Checkbox
                checked={setting.isCardMovablePc}
                onChange={changeIsCardMovablePc}
                color="primary"
              />
            }
            label="ドラッグ・アンド・ドロップでカードの移動を許可する"
            labelPlacement="start"
          />
          <br />
          <FormControlLabel
            value=""
            control={
              <Checkbox
                checked={setting.isCardResizablePc}
                onChange={changeIsCardResizablePc}
                color="primary"
              />
            }
            label="ドラッグ・アンド・ドロップでカードの長さ（時間）の変更を許可する"
            labelPlacement="start"
          />
        </Grid>

        <Grid item xs={3}>
          <Typography variant="subtitle1" gutterBottom>
            ドラッグ・アンド・ドロップを使う（スマートフォン）:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <FormControlLabel
            value=""
            control={
              <Checkbox
                checked={setting.isCardMovableSp}
                onChange={changeIsCardMovableSp}
                color="primary"
              />
            }
            label="ドラッグ・アンド・ドロップでカードの移動を許可する"
            labelPlacement="start"
          />
          <br />
          <FormControlLabel
            value=""
            control={
              <Checkbox
                checked={setting.isCardResizableSp}
                onChange={changeIsCardResizableSp}
                color="primary"
              />
            }
            label="ドラッグ・アンド・ドロップでカードの長さ（時間）の変更を許可する"
            labelPlacement="start"
          />
        </Grid>
      </Grid>

      <Fab variant="extended" color="primary"
        style={{ bottom: '2%', position: 'fixed' }}
        disabled={ isLoading }
        onClick={(e) => saveSetting(setting,
          rentalReasonMasters
            .filter(m => !(m.isAddedMaster && m.isDeleted))
            .sort((a, b) => a.sortNo - b.sortNo)
            .map((m, i) => ({ ...m, sortNo: i })))}>
        <Save />
                保存
      </Fab>
    </div>

  );
};
