export const DriverTypes = [
  { key: 'AT', value: 1 },
  { key: 'MT', value: 2 },
  { key: '両方', value: 3 },
];

export const TransmissionTypes = [
  { key: 'AT', value: 1 },
  { key: 'MT', value: 2 },
];

export const DriveSystem = [
  { key: '2WD', value: 1 },
  { key: '4WD', value: 2 },
];

export const Use = [
  { key: '代車', value: 1 },
  { key: '試乗車', value: 2 },
]

export const WeightType = [
  { key: '普通車', value: 1 },
  { key: '軽自動車', value: 2 },
];

export const FreightType = [
  { key: '普通車', value: 1 },
  { key: 'バン', value: 2 },
  { key: '軽トラック', value: 3 },
];

export const ShiftLever = [
  { key: 'コラム', value: 1 },
  { key: 'サイド', value: 2 },
];

export const ParkingBrake = [
  { key: 'フット', value: 1 },
  { key: 'サイド', value: 2 },
  { key: '電動パーキングブレーキ', value: 3 },
];

export const KeyType = [
  { key: 'キー', value: 1 },
  { key: 'キーレスエントリー', value: 2 },
  { key: 'スマートエントリー', value: 3 },
];

export const WithOrWithout = [
  { key: '無し', value: 0 },
  { key: '有り', value: 1 },
];
