import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Urls } from '../../constant/url';
import { Error } from '../message/Error';

export const ForgetPassword = (props) => {
  const { changeRegisteredEmail } = props;

  useEffect(() => {
    changeRegisteredEmail('');
  }, [changeRegisteredEmail]);

  return props.isLoggedIn
    ? (<Redirect to={Urls.Schedule.path} />)
    : (
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h4" color="inherit" style={{ flexGrow: 1 }} />
            <Button color="inherit" component={Link} to={Urls.Login.path}>ログイン</Button>
            <Button color="inherit" component={Link} to="/">アカウント作成</Button>
          </Toolbar>
        </AppBar>
        <main>
          <Grid container spacing={2} direction="column" justify="flex-start" style={{ padding: 20 }}>

            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography variant="h5">
                パスワード再設定メールを送る
              </Typography>
            </Grid>

            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Error visible={props.errorMessages.length > 0} message={props.errorMessages} />
              <TextField
                label="登録に使用したメールアドレス"
                type="email"
                value={props.registeredEmail}
                onChange={(e) => changeRegisteredEmail(e.target.value)}
                style={{ width: 240 }}
              />
            </Grid>

            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                disabled={props.isLoading}
                onClick={(e) => props.sendPasswordReset(props.registeredEmail)}
              >
                送信
              </Button>
            </Grid>
          </Grid>
        </main>
      </div>
    );
};
