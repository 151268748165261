import moment from 'moment';
import { createAction } from 'redux-actions';
import { ActionTypes as actions } from '../actionTypes/facilityActionTypes';

export const changeFacilityName = (name) => ({
  type: actions.CHANGE_FACILITY_NAME,
  payload: { name },
  meta: {},
  error: false,
});

export const changeFacilityNo = (no) => ({
  type: actions.CHANGE_FACILITY_NO,
  payload: { no },
  meta: {},
  error: false,
});

export const changeFacilityColor = (color) => ({
  type: actions.CHANGE_FACILITY_COLOR,
  payload: { color },
  meta: {},
  error: false,
});

export const changeFacilityCarType = (carType) => ({
  type: actions.CHANGE_FACILITY_CAR_TYPE,
  payload: { carType },
  meta: {},
  error: false,
});
export const changeExpireDate = (date) => ({
  type: actions.CHANGE_EXPIRE_DATE,
  payload: { expireDate: date },
  meta: {},
  error: false,
});
export const changeFacilityMemo = (memo) => ({
  type: actions.CHANGE_FACILITY_MEMO,
  payload: { memo },
  meta: {},
  error: false,
});

export const changeFacilityWeightType = (weightType) => ({
  type: actions.CHANGE_FACILITY_WEIGHT_TYPE,
  payload: { weightType },
  meta: {},
  error: false,
});

export const changeFacilityFreightType = (freightType) => ({
  type: actions.CHANGE_FACILITY_FREIGHT_TYPE,
  payload: { freightType },
  meta: {},
  error: false,
});

export const changeRegistrationNumberPlace = (registrationNumberPlace) => ({
  type: actions.CHANGE_REGISTRATION_NUMBER_PLACE,
  payload: { registrationNumberPlace },
  meta: {},
  error: false,
});

export const changeRegistrationNumberMobileType = (registrationNumberMobileType) => ({
  type: actions.CHANGE_REGISTRATION_NUMBER_MOBILE_TYPE,
  payload: { registrationNumberMobileType },
  meta: {},
  error: false,
});

export const changeRegistrationNumberMobileUses = (registrationNumberMobileUses) => ({
  type: actions.CHANGE_REGISTRATION_NUMBER_MOBILE_USES,
  payload: { registrationNumberMobileUses },
  meta: {},
  error: false,
});

export const changeRegistrationNumberNumber = (registrationNumberNumber) => ({
  type: actions.CHANGE_REGISTRATION_NUMBER_NUMBER,
  payload: { registrationNumberNumber },
  meta: {},
  error: false,
});

export const changeFacilityDriveSystem = (driveSystem) => ({
  type: actions.CHANGE_FACILITY_DRIVE_SYSTEM,
  payload: { driveSystem },
  meta: {},
  error: false,
});

export const changeFacilityShiftLever = (shiftLever) => ({
  type: actions.CHANGE_FACILITY_SHIFT_LEVER,
  payload: { shiftLever },
  meta: {},
  error: false,
});

export const changeFacilityParkingBrake = (parkingBrake) => ({
  type: actions.CHANGE_FACILITY_PARKING_BRAKE,
  payload: { parkingBrake },
  meta: {},
  error: false,
});

export const changeFacilityKeyType = (keyType) => ({
  type: actions.CHANGE_FACILITY_KEY_TYPE,
  payload: { keyType },
  meta: {},
  error: false,
});

export const changeFacilityWithAirConditioner = (withAirConditioner) => ({
  type: actions.CHANGE_FACILITY_WITH_AIR_CONDITIONER,
  payload: { withAirConditioner },
  meta: {},
  error: false,
});

export const changeFirstRegistrationDate = (firstRegistrationDate) => ({
  type: actions.CHANGE_FIRST_REGISTRATION_DATE,
  payload: { firstRegistrationDate },
  meta: {},
  error: false,
});

export const changeFacilityUse = (use) => ({
  type: actions.CHANGE_FACILITY_USE,
  payload: { use },
  meta: {},
  error: false,
});

export const newFacility = () => ({
  type: actions.NEW_FACILITY,
  payload: {
    facilityId: -1,
    name: '',
    no: 0,
    color: '',
    carType: 1,
    expireDate: moment().startOf('day'),
    memo: '',
    weightType: -1,
    freightType: -1,
    registrationNumberPlace: '',
    registrationNumberMobileType: '',
    registrationNumberMobileUses: '',
    registrationNumberNumber: '',
    driveSystem: -1,
    shiftLever: -1,
    parkingBrake: -1,
    keyType: -1,
    withAirConditioner: -1,
    firstRegistrationDate: moment().startOf('day'),
    use: 1,
    isScrapped: false,
    scrappedDate: null,
  },
  meta: {},
  error: false,
});
export const editFacility = (facility) => ({
  type: actions.EDIT_FACILITY,
  payload: facility,
  meta: {},
  error: false,
});
export const cancelEditFacility = createAction(actions.CANCEL_EDIT_FACILITY);
export const addFacility = (userId, facility) => ({
  type: actions.ADD_FACILITY,
  payload: {
    userId,
    facility,
  },
  meta: {},
  error: false,
});
export const successAddFacility = (facility) => ({
  type: actions.SUCCESS_ADD_FACILITY,
  payload: { facility },
  meta: {},
  error: false,
});
export const errorAddFacility = (messages) => ({
  type: actions.ERROR_ADD_FACILITY,
  payload: { messages },
  meta: {},
  error: false,
});
export const saveFacility = (userId, facility) => ({
  type: actions.SAVE_FACILITY,
  payload: {
    userId,
    facility,
  },
  meta: {},
  error: false,
});

export const errorSaveFacility = createAction(actions.ERROR_SAVE_FACILITY);

export const successSaveFacility = (facility) => ({
  type: actions.SUCCESS_SAVE_FACILITY,
  payload: { facility },
  meta: {},
  error: false,
});

export const successGetFacilities = (facilities) => ({
  type: actions.SUCCESS_GET_FACILITIES,
  payload: { facilities },
  meta: {},
  error: false,
});
export const errorGetFacilities = (messages) => ({
  type: actions.ERROR_GET_FACILITIES,
  payload: { messages },
  meta: {},
  error: false,
});

export const cancelScrapFacility = createAction(actions.CANCEL_SCRAP_FACILITY);
export const confirmScrapFacility = (facilityId) => ({
  type: actions.CONFIRM_SCRAP_FACILITY,
  payload: { facilityId },
  meta: {},
  error: false,
});

export const cancelDeleteFacility = createAction(actions.CANCEL_DELETE_FACILITY);
export const confirmDeleteFacility = (facilityId) => ({
  type: actions.CONFIRM_DELETE_FACILITY,
  payload: { facilityId },
  meta: {},
  error: false,
});
export const deleteFacility = (userId, facilityId) => ({
  type: actions.DELETE_FACILITY,
  payload: { userId, facilityId },
  meta: {},
  error: false,
});
export const successDeleteFacility = (facilityId) => ({
  type: actions.SUCCESS_DELETE_FACILITY,
  payload: { facilityId },
  meta: {},
  error: false,
});
export const errorDeleteFacility = (messages) => ({
  type: actions.ERROR_DELETE_FACILITY,
  payload: { messages },
  meta: {},
  error: false,
});
