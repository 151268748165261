import { createReducer, updateState } from './utility';

export const initialUserState = {
  user: null,
};

const updateUser = (state, action) => updateState(state, { user: action.payload === null ? null : action.payload.user });

const handlers = {
  SUCCESS_LOGIN: updateUser,
  SUCCESS_FETCH_LOGIN_STATE: updateUser,
};

export const userReducer = createReducer(initialUserState, handlers);
