import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { Urls } from '../constant/url';
import { ActionTypes as types } from '../actionTypes/settingActionTypes';
import { ActionTypes as commonTypes } from '../actionTypes/actionTypes';
import settingApi from '../api/settingApi';
import api from '../api/rentalReasonMastersApi';
import {
  successGetSetting,
  errorGetSetting,
  errorSaveSetting,
  getSetting,
} from '../actions/settingActions';
import * as actions from '../actions/actions';

function* locationChangeAsync(action) {
  const path = action.payload.location.pathname;
  const state = yield select();

  if (path === Urls.Setting.path && state.userReducer.user !== null) {
    const token = localStorage.getItem('TOKEN');
    const response = yield call(settingApi.getSetting, { userId: state.userReducer.user.id, token });
    const responseOfRentalReasonMasters = yield call(api.getRentalReasonMasters, { userId: state.userReducer.user.id, token });

    if (response.status === 200 && responseOfRentalReasonMasters.status === 200) {
      yield put(successGetSetting(response.setting, responseOfRentalReasonMasters.rentalReasonMasters));
    } else if (response.status === 401 || responseOfRentalReasonMasters.status === 401) {
      yield put(actions.clearState());
      yield put(push(Urls.Login.path));
    } else {
      yield put(errorGetSetting(response.messages));
    }
  }
}

function* watchLocationChangeAsync() {
  yield takeEvery(commonTypes.LOCATION_CHANGE, locationChangeAsync);
}

function* getSettingAsync(action) {
  const state = yield select();
  const token = localStorage.getItem('TOKEN');

  if (!token || state.userReducer.user === null) {
    yield put(errorGetSetting([]));
  } else {
    const response = yield call(settingApi.getSetting, { userId: state.userReducer.user.id, token });
    const responseOfRentalReasonMasters = yield call(api.getRentalReasonMasters, { userId: state.userReducer.user.id, token });

    if (response.status === 200 || responseOfRentalReasonMasters.status === 200) {
      yield put(successGetSetting(response.setting, responseOfRentalReasonMasters.rentalReasonMasters));
    } else if (response.status === 401 || responseOfRentalReasonMasters.status === 401) {
      yield put(actions.clearState());
      yield put(push(Urls.Login.path));
    } else { yield put(errorGetSetting(response.messages)); }
  }
}

function* watchGetSettingAsync() {
  yield takeEvery(types.GET_SETTING, getSettingAsync);
}

function* saveSettingAsync(action) {
  const state = yield select();
  const token = localStorage.getItem('TOKEN');

  const response = yield call(settingApi.saveSetting, {
    token,
    userId: state.userReducer.user.id,
    setting: action.payload.setting,
  });

  const responseOfRentalReasonMasters = yield call(api.saveRentalReasonMasters, {
    userId: state.userReducer.user.id,
    token,
    rentalReasonMasters: action.payload.rentalReasonMasters,
  });

  if (response.status === 200 && responseOfRentalReasonMasters.status === 200) {
    yield put(getSetting());
  } else if (response.status === 401 || responseOfRentalReasonMasters.status === 401) {
    yield put(actions.clearState());
    yield put(push(Urls.Login.path));
  } else { yield put(errorSaveSetting()); }
}

function* watchSaveSettingAsync() {
  yield takeEvery(types.SAVE_SETTING, saveSettingAsync);
}

export const settingSaga = [
  watchLocationChangeAsync(),
  watchSaveSettingAsync(),
  watchGetSettingAsync(),
];
