import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Error } from '../../message/Error';

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    padding: 36,
  },
  supportBox: {
    maxWidth: 650,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  align: {
    textAlign: 'left',
  },
  button: {
    width: 176,
  },
}));

export const SupportForMember = ({
  content, changeContent,
  sendRequest,
  name, changeName,
  isLoading, messages,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.outerContainer}>
      <Grid container spacing={2} justify="flex-start" className={classes.supportBox}>

        <Grid item xs={12} className={classes.align}>
          <Error visible={messages.length > 0} message={messages} />
          <Typography variant="h5">
            お問合せ
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.align}>
          <Typography variant="body2">
            お問合せ内容を入力して送信してください。
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            label="お名前"
            value={name}
            onChange={(e) => changeName(e.target.value)}
            disabled={isLoading}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            label="お問合せ内容"
            margin="normal"
            variant="outlined"
            multiline
            rows={20}
            value={content}
            onChange={(e) => changeContent(e.target.value)}
            disabled={isLoading}
          />
        </Grid>

        <Grid item xs={12} className={classes.align}>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            disabled={name === '' || content === '' || isLoading}
            onClick={(e) => sendRequest(name, content)}
          >
            {isLoading ? <CircularProgress size={24} /> : <>メッセージを送信する</>}

          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
