export const ActionTypes = {

  CHANGE_STAFF_NAME: 'CHANGE_STAFF_NAME',
  CHANGE_STAFF_MEMO: 'CHANGE_STAFF_MEMO',

  NEW_STAFF: 'NEW_STAFF',
  EDIT_STAFF: 'EDIT_STAFF',
  CANCEL_EDIT_STAFF: 'CANCEL_EDIT_STAFF',

  ADD_STAFF: 'ADD_STAFF',
  SUCCESS_ADD_STAFF: 'SUCCESS_ADD_STAFF',
  ERROR_ADD_STAFF: 'ERROR_ADD_STAFF',

  SAVE_STAFF: 'SAVE_STAFF',
  SUCCESS_SAVE_STAFF: 'SUCCESS_SAVE_STAFF',
  ERROR_SAVE_STAFF: 'ERROR_SAVE_STAFF',

  SUCCESS_GET_STAFFS: 'SUCCESS_GET_STAFFS',
  ERROR_GET_STAFFS: 'ERROR_GET_STAFFS',

  CANCEL_DELETE_STAFF: 'CANCEL_DELETE_STAFF',
  CONFIRM_DELETE_STAFF: 'CONFIRM_DELETE_STAFF',
  CONFIRM_RETIRE_STAFF: 'CONFIRM_RETIRE_STAFF',
  CANCEL_RETIRE_STAFF: 'CANCEL_RETIRE_STAFF',
  DELETE_STAFF: 'DELETE_STAFF',
  SUCCESS_DELETE_STAFF: 'SUCCESS_DELETE_STAFF',
  ERROR_DELETE_STAFF: 'ERROR_DELETE_STAFF',

};
