import moment from 'moment';

export const getTimeList = (durationMinute, format = 'HH:mm') => {
  const minutesPerDay = 24 * 60;
  const now = moment();

  const timeList = [...Array(Math.floor(minutesPerDay / durationMinute)).keys()]
    .map((i) => now.startOf('date').add((i * durationMinute), 'minutes').format(format));

  return timeList;
};

export const getMinuteList = (durationMinute) => {
  const minutesPerHour = 60;

  const minuteList = [...Array(Math.floor(minutesPerHour / durationMinute)).keys()]
    .map((i) => i * durationMinute);

  return minuteList;
};
