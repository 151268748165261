import request from 'superagent';
import { Environment } from '../config/config';

function sendMemberSupportRequest(payload) {
  return new Promise((resolve, reject) => {
    request
      .post(`${Environment.ApiUrl}/member_supports`)
      .type('form')
      .set('Authorization', `Bearer ${payload.token}`)
      .send({
        name: payload.name,
        user_id: payload.userId,
        content: payload.content,
      })
      .end((err, res) => {
        if (err === null) {
          resolve({ status: res.status });
        } else {
          resolve({ status: res.status });
        }
      });
  });
}

export default {
  sendMemberSupportRequest,
};
