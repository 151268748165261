import { createAction } from 'redux-actions';
import { ActionTypes as actions } from '../actionTypes/loginActionTypes';

export const changeLoginEmail = (email) => ({
  type: actions.CHANGE_LOGIN_EMAIL,
  payload: { email },
  meta: {},
  error: false,
});
export const changeLoginPassword = (password) => ({
  type: actions.CHANGE_LOGIN_PASSWORD,
  payload: { password },
  meta: {},
  error: false,
});
export const fetchLoginState = createAction(actions.FETCH_LOGIN_STATE);
export const successFetchLoginState = (response) => ({
  type: actions.SUCCESS_FETCH_LOGIN_STATE,
  payload: response,
  meta: {},
  error: false,
});
export const login = (email, password) => ({
  type: actions.LOGIN,
  payload: { password, email },
  meta: {},
  error: false,
});
export const successLogin = (response) => ({
  type: actions.SUCCESS_LOGIN,
  payload: response,
  meta: {},
  error: false,
});
export const errorLogin = (response) => ({
  type: actions.ERROR_LOGIN,
  payload: response,
  meta: {},
  error: true,
});
export const logout = createAction(actions.LOGOUT);
export const successLogout = createAction(actions.SUCCESS_LOGOUT);
export const errorLogout = createAction(actions.ERROR_LOGOUT);

export const changeRegisteredEmail = (email) => ({
  type: actions.CHANGE_REGISTERED_EMAIL,
  payload: { email },
  meta: {},
  error: false,
});

export const sendPasswordReset = (email) => ({
  type: actions.SEND_PASSWORD_RESET,
  payload: { email },
  meta: {},
  error: false,
});

export const successSendPasswordReset = () => ({
  type: actions.SUCCESS_SEND_PASSWORD_RESET,
  payload: {},
  meta: {},
  error: false,
});

export const errorSendPasswordReset = (response) => ({
  type: actions.ERROR_SEND_PASSWORD_RESET,
  payload: response,
  meta: {},
  error: true,
});

export const errorEmailIsNotUsed = () => ({
  type: actions.ERROR_EMAIL_IS_NOT_USED,
  payload: {},
  meta: {},
  error: true,
});

export const checkValidityToChangePassword = (id) => ({
  type: actions.CHECK_VALIDITY_TO_CHANGE_PASSWORD,
  payload: { id },
  meta: {},
  error: false,
});

export const validToChangePassword = () => ({
  type: actions.VALID_TO_CHANGE_PASSWORD,
  payload: {},
  meta: {},
  error: true,
});

export const inValidToChangePassword = () => ({
  type: actions.INVALID_TO_CHANGE_PASSWORD,
  payload: {},
  meta: {},
  error: false,
});

export const validateNewPassword = (password) => ({
  type: actions.VALIDATE_NEW_PASSWORD,
  payload: { password },
  meta: {},
  error: false,
});

export const finishNewPasswordValidation = (errorMessage) => ({
  type: actions.FINISH_NEW_PASSWORD_VALIDATION,
  payload: { errorMessage },
  meta: {},
  error: false,
});

export const changeNewPassword = (password) => ({
  type: actions.CHANGE_NEW_PASSWORD,
  payload: { password },
  meta: {},
  error: false,
});

export const validateNewPasswordConfirm = (password) => ({
  type: actions.VALIDATE_NEW_PASSWORD_CONFIRM,
  payload: { password },
  meta: {},
  error: false,
});

export const finishNewPasswordConfirmValidation = (errorMessage) => ({
  type: actions.FINISH_NEW_PASSWORD_CONFIRM_VALIDATION,
  payload: { errorMessage },
  meta: {},
  error: false,
});

export const changeNewPasswordConfirm = (password) => ({
  type: actions.CHANGE_NEW_PASSWORD_CONFIRM,
  payload: { password },
  meta: {},
  error: false,
});

export const registerNewPassword = (id, password, passwordConfirm) => ({
  type: actions.REGISTER_NEW_PASSWORD,
  payload: { id, password, passwordConfirm },
  meta: {},
  error: false,
});

export const successRegisterNewPassword = () => ({
  type: actions.SUCCESS_REGISTER_NEW_PASSWORD,
  payload: { },
  meta: {},
  error: false,
});

export const errorRegisterNewPassword = (result) => ({
  type: actions.ERROR_REGISTER_NEW_PASSWORD,
  payload: { result },
  meta: {},
  error: false,
});
