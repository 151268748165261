import {
  call, put, select, takeEvery,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { Urls } from '../constant/url';
import { ActionTypes as types } from '../actionTypes/memberSupportActionTypes';
import API from '../api/memberSupportApi';
import {
  successSendRequest,
  errorSendRequest,
} from '../actions/memberSupportActions';
import { Messages as Message } from '../constant/messages';

function* sendMemberSupportRequestAsync(action) {
  const data = action.payload;
  const state = yield select();
  const token = localStorage.getItem('TOKEN');
  const response = yield call(API.sendMemberSupportRequest, {
    name: data.name,
    userId: state.userReducer.user.id,
    content: data.content,
    token,
  });

  if (response.status === 200) {
    yield put(successSendRequest());
    yield put(push(Urls.SuccessRequestMemberSupport.path));
  } else { yield put(errorSendRequest([Message.ServerError])); }
}

function* watchSendMemberSupportRequestAsync() {
  yield takeEvery(types.SEND_MEMBER_SUPPORT_REQUEST, sendMemberSupportRequestAsync);
}

export const memberSupportSaga = [
  watchSendMemberSupportRequestAsync(),
];
