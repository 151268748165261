import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

export const SortFacilityDialog = ({
  sortState,
  changeSortState,
  isSortFacilityDialogOpen,
  closeSortFacilityDialog
}) => {
  return (
    <div>
      <Dialog
        open={isSortFacilityDialogOpen}
        onClose={closeSortFacilityDialog}
      >
        <DialogTitle>並べ替え</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup value={sortState.sortType} onChange={(e) => changeSortState({ ...sortState, sortType: Number(e.target.value) })}>
                  <FormControlLabel value={0} control={<Radio />} label="並べ替えしない" />
                  <FormControlLabel value={1} control={<Radio />} label="登録番号" />
                  <FormControlLabel value={2} control={<Radio />} label="普通車/軽自動車" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained"
            onClick={closeSortFacilityDialog}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

/* const comparerList = [
  (a, b) => (a.weightType - b.weightType) * 1,
  (a, b) => (a.carType - b.carType) * -1,
  (a, b) => (a.registrationNumber - b.registrationNumber) * 1,
];
[].sort((a, b) => {
  const comparer = comparerList.find((c) => c(a, b) !== 0);
  return comparer === undefined ? 0 : comparer(a, b);
});
*/
