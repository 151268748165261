import React from 'react';
import { connect } from 'react-redux';
import { Setting } from '../../../components/pages/member/Setting';
import {
  saveSetting,
  changeDragSnapValue,
  changeDragSnapUnit,
  changeHeaderFormat,
  changeShowStaffInCard,
  changeShowCustomerInCard,
  changeShowCustomersCarInCard,
  changeLabelOfStaffInCard,
  changeLabelOfCustomerInCard,
  changeLabelOfCustomersCarInCard,
  changeDefaultCalenderSpan,
  changeRentalReasonName,
  changeShowCarHintInCalendar,
  changeShowScheduleHintInCard,
  changeBanDoubleBook,
  changeIsCardMovableSp,
  changeIsCardMovablePc,
  changeIsCardResizableSp,
  changeIsCardResizablePc,
  changeCheckWarningAsInitialValue,
  moveRentalReasonMaster,
  openColorPallet,
  changeRentalReasonColor,
  addNewRentalReasonMaster,
  deleteRentalReasonMaster,
} from '../../../actions/settingActions';

export default connect(
  (state) => ({
    userId: state.userReducer.user.id,
    setting: state.settingReducer.setting,
    rentalReasonMasters: state.settingReducer.rentalReasonMasters,
    openedColorPalletId: state.settingReducer.openedColorPalletId,
    isLoading: state.settingReducer.isLoading,
    messages: state.settingReducer.messages,
  }),
  (dispatch) => ({
    saveSetting: (setting, rentalReasonMasters) => dispatch(saveSetting(setting, rentalReasonMasters)),
    changeDragSnapValue: (value) => dispatch(changeDragSnapValue(value)),
    changeDragSnapUnit: (unit) => dispatch(changeDragSnapUnit(unit)),
    changeHeaderFormat: (format) => dispatch(changeHeaderFormat(format)),
    changeShowStaffInCard: () => dispatch(changeShowStaffInCard()),
    changeShowCustomerInCard: () => dispatch(changeShowCustomerInCard()),
    changeShowCustomersCarInCard: () => dispatch(changeShowCustomersCarInCard()),
    changeLabelOfStaffInCard: (label) => dispatch(changeLabelOfStaffInCard(label)),
    changeLabelOfCustomerInCard: (label) => dispatch(changeLabelOfCustomerInCard(label)),
    changeLabelOfCustomersCarInCard: (label) => dispatch(changeLabelOfCustomersCarInCard(label)),
    changeDefaultCalenderSpan: (span) => dispatch(changeDefaultCalenderSpan(span)),
    changeShowCarHintInCalendar: () => dispatch(changeShowCarHintInCalendar()),
    changeShowScheduleHintInCard: () => dispatch(changeShowScheduleHintInCard()),
    changeIsCardMovableSp: () => dispatch(changeIsCardMovableSp()),
    changeIsCardMovablePc: () => dispatch(changeIsCardMovablePc()),
    changeIsCardResizableSp: () => dispatch(changeIsCardResizableSp()),
    changeIsCardResizablePc: () => dispatch(changeIsCardResizablePc()),
    changeBanDoubleBook: () => dispatch(changeBanDoubleBook()),
    changeCheckWarningAsInitialValue: () => dispatch(changeCheckWarningAsInitialValue()),
    changeRentalReasonName: (rentalReasonMasterId, name) => dispatch(changeRentalReasonName(rentalReasonMasterId, name)),
    changeRentalReasonColor: (rentalReasonMasterId, color) => dispatch(changeRentalReasonColor(rentalReasonMasterId, color)),
    openColorPallet: (rentalReasonMasterId) => dispatch(openColorPallet(rentalReasonMasterId)),
    moveRentalReasonMaster: (draggedId, hoveredId) => dispatch(moveRentalReasonMaster(draggedId, hoveredId)),
    addNewRentalReasonMaster: () => dispatch(addNewRentalReasonMaster()),
    deleteRentalReasonMaster: (rentalReasonMasterId) => dispatch(deleteRentalReasonMaster(rentalReasonMasterId)),
  }),
)(Setting);
