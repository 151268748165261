import React from 'react';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteSweep } from '@material-ui/icons';
import { datetimeFormat as format } from '../../../constant/datetime';
import { TransmissionTypes } from '../../../constant/common';
import { FacilityEditDialog } from '../../FacilityEditDialog';
import { ConfirmDialog } from '../../ConfirmDialog';
import { Error } from '../../message/Error';

moment.locale('ja');

const useStyles = makeStyles((theme) => ({
  card: {
    height: 160,
    margin: 4,
    opacity: 1,
  },
  cardLoading: {
    height: 160,
    margin: 4,
    opacity: 0.3,
  },
  cardHeader: {
    textAlign: 'left',
    paddingBottom: 8,
  },
  cardContent: {
    textAlign: 'left',
    paddingBottom: 8,
    paddingTop: 8,
  },
}));

export const Facility = ({
  userId, facilities,
  isLoading,
  editingFacility,
  changeFacilityName,
  changeFacilityNo,
  changeFacilityColor,
  changeFacilityCarType,
  changeFacilityWeightType,
  changeFacilityFreightType,
  changeExpireDate,
  changeFacilityMemo,
  changeRegistrationNumberPlace,
  changeRegistrationNumberMobileType,
  changeRegistrationNumberMobileUses,
  changeRegistrationNumberNumber,
  changeFacilityDriveSystem,
  changeFacilityShiftLever,
  changeFacilityParkingBrake,
  changeFacilityKeyType,
  changeFacilityWithAirConditioner,
  changeFacilityUse,
  changeFirstRegistrationDate,
  newFacility, cancelEditFacility,
  addFacility,
  editFacility,
  saveFacility,
  confirmDeleteId, confirmDeleteFacility, deleteFacility, cancelDeleteFacility,
  confirmScrapId, confirmScrapFacility, cancelScrapFacility,
  messages,
}) => {
  const classes = useStyles();
  const deleteTarget = facilities.find((f) => f.facilityId === confirmDeleteId);
  const scrapTarget = facilities.find((f) => f.facilityId === confirmScrapId);
  const today = moment();
  return (
    <div style={{ textAlign: 'center' }}>
      <Grid container spacing={10}>
        <Grid item xs={12} style={{ position: 'relative' }}>

          {isLoading
            ? (
              <CircularProgress
                color="primary"
                style={{
                  position: 'fixed', top: '50%', left: '50%', zIndex: 1400,
                }}
              />
            )
            : false}

          <Error visible={messages.length > 0} message={messages} />

          {typeof deleteTarget === 'undefined'
            ? false
            : (
              <ConfirmDialog
                title="確認"
                content={(
                  <span style={{ whiteSpace: 'pre-wrap' }}>
                    以下の代車を削除します。
                    <br />
                    よろしいですか？
                    <br />
                    ※代車を廃車する場合は廃車ボタンから廃車処理を行ってください。
                    <br />
                    代車名：
                    {deleteTarget.name}
                    <br />
                    車種：
                    {TransmissionTypes.find((t) => t.value === deleteTarget.carType).key}
                    <br />
                    車検期限：
                    {deleteTarget.expireDate.format(format.YYYYMMDDdddJp)}
                    <br />
                    メモ：
                    {deleteTarget.memo}
                  </span>
              )}
                cancelButtonText="キャンセル"
                okButtonText="OK"
                isOpen
                ok={(e) => deleteFacility(userId, deleteTarget.facilityId)}
                cancel={(e) => cancelDeleteFacility()}
              />
            )}

          {typeof scrapTarget === 'undefined'
            ? false
            : (
              <ConfirmDialog
                title="確認"
                content={(
                  <span style={{ whiteSpace: 'pre-wrap' }}>
                    以下の代車を廃車済みにします。
                    <br />
                    よろしいですか？
                    <br />
                    代車名：
                    {scrapTarget.name}
                    <br />
                    車種：
                    {TransmissionTypes.find((t) => t.value === scrapTarget.carType).key}
                    <br />
                    車検期限：
                    {scrapTarget.expireDate.format(format.YYYYMMDDdddJp)}
                    <br />
                    メモ：
                    {scrapTarget.memo}
                  </span>
              )}
                cancelButtonText="キャンセル"
                okButtonText="OK"
                isOpen
                ok={(e) => {
                  saveFacility(userId, { ...scrapTarget, isScrapped: true, scrappedDate: moment() });
                  cancelScrapFacility();
                }}
                cancel={(e) => cancelScrapFacility()}
              />
            )}

          {facilities.filter((f) => !f.isScrapped).map((f) => (
            <Card classes={{ root: isLoading ? classes.cardLoading : classes.card }} key={f.facilityId}>
              <CardHeader
                title={(f.no === '' ? '' : `${f.no}.`) + f.name}
                classes={{ root: classes.cardHeader }}
                action={(
                  <div>
                    <IconButton variant="contained" color="primary" onClick={(e) => editFacility(f)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton variant="contained" color="default" onClick={(e) => confirmScrapFacility(f.facilityId)}>
                      <DeleteSweep />
                    </IconButton>
                    <IconButton variant="contained" color="secondary" onClick={(e) => confirmDeleteFacility(f.facilityId)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </div>
                  )}
                titleTypographyProps={{ variant: 'h6' }}
              />
              <CardContent classes={{ root: classes.cardContent }}>
                <Typography variant="subtitle1">
                  変速機：
                  { TransmissionTypes.find((t) => t.value === f.carType).key }
                  <br />
                  登録番号：
                  {`${f.registrationNumberPlace} ${f.registrationNumberMobileType} ${f.registrationNumberMobileUses} ${f.registrationNumberNumber}`}
&nbsp;
                </Typography>

                <Typography variant="subtitle1">
                  車検期限：
                  {f.expireDate.format(format.YYYYMMDDdddJp)}
                  {f.expireDate <= today
                    ? (
                      <Tooltip title={(
                        <span style={{ fontSize: 16, whiteSpace: 'pre-wrap' }}>
                          車検期限が切れています
                        </span>
                      )}
                      >
                        <ErrorIcon style={{ color: 'red' }} />
                      </Tooltip>
                    )
                    : (f.expireDate.diff(today, 'days') <= 30)
                      ? (
                        <Tooltip title={(
                          <span style={{ fontSize: 16, whiteSpace: 'pre-wrap' }}>
                            車検期限まで30日以内です
                          </span>
                        )}
                        >
                          <WarningIcon style={{ color: 'darkorange' }} />
                        </Tooltip>
                      )
                      : false}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Grid>
      </Grid>

      <FacilityEditDialog
        userId={userId}
        title="代車編集"
        isOpen={editingFacility !== null && editingFacility.facilityId >= 0}
        facility={editingFacility}
        cancelEditFacility={cancelEditFacility}
        addFacility={null}
        saveFacility={saveFacility}
        changeFacilityName={changeFacilityName}
        changeFacilityCarType={changeFacilityCarType}
        changeExpireDate={changeExpireDate}
        changeFacilityMemo={changeFacilityMemo}
        {...{
          changeFacilityColor,
          changeFacilityNo,
          changeRegistrationNumberPlace,
          changeRegistrationNumberMobileType,
          changeRegistrationNumberMobileUses,
          changeRegistrationNumberNumber,
          changeFacilityDriveSystem,
          changeFacilityShiftLever,
          changeFacilityParkingBrake,
          changeFacilityKeyType,
          changeFacilityWithAirConditioner,
          changeFirstRegistrationDate,
          changeFacilityWeightType,
          changeFacilityFreightType,
          changeFacilityUse,
        }}
        isLoading={isLoading}
        messages={messages}
      />

      <FacilityEditDialog
        userId={userId}
        title="新規代車"
        isOpen={editingFacility !== null && editingFacility.facilityId < 0}
        facility={editingFacility}
        cancelEditFacility={cancelEditFacility}
        addFacility={addFacility}
        saveFacility={null}
        changeFacilityName={changeFacilityName}
        changeFacilityCarType={changeFacilityCarType}
        changeExpireDate={changeExpireDate}
        changeFacilityMemo={changeFacilityMemo}
        {...{
          changeFacilityColor,
          changeFacilityNo,
          changeRegistrationNumberPlace,
          changeRegistrationNumberMobileType,
          changeRegistrationNumberMobileUses,
          changeRegistrationNumberNumber,
          changeFacilityDriveSystem,
          changeFacilityShiftLever,
          changeFacilityParkingBrake,
          changeFacilityKeyType,
          changeFacilityWithAirConditioner,
          changeFirstRegistrationDate,
          changeFacilityWeightType,
          changeFacilityFreightType,
          changeFacilityUse,
        }}
        isLoading={isLoading}
        messages={messages}
      />

      <Fab
        variant="extended"
        color="primary"
        style={{ bottom: '2%', position: 'fixed' }}
        disabled={isLoading}
        onClick={(e) => newFacility()}
      >
        <AddIcon />
        新規作成
      </Fab>
    </div>

  );
};
