export const Urls = {
  CreateAccount: { path: '/', name: 'アカウント作成' },
  SuccessCreateAccount: { path: '/success_create_account', name: 'Email認証用メール送信完了' },
  ConfirmEmailVerification: { path: '/confirm_email_verification/:token', name: 'Email認証' },
  Login: { path: '/login', name: 'ログイン' },
  ForgetPassword: { path: '/forget_password', name: 'パスワード再設定メール送信' },
  SentResetPasswordEmail: { path: '/sent_reset_password_mail', name: 'パスワード再設定メール送信完了' },
  ResetPassword: { path: '/reset_password/:id', name: 'パスワード再設定' },
  Support: { path: '/support', name: 'お問合せ' },
  SuccessRequestGuestSupport: { path: '/success_request_guest_support', name: 'お問合せメール送信完了' },

  MemberTop: { path: '/member/membertop', name: 'トップ' },
  Schedule: { path: '/member/schdule', name: '代車予約管理' },
  Customer: { path: '/member/customer', name: 'お客様' },
  Staff: { path: '/member/staff', name: 'スタッフ' },
  Facility: { path: '/member/facility', name: '代車' },
  Setting: { path: '/member/setting', name: '設定' },
  SupportForMember: { path: '/member/support_for_member', name: 'お問合せ' },
  SuccessRequestMemberSupport: { path: '/member/success_request_member_support', name: 'お問合せメール送信完了' },
};
