import request from 'superagent';
import { Environment } from '../config/config';

function getSetting (payload) {
  return new Promise((resolve, reject) => {
    request
      .get(Environment.ApiUrl + '/users/' + payload.userId + '/settings')
      .set('Authorization', 'Bearer ' + payload.token)
      .end((err, res) => {
        if (err === null) {
          resolve({
            status: res.status,
            setting: {
              settingId: res.body.setting.id,
              dragSnapValue: res.body.setting.drag_snap_value,
              dragSnapUnit: res.body.setting.drag_snap_unit,
              headerFormat: res.body.setting.header_format,
              showStaffInCard: res.body.setting.show_staff_in_card,
              showCustomerInCard: res.body.setting.show_customer_in_card,
              showCustomersCarInCard: res.body.setting.show_customers_car_in_card,
              labelOfStaffInCard: res.body.setting.label_of_staff_in_card,
              labelOfCustomerInCard: res.body.setting.label_of_customer_in_card,
              labelOfCustomersCarInCard: res.body.setting.label_of_customers_car_in_card,
              defaultCalenderSpan: res.body.setting.default_calender_span,
              showCarHintInCalendar: res.body.setting.show_car_hint_in_calendar,
              showScheduleHintInCard: res.body.setting.show_schedule_hint_in_card,
              checkWarningAsInitialValue: res.body.setting.check_warning_as_initial_value,
              banDoubleBook: res.body.setting.ban_double_book,
              isCardMovableSp: res.body.setting.is_card_movable_sp,
              isCardMovablePc: res.body.setting.is_card_movable_pc,
              isCardResizableSp: res.body.setting.is_card_resizable_sp,
              isCardResizablePc: res.body.setting.is_card_resizable_pc
            }
          });
        } else { resolve({ status: res.status, setting: {} }); }
      });
  });
}

function saveSetting (payload) {
  return new Promise((resolve, reject) => {
    request
      .put(Environment.ApiUrl + '/users/' + payload.userId + '/settings/' + payload.setting.id)
      .set('Authorization', 'Bearer ' + payload.token)
      .send({
        drag_snap_value: payload.setting.dragSnapValue,
        drag_snap_unit: payload.setting.dragSnapUnit,
        header_format: payload.setting.headerFormat,
        show_staff_in_card: payload.setting.showStaffInCard,
        show_customer_in_card: payload.setting.showCustomerInCard,
        show_customers_car_in_card: payload.setting.showCustomersCarInCard,
        label_of_staff_in_card: payload.setting.labelOfStaffInCard,
        label_of_customer_in_card: payload.setting.labelOfCustomerInCard,
        label_of_customers_car_in_card: payload.setting.labelOfCustomersCarInCard,
        default_calender_span: payload.setting.defaultCalenderSpan,
        show_car_hint_in_calendar: payload.setting.showCarHintInCalendar,
        show_schedule_hint_in_card: payload.setting.showScheduleHintInCard,
        check_warning_as_initial_value: payload.setting.checkWarningAsInitialValue,
        ban_double_book: payload.setting.banDoubleBook,
        is_card_movable_sp: payload.setting.isCardMovableSp,
        is_card_movable_pc: payload.setting.isCardMovablePc,
        is_card_resizable_sp: payload.setting.isCardResizableSp,
        is_card_resizable_pc: payload.setting.isCardResizablePc
      })
      .end((err, res) => {
        if (err === null) { resolve({ status: res.status }); } else { resolve({ status: res.status }); }
      });
  });
}

export default {
  getSetting,
  saveSetting
};
