import { fromJS, List } from "immutable";
import { Urls } from "../constant/url";
import { createReducer, updateState } from "./utility";
import moment from "moment";
import { isThisScheduleConflicting } from "../validater/scheduleValidater";
import { Messages } from "../constant/messages";

const now = moment();
export const initialScheduleState = {
  defaultTimeStart: now.clone().startOf("day").valueOf(),
  defaultTimeEnd: now.clone().startOf("day").add(4, "day").valueOf(),
  previousCanvasTimeStart: now.clone().startOf("day")
    .subtract(1, "day")
    .valueOf(),
  previousCanvasTimeEnd: now.clone().startOf("day")
    .add(2, "day")
    .valueOf(),
  schedules: [],
  customerAge: 0,
  messages: [],
  deleteConfirmId: -1,
  editedSchedule: null,
  isLoading: false,
  filterState: {
    transmissionType: -1,
    weightType: -1,
    use: -1,
  },
  sortState: {
    sortType: 0,
  },
  isFilterFacilityDialogOpen: false,
  isSortFacilityDialogOpen: false,
  showCarList: true,
  isHover: false,
  isHoveringOnGroups: false,
  itemContextObject: null,
  canvasContextObject: null,
  copiedSchedule: null,
};

const locationChange = (state, action) => {
  if (action.payload.location.pathname === Urls.Schedule.path) {
    return updateState(state, { isLoading: true, messages: [] });
  }

  return state;
};

const successGetSchedules = (state, action) =>
  updateState(state, { schedules: action.payload.schedules, isLoading: false });

const errorGetSchedules = (state, action) =>
  updateState(state, { messages: action.payload.messages, isLoading: false });

const addSchedule = (state, action) => updateState(state, { isLoading: true });

const successAddSchedule = (state, action) =>
  fromJS(state)
    .update("schedules", (s) => s.push(action.payload.schedule))
    .set("editedSchedule", null)
    .set("isLoading", false)
    .toJS();

const errorAddSchedule = (state, action) =>
  updateState(state, { messages: action.payload.messages });

const newSchedule = (state, action) =>
  updateState(state, { editedSchedule: action.payload.editedSchedule });

const changeSelectedStaff = (state, action) =>
  fromJS(state)
    .setIn(["editedSchedule", "staffId"], action.payload.staffId)
    .toJS();

const changeSelectedCustomer = (state, action) =>
  fromJS(state)
    .setIn(["editedSchedule", "customerId"], action.payload.customerId)
    .toJS();

const changeSelectedFacility = (state, action) => {
  const editedSchedule = {
    ...state.editedSchedule,
    facilityId: action.payload.facilityId,
  };

  if (isThisScheduleConflicting(editedSchedule, state.schedules)) {
    return {
      ...state,
      editedSchedule,
      messages: [...state.messages, Messages.ConflictingSchedule],
    };
  }

  return {
    ...state,
    editedSchedule,
    messages: state.messages.filter((m) => m !== Messages.ConflictingSchedule),
  };
};

const changeFreeFirstName = (state, action) =>
  fromJS(state)
    .setIn(["editedSchedule", "freeFirstName"], action.payload.freeFirstName)
    .toJS();

const changeFreeLastName = (state, action) =>
  fromJS(state)
    .setIn(["editedSchedule", "freeLastName"], action.payload.freeLastName)
    .toJS();

const changeCustomersCar = (state, action) =>
  fromJS(state)
    .setIn(["editedSchedule", "customersCar"], action.payload.customersCar)
    .toJS();

const changeMemo = (state, action) =>
  fromJS(state).setIn(["editedSchedule", "memo"], action.payload.memo).toJS();

const changeRequestedCarType = (state, action) =>
  fromJS(state)
    .setIn(
      ["editedSchedule", "requestedCarType"],
      action.payload.requestedCarType
    )
    .toJS();

const changeRequestedWeightType = (state, action) =>
  fromJS(state)
    .setIn(
      ["editedSchedule", "requestedWeightType"],
      action.payload.requestedWeightType
    )
    .toJS();

const changeRequestedFreightType = (state, action) =>
  fromJS(state)
    .setIn(
      ["editedSchedule", "requestedFreightType"],
      action.payload.requestedFreightType
    )
    .toJS();

const changeRequestedDriveSystem = (state, action) =>
  fromJS(state)
    .setIn(
      ["editedSchedule", "requestedDriveSystem"],
      action.payload.requestedDriveSystem
    )
    .toJS();

const changeRequestedShiftLever = (state, action) =>
  fromJS(state)
    .setIn(
      ["editedSchedule", "requestedShiftLever"],
      action.payload.requestedShiftLever
    )
    .toJS();

const changeRequestedParkingBrake = (state, action) =>
  fromJS(state)
    .setIn(
      ["editedSchedule", "requestedParkingBrake"],
      action.payload.requestedParkingBrake
    )
    .toJS();

const changeRequestedKeyType = (state, action) =>
  fromJS(state)
    .setIn(
      ["editedSchedule", "requestedKeyType"],
      action.payload.requestedKeyType
    )
    .toJS();

const changeRequestedWithAirConditioner = (state, action) =>
  fromJS(state)
    .setIn(
      ["editedSchedule", "requestedWithAirConditioner"],
      action.payload.requestedWithAirConditioner
    )
    .toJS();

const changeWarning = (state, action) =>
  fromJS(state)
    .setIn(["editedSchedule", "warning"], !state.editedSchedule.warning)
    .toJS();

const changeSelectedRentalReason = (state, action) =>
  fromJS(state)
    .setIn(["editedSchedule", "rentalReason"], action.payload.rentalReason)
    .toJS();

const changeStart = (state, action) => {
  const editedSchedule = {
    ...state.editedSchedule,
    start: action.payload.start,
  };

  const newState = {
    ...state,
    editedSchedule: editedSchedule,
  };

  let newMessages = [];
  newMessages = isThisScheduleConflicting(editedSchedule, state.schedules)
    ? [...newMessages, Messages.ConflictingSchedule]
    : newMessages.filter((m) => m !== Messages.ConflictingSchedule);

  newMessages = editedSchedule.start.isAfter(editedSchedule.end)
    ? [...newMessages, Messages.ReverseStartEndScheduleTime]
    : newMessages.filter((m) => m !== Messages.ReverseStartEndScheduleTime);

  return {
    ...newState,
    messages: newMessages,
  };
};

const changeEnd = (state, action) => {
  const editedSchedule = {
    ...state.editedSchedule,
    end: action.payload.end,
  };

  const newState = {
    ...state,
    editedSchedule: editedSchedule,
  };

  let newMessages = [];
  newMessages = isThisScheduleConflicting(editedSchedule, state.schedules)
    ? [...newMessages, Messages.ConflictingSchedule]
    : newMessages.filter((m) => m !== Messages.ConflictingSchedule);

  newMessages = editedSchedule.start.isAfter(editedSchedule.end)
    ? [...newMessages, Messages.ReverseStartEndScheduleTime]
    : newMessages.filter((m) => m !== Messages.ReverseStartEndScheduleTime);

  return {
    ...newState,
    messages: newMessages,
  };
};

const editSchedule = (state, action) =>
  updateState(state, {
    editedSchedule: action.payload.schedule,
  });

const cancelEditSchedule = (state, action) =>
  updateState(state, { editedSchedule: null });

const openScheduleCardContext = (state, action) =>
  updateState(state, {
    itemContextObject: action.payload.itemContextObject,
  });

const closeScheduleCardContext = (state, action) =>
  updateState(state, {
    itemContextObject: null,
  });

const openCanvasContext = (state, action) =>
  updateState(state, {
    canvasContextObject: action.payload.canvasContextObject,
  });

const closeCanvasContext = (state, action) =>
  updateState(state, {
    canvasContextObject: null,
  });

const saveSchedule = (state, action) => {
  const copyState = fromJS(state);
  const idx = copyState
    .get("schedules")
    .findIndex(
      (s) => s.get("scheduleId") === action.payload.schedule.scheduleId
    );
  const newState = copyState
    .setIn(["schedules", idx], action.payload.schedule)
    .set("isLoading", true)
    .toJS();

  if (!isThisScheduleConflicting(action.payload.schedule, state.schedules)) {
    return {
      ...newState,
      messages: [],
    };
  }

  return {
    ...newState,
    messages: [...state.messages, Messages.ConflictingSchedule],
  };
};

const copySchedule = (state, action) =>
  updateState(state, { copiedSchedule: action.payload.schedule });

const successSaveSchedule = (state, action) =>
  updateState(state, { editedSchedule: null, isLoading: false });

const errorSaveSchedule = (state, action) =>
  updateState(state, { messages: action.payload.messages, isLoading: false });

const cancelDeleteSchedule = (state, action) =>
  updateState(state, { deleteConfirmId: -1 });

const confirmDeleteSchedule = (state, action) =>
  updateState(state, { deleteConfirmId: action.payload.scheduleId });

const deleteSchedule = (state, action) =>
  updateState(state, { isLoading: true, deleteConfirmId: -1 });

const successDeleteSchedule = (state, action) => {
  const newSchedule = List(
    state.schedules.filter((s) => s.scheduleId !== action.payload.scheduleId)
  );
  const deletedState = fromJS(state)
    .set("schedules", newSchedule)
    .set("deleteConfirmId", -1)
    .set("editedSchedule", null)
    .set("isLoading", false)
    .toJS();
  return deletedState;
};

const errorDeleteSchedule = (state, action) =>
  updateState(state, {
    messages: action.payload.messages,
    isLoading: false,
  });

const changeFilterState = (state, action) =>
  updateState(state, { filterState: action.payload.filterState });

const openFilterFacilityDialog = (state, action) =>
  updateState(state, { isFilterFacilityDialogOpen: true });

const closeFilterFacilityDialog = (state, action) =>
  updateState(state, { isFilterFacilityDialogOpen: false });

const changeSortState = (state, action) =>
  updateState(state, { sortState: action.payload.sortState });

const openSortFacilityDialog = (state, action) =>
  updateState(state, { isSortFacilityDialogOpen: true });

const closeSortFacilityDialog = (state, action) =>
  updateState(state, { isSortFacilityDialogOpen: false });

const toggleCarList = (state, action) =>
  updateState(state, { showCarList: !state.showCarList });

const changeDefaultStartEnd = (state, action) =>
  updateState(state, {
    defaultTimeStart: action.payload.defaultTimeStart,
    defaultTimeEnd: action.payload.defaultTimeEnd,
  });

const hoverEnd = (state, action) => updateState(state, { isHover: false });

const hover = (state, action) => updateState(state, { isHover: true });

const hoverOnGroupsEnd = (state, action) =>
  updateState(state, { isHoveringOnGroups: false });

const hoverOnGroups = (state, action) =>
  updateState(state, { isHoveringOnGroups: true });

const scrollOnDrag = (state, action) => {
  const copyState = fromJS(state);
  const idx = copyState
    .get("schedules")
    .findIndex((s) => s.get("scheduleId") === action.payload.scheduleId);
  const newState = copyState
    .setIn(["schedules", idx, "start"], action.payload.scheduleStart)
    .setIn(["schedules", idx, "end"], action.payload.scheduleEnd)
    .set("defaultTimeStart", action.payload.visibleTimeStart)
    .set("defaultTimeEnd", action.payload.visibleTimeEnd)
    .toJS();

  return newState;
};

const updateCanvasTime = (state, action) => {
  const { end, start } = action.payload;
  return {
    ...state,
    previousCanvasTimeStart: start,
    previousCanvasTimeEnd: end,
  };
};

const handlers = {
  "@@router/LOCATION_CHANGE": locationChange,
  UPDATE_CANVAS_TIME: updateCanvasTime,
  SUCCESS_GET_SCHEDULES: successGetSchedules,
  ERROR_GET_SCHEDULES: errorGetSchedules,
  CHANGE_SELECTED_CUSTOMER: changeSelectedCustomer,
  CHANGE_SELECTED_FACILITY: changeSelectedFacility,
  CHANGE_FREE_FIRST_NAME: changeFreeFirstName,
  CHANGE_FREE_LAST_NAME: changeFreeLastName,
  CHANGE_CUSTOMERS_CAR: changeCustomersCar,
  CHANGE_SELECTED_STAFF: changeSelectedStaff,
  CHANGE_MEMO: changeMemo,
  CHANGE_WARNING: changeWarning,
  CHANGE_SELECTED_RENTAL_REASON: changeSelectedRentalReason,
  CHANGE_START: changeStart,
  CHANGE_END: changeEnd,

  CHANGE_REQUESTED_CAR_TYPE: changeRequestedCarType,
  CHANGE_REQUESTED_WEIGHT_TYPE: changeRequestedWeightType,
  CHANGE_REQUESTED_FREIGHT_TYPE: changeRequestedFreightType,
  CHANGE_REQUESTED_DRIVE_SYSTEM: changeRequestedDriveSystem,
  CHANGE_REQUESTED_SHIFT_LEVER: changeRequestedShiftLever,
  CHANGE_REQUESTED_PARKING_BRAKE: changeRequestedParkingBrake,
  CHANGE_REQUESTED_KEY_TYPE: changeRequestedKeyType,
  CHANGE_REQUESTED_WITH_AIR_CONDITIONER: changeRequestedWithAirConditioner,

  CANCEL_EDIT_SCHEDULE: cancelEditSchedule,
  NEW_SCHEDULE: newSchedule,
  ADD_SCHEDULE: addSchedule,
  SUCCESS_ADD_SCHEDULE: successAddSchedule,
  ERROR_ADD_SCHEDULE: errorAddSchedule,
  EDIT_SCHEDULE: editSchedule,
  SAVE_SCHEDULE: saveSchedule,
  SUCCESS_SAVE_SCHEDULE: successSaveSchedule,
  ERROR_SAVE_SCHEDULE: errorSaveSchedule,
  DELETE_SCHEDULE: deleteSchedule,
  CANCEL_DELETE_SCHEDULE: cancelDeleteSchedule,
  CONFIRM_DELETE_SCHEDULE: confirmDeleteSchedule,
  SUCCESS_DELETE_SCHEDULE: successDeleteSchedule,
  ERROR_DELETE_SCHEDULE: errorDeleteSchedule,
  CHANGE_FILTER_STATE: changeFilterState,
  OPEN_FILTER_FACILITY_DIALOG: openFilterFacilityDialog,
  CLOSE_FILTER_FACILITY_DIALOG: closeFilterFacilityDialog,
  CHANGE_SORT_STATE: changeSortState,
  OPEN_SORT_FACILITY_DIALOG: openSortFacilityDialog,
  CLOSE_SORT_FACILITY_DIALOG: closeSortFacilityDialog,
  TOGGLE_CAR_LIST: toggleCarList,
  OPEN_SCHEDULE_CARD_CONTEXT: openScheduleCardContext,
  CLOSE_SCHEDULE_CARD_CONTEXT: closeScheduleCardContext,
  OPEN_CANVAS_CONTEXT: openCanvasContext,
  CLOSE_CANVAS_CONTEXT: closeCanvasContext,
  COPY_SCHEDULE: copySchedule,
  CHANGE_DEFAULT_TIME_START_END: changeDefaultStartEnd,
  HOVER: hover,
  HOVER_END: hoverEnd,
  HOVER_ON_GROUPS: hoverOnGroups,
  HOVER_ON_GROUPS_END: hoverOnGroupsEnd,
  SCROLL_ON_DRAG: scrollOnDrag,
};

export const scheduleReducer = createReducer(initialScheduleState, handlers);
