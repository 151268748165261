import React, { FC, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-XBMH780YNS');
export const Ga4: FC = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname + location.search,
        });
    }, [location]);
    return <>{children}</>;
};

