import React from "react";
import { connect } from "react-redux";
import { Facility } from "../../../components/pages/member/Facility";
import {
  addFacility,
  cancelDeleteFacility,
  cancelEditFacility,
  changeExpireDate,
  changeFacilityCarType,
  changeFacilityWeightType,
  changeFacilityFreightType,
  changeFacilityMemo,
  changeFacilityName,
  changeFacilityNo,
  changeFacilityColor,
  confirmDeleteFacility,
  confirmScrapFacility,
  cancelScrapFacility,
  deleteFacility,
  editFacility,
  newFacility,
  saveFacility,
  changeRegistrationNumberPlace,
  changeRegistrationNumberMobileType,
  changeRegistrationNumberMobileUses,
  changeRegistrationNumberNumber,
  changeFacilityDriveSystem,
  changeFacilityShiftLever,
  changeFacilityParkingBrake,
  changeFacilityKeyType,
  changeFacilityWithAirConditioner,
  changeFirstRegistrationDate,
  changeFacilityUse,
} from "../../../actions/facilityActions";

export default connect(
  (state) => ({
    facilities: state.facilityReducer.facilities,
    facilityName: state.facilityReducer.facilityName,
    carType: state.facilityReducer.carType,
    messages: state.facilityReducer.messages,
    userId: state.userReducer.user.id,
    confirmDeleteId: state.facilityReducer.confirmDeleteId,
    confirmScrapId: state.facilityReducer.confirmScrapId,
    editingFacility: state.facilityReducer.editingFacility,
    isLoading: state.facilityReducer.isLoading,
  }),
  (dispatch) => ({
    changeFacilityName: (name) => dispatch(changeFacilityName(name)),
    changeFacilityNo: (no) => dispatch(changeFacilityNo(no)),
    changeFacilityColor: (color) => dispatch(changeFacilityColor(color)),
    changeFacilityCarType: (type) => dispatch(changeFacilityCarType(type)),
    changeFacilityWeightType: (value) =>
      dispatch(changeFacilityWeightType(value)),
    changeFacilityFreightType: (value) =>
      dispatch(changeFacilityFreightType(value)),
    changeExpireDate: (date) => dispatch(changeExpireDate(date)),
    changeFacilityMemo: (memo) => dispatch(changeFacilityMemo(memo)),

    changeRegistrationNumberPlace: (value) =>
      dispatch(changeRegistrationNumberPlace(value)),
    changeRegistrationNumberMobileType: (value) =>
      dispatch(changeRegistrationNumberMobileType(value)),
    changeRegistrationNumberMobileUses: (value) =>
      dispatch(changeRegistrationNumberMobileUses(value)),
    changeRegistrationNumberNumber: (value) =>
      dispatch(changeRegistrationNumberNumber(value)),
    changeFacilityDriveSystem: (value) =>
      dispatch(changeFacilityDriveSystem(value)),
    changeFacilityShiftLever: (value) =>
      dispatch(changeFacilityShiftLever(value)),
    changeFacilityParkingBrake: (value) =>
      dispatch(changeFacilityParkingBrake(value)),
    changeFacilityKeyType: (value) => dispatch(changeFacilityKeyType(value)),
    changeFacilityWithAirConditioner: (value) =>
      dispatch(changeFacilityWithAirConditioner(value)),
    changeFirstRegistrationDate: (value) =>
      dispatch(changeFirstRegistrationDate(value)),
    changeFacilityUse: (value) => dispatch(changeFacilityUse(value)),

    newFacility: () => dispatch(newFacility()),
    editFacility: (facility) => dispatch(editFacility(facility)),
    cancelEditFacility: () => dispatch(cancelEditFacility()),
    addFacility: (userId, facility) => dispatch(addFacility(userId, facility)),
    saveFacility: (userId, facility) =>
      dispatch(saveFacility(userId, facility)),

    deleteFacility: (userId, facilityId) =>
      dispatch(deleteFacility(userId, facilityId)),
    cancelDeleteFacility: () => dispatch(cancelDeleteFacility()),
    confirmDeleteFacility: (facilityId) =>
      dispatch(confirmDeleteFacility(facilityId)),
    confirmScrapFacility: (facilityId) =>
      dispatch(confirmScrapFacility(facilityId)),
    cancelScrapFacility: () => dispatch(cancelScrapFacility()),
  })
)(Facility);
