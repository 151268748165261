import { createReducer, updateState } from './utility';
import { Messages } from '../constant/messages';

export const initialLoginState = {
  isLoggedIn: false,
  loginFailed: false,
  logoutFailed: false,
  email: '',
  password: '',
  registeredEmail: '',
  isLoading: false,
  errorMessages: [],
  newPassword: '',
  newPasswordConfirm: '',
  errorNewPasswordMessage: [],
  errorNewPasswordConfirmMessage: [],
  errorNewEmailMessage: [],
  errorServerMessage: [],
  successRegisterNewPasswordMessage: [],
};

const successFetchLoginState = (state, action) => updateState(state, { isLoggedIn: action.payload === null ? false : action.payload.status === 200 });

const changeLoginEmail = (state, action) => updateState(state, { email: action.payload.email });

const changeLoginPassword = (state, action) => updateState(state, { password: action.payload.password });

const successLogin = (state, action) => updateState(state, { isLoggedIn: true });

const errorLogin = (state, action) => updateState(state, { loginFailed: true });

const successLogout = (state, action) => updateState(state, { isLoggedIn: false });

const errorLogout = (state, action) => updateState(state, { logoutFailed: true });

const changeRegisteredEmail = (state, action) => updateState(state, { registeredEmail: action.payload.email });

const sendPasswordReset = (state, action) => updateState(state, { isLoading: true });

const successSendPasswordReset = (state, action) => updateState(state, { isLoading: false });

const errorSendPasswordReset = (state, action) => updateState(state, { isLoading: false, errorMessages: [Messages.ServerError] });

const errorEmailIsNotUsed = (state, action) => updateState(state, { isLoading: false, errorMessages: [Messages.UnUsedMail] });

const inValidToChangePassword = (state, action) => updateState(state, { isLoading: false, errorMessages: [Messages.InvalidTokenToChangePassword] });

const changeNewPassword = (state, action) => updateState(state, { newPassword: action.payload.password });

const changeNewPasswordConfirm = (state, action) => updateState(state, { newPasswordConfirm: action.payload.password });

const finishNewPasswordValidation = (state, action) => updateState(state, { errorNewPasswordMessage: action.payload.errorMessage });

const finishNewPasswordConfirmValidation = (state, action) => updateState(state, { errorNewPasswordConfirmMessage: action.payload.errorMessage });

const successRegisterNewPassword = (state, action) => updateState(state, {
  successRegisterNewPasswordMessage: [Messages.PasswordUpdated],
  newPassword: '',
  newPasswordConfirm: '',
  errorServerMessage: [],
  errorNewPasswordMessage: [],
  errorNewPasswordConfirmMessage: [],
});

const errorRegisterNewPassword = (state, action) => updateState(state, {
  errorNewPasswordMessage: action.payload.result.errorNewPasswordMessage,
  errorNewPasswordConfirmMessage: action.payload.result.errorNewPasswordConfirmMessage,
  errorServerMessage: action.payload.result.errorServerMessage,
});

const handlers = {
  SUCCESS_FETCH_LOGIN_STATE: successFetchLoginState,
  CHANGE_LOGIN_EMAIL: changeLoginEmail,
  CHANGE_LOGIN_PASSWORD: changeLoginPassword,
  SUCCESS_LOGIN: successLogin,
  ERROR_LOGIN: errorLogin,
  SUCCESS_LOGOUT: successLogout,
  ERROR_LOGOUT: errorLogout,
  CHANGE_REGISTERED_EMAIL: changeRegisteredEmail,
  SEND_PASSWORD_RESET: sendPasswordReset,
  SUCCESS_SEND_PASSWORD_RESET: successSendPasswordReset,
  ERROR_SEND_PASSWORD_RESET: errorSendPasswordReset,
  ERROR_EMAIL_IS_NOT_USED: errorEmailIsNotUsed,
  INVALID_TO_CHANGE_PASSWORD: inValidToChangePassword,
  CHANGE_NEW_PASSWORD: changeNewPassword,
  CHANGE_NEW_PASSWORD_CONFIRM: changeNewPasswordConfirm,
  FINISH_NEW_PASSWORD_VALIDATION: finishNewPasswordValidation,
  FINISH_NEW_PASSWORD_CONFIRM_VALIDATION: finishNewPasswordConfirmValidation,
  SUCCESS_REGISTER_NEW_PASSWORD: successRegisterNewPassword,
  ERROR_REGISTER_NEW_PASSWORD: errorRegisterNewPassword,
};

export const loginReducer = createReducer(initialLoginState, handlers);
