import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { Messages } from '../constant/messages';
import { Urls } from '../constant/url';
import { ActionTypes as loginTypes } from '../actionTypes/loginActionTypes';
import { ActionTypes as types } from '../actionTypes/createAccountActionTypes';
import * as actions from '../actions/actions';
import API from '../api/api';

import { validateEmail } from '../validater/emailValidater';
import { validatePassword } from '../validater/passwordValidater';
import {
  errorCreateAccount,
  finishEmailValidation,
  finishPasswordValidation,
  successCreateAccount,
} from '../actions/createAccountActions';
import {
  errorLogin,
  errorLogout,
  successFetchLoginState,
  successLogin,
  successSendPasswordReset,
  errorSendPasswordReset,
  errorEmailIsNotUsed,
  validToChangePassword,
  inValidToChangePassword,
  finishNewPasswordValidation,
  finishNewPasswordConfirmValidation,
  successRegisterNewPassword,
  errorRegisterNewPassword,
} from '../actions/loginActions';
import { getSetting } from '../actions/settingActions';

function* runEmailValidationAsync(action) {
  const result = yield call(validateEmail, action.payload.email);

  const errorMessage = result ? [] : [Messages.InvalidMail];

  yield put(finishEmailValidation(errorMessage));
}

function* watchRunEmailValidationAsync() {
  yield takeEvery(types.VALIDATE_EMAIL, runEmailValidationAsync);
}

function* runPasswordValidationAsync(action) {
  const result = yield call(validatePassword, action.payload.password);

  const errorMessage = result ? [] : [Messages.InvalidPassWord];

  yield put(finishPasswordValidation(errorMessage));
}

function* watchRunPasswordValidationAsync() {
  yield takeEvery(types.VALIDATE_PASSWORD, runPasswordValidationAsync);
}

function* tryCreateAccountAsync(action) {
  const result = {
    errorEmailMessage: [],
    errorPasswordMessage: [],
    errorPasswordConfirmMessage: [],
    errorServerMessage: [],
    user: {},
  };

  const isEmailValid = yield call(validateEmail, action.payload.email);
  if (!isEmailValid) { result.errorEmailMessage.push(Messages.InvalidMail); }

  const isPasswordValid = yield call(validatePassword, action.payload.password);
  if (!isPasswordValid) { result.errorPasswordMessage.push(Messages.InvalidPassWord); }

  if (action.payload.password !== action.payload.passwordConfirm) { result.errorPasswordMessage.push(Messages.NotMatchPasswordConfirm); }

  if (result.errorEmailMessage.length > 0 || result.errorPasswordMessage.length > 0) {
    yield put(errorCreateAccount(result));
  } else {
    const createResult = yield call(API.createAccount, {
      email: action.payload.email,
      password: action.payload.password,
    });

    console.log({createResult});
    if (createResult.status === 200) {
      yield put(successCreateAccount(action.payload.email));
      yield put(push(Urls.SuccessCreateAccount.path));
    } else if (createResult.status === 422) {
      result.errorEmailMessage.push(Messages.UsedMail);
      yield put(errorCreateAccount(result));
    } else if (createResult.status === 403) {
      result.errorEmailMessage.push(Messages.ServerError + '03');
      yield put(errorCreateAccount(result));
    } else {
      result.errorServerMessage.push(Messages.ServerError);
      yield put(errorCreateAccount(result));
    }
  }
}

function* watchTryCreateAccountAsync() {
  yield takeEvery(types.CREATE_ACCOUNT, tryCreateAccountAsync);
}

function* confirmEmailVerificationAsync(action) {
  const response = yield call(API.confirmEmailVerification, { token: action.payload.token });

  if (response.status === 200) {
    localStorage.setItem('TOKEN', response.user.token);
    yield put(successLogin(response));
    yield put(push(Urls.Schedule.path));
  } else {
    console.log(response.status);
    yield put(errorLogin(response));
    yield put(push(Urls.Login.path));
  }
}

function* watchConfirmEmailVerificationAsync() {
  yield takeEvery(types.CONFIRM_EMAIL_VERIFICATION, confirmEmailVerificationAsync);
}

function* fetchLoginStateAsync() {
  const token = localStorage.getItem('TOKEN');

  if (!token) {
    yield put(successFetchLoginState({ status: 401 }));
  } else {
    const response = yield call(API.fetchLoginState, { token });
    yield put(successFetchLoginState(response));
    if (response.status === 200) {
      yield put(getSetting());
    }
  }
}

function* watchFetchLoginStateAsync() {
  yield takeEvery(loginTypes.FETCH_LOGIN_STATE, fetchLoginStateAsync);
}

function* logout() {
  const token = localStorage.getItem('TOKEN');
  const response = yield call(API.logout, { token });
  if (response.status === 200) {
    localStorage.removeItem('TOKEN');
    yield put(actions.clearState());
    yield put(push(Urls.Login.path));
  } else { yield put(errorLogout()); }
}

function* watchLogout() {
  yield takeEvery(loginTypes.LOGOUT, logout);
}

function* tryLoginAsync(action) {
  const response = yield call(API.login, { email: action.payload.email, password: action.payload.password });

  if (response.status === 200) {
    localStorage.setItem('TOKEN', response.user.token);
    yield put(successLogin(response));
  } else {
    console.log(response.status);
    yield put(errorLogin(response));
  }
}

function* watchTryLoginAsync() {
  yield takeEvery(loginTypes.LOGIN, tryLoginAsync);
}

function* sendPasswordResetAsync(action) {
  const response = yield call(API.sendPasswordReset, { email: action.payload.email });

  if (response.status === 200) {
    yield put(push(Urls.SentResetPasswordEmail.path));
    yield put(successSendPasswordReset());
  } else if (response.status === 422) {
    yield put(errorEmailIsNotUsed());
  } else {
    console.log(response.status);
    yield put(errorSendPasswordReset(response));
  }
}

function* watchSendPasswordResetAsync() {
  yield takeEvery(loginTypes.SEND_PASSWORD_RESET, sendPasswordResetAsync);
}

function* checkValidityToChangePassword(action) {
  const response = yield call(API.checkValidityToChangePassword, { id: action.payload.id });

  if (response.status === 200) {
    yield put(validToChangePassword());
  } else {
    yield put(inValidToChangePassword());
    yield put(push(Urls.ForgetPassword.path));
  }
}

function* watchCheckValidityToChangePasswordAsync() {
  yield takeEvery(loginTypes.CHECK_VALIDITY_TO_CHANGE_PASSWORD, checkValidityToChangePassword);
}

function* runNewPasswordValidationAsync(action) {
  const result = yield call(validatePassword, action.payload.password);

  const errorMessage = result ? [] : [Messages.InvalidPassWord];

  yield put(finishNewPasswordValidation(errorMessage));
}

function* watchRunNewPasswordValidationAsync() {
  yield takeEvery(loginTypes.VALIDATE_NEW_PASSWORD, runNewPasswordValidationAsync);
}

function* runNewPasswordConfirmValidationAsync(action) {
  const result = yield call(validatePassword, action.payload.password);

  const errorMessage = result ? [] : [Messages.InvalidPassWord];

  yield put(finishNewPasswordConfirmValidation(errorMessage));
}

function* watchRunNewPasswordConfirmValidationAsync() {
  yield takeEvery(loginTypes.VALIDATE_NEW_PASSWORD_CONFIRM, runNewPasswordConfirmValidationAsync);
}

function* registerNewPassword(action) {
  const result = {
    errorNewPasswordMessage: [],
    errorNewPasswordConfirmMessage: [],
    errorServerMessage: [],
  };

  const isPasswordValid = yield call(validatePassword, action.payload.password);
  if (!isPasswordValid) {
    result.errorNewPasswordMessage.push(Messages.InvalidPassWord);
  }

  if (action.payload.password !== action.payload.passwordConfirm) {
    result.errorNewPasswordMessage.push(Messages.NotMatchPasswordConfirm);
  }

  if (result.errorNewPasswordMessage.length > 0) {
    yield put(errorRegisterNewPassword(result));
  } else {
    const registerResult = yield call(API.registerNewPassword, {
      id: action.payload.id,
      password: action.payload.password,
      passwordConfirm: action.payload.passwordConfirm,
    });

    if (registerResult.status === 200) {
      yield put(successRegisterNewPassword());
      yield put(push(Urls.Login.path));
    } else {
      result.errorServerMessage.push(Messages.ServerError);
      yield put(errorRegisterNewPassword(result));
    }
  }
}

function* watchRegisterNewPasswordAsync() {
  yield takeEvery(loginTypes.REGISTER_NEW_PASSWORD, registerNewPassword);
}

export const loginSaga = [
  watchRunEmailValidationAsync(),
  watchRunPasswordValidationAsync(),
  watchTryCreateAccountAsync(),
  watchTryLoginAsync(),
  watchFetchLoginStateAsync(),
  watchLogout(),
  watchSendPasswordResetAsync(),
  watchCheckValidityToChangePasswordAsync(),
  watchRunNewPasswordValidationAsync(),
  watchRunNewPasswordConfirmValidationAsync(),
  watchRegisterNewPasswordAsync(),
  watchConfirmEmailVerificationAsync(),
];
