import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { TransmissionTypes, Use, WeightType } from "../constant/common";
import { Typography } from "@material-ui/core";
import { CommonDropDown } from "./CommonDropDown";

export const FilterFacilityDialog = ({
  filterState,
  changeFilterState,
  isFilterFacilityDialogOpen,
  closeFilterFacilityDialog,
}) => {
  return (
    <div>
      <Dialog
        open={isFilterFacilityDialogOpen}
        onClose={closeFilterFacilityDialog}
      >
        <DialogTitle>絞込み</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">用途</Typography>
              <CommonDropDown
                showLabel={true}
                fullWidth={true}
                defaultValue={-1}
                list={Use}
                labelText="用途"
                inputId={"use"}
                value={filterState.use}
                onChange={(e) =>
                  changeFilterState({ ...filterState, use: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">マニュアル/オートマ</Typography>
              <CommonDropDown
                showLabel={true}
                fullWidth={true}
                defaultValue={-1}
                list={TransmissionTypes}
                labelText="マニュアル/オートマ"
                inputId={"at_mt_type"}
                value={filterState.transmissionType}
                onChange={(e) =>
                  changeFilterState({
                    ...filterState,
                    transmissionType: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">普通車/軽自動車</Typography>
              <CommonDropDown
                showLabel={true}
                fullWidth={true}
                defaultValue={-1}
                list={WeightType}
                labelText="普通車/軽自動車"
                inputId={"weight_type"}
                value={filterState.weightType}
                onChange={(e) =>
                  changeFilterState({
                    ...filterState,
                    weightType: e.target.value,
                  })
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={closeFilterFacilityDialog}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
