export const ActionTypes = {
  CHANGE_FACILITY_NAME: 'CHANGE_FACILITY_NAME',
  CHANGE_FACILITY_NO: 'CHANGE_FACILITY_NO',
  CHANGE_FACILITY_COLOR: 'CHANGE_FACILITY_COLOR',
  CHANGE_FACILITY_CAR_TYPE: 'CHANGE_FACILITY_CAR_TYPE',
  CHANGE_EXPIRE_DATE: 'CHANGE_EXPIRE_DATE',
  CHANGE_FACILITY_MEMO: 'CHANGE_FACILITY_MEMO',

  NEW_FACILITY: 'NEW_FACILITY',
  EDIT_FACILITY: 'EDIT_FACILITY',
  CANCEL_EDIT_FACILITY: 'CANCEL_EDIT_FACILITY',

  ADD_FACILITY: 'ADD_FACILITY',
  SUCCESS_ADD_FACILITY: 'SUCCESS_ADD_FACILITY',
  ERROR_ADD_FACILITY: 'ERROR_ADD_FACILITY',

  SAVE_FACILITY: 'SAVE_FACILITY',
  SUCCESS_SAVE_FACILITY: 'SUCCESS_SAVE_FACILITY',
  ERROR_SAVE_FACILITY: 'ERROR_SAVE_FACILITY',

  SUCCESS_GET_FACILITIES: 'SUCCESS_GET_FACILITIES',
  ERROR_GET_FACILITIES: 'ERROR_GET_FACILITIES',

  CONFIRM_SCRAP_FACILITY: 'CONFIRM_SCRAP_FACILITY',
  CANCEL_SCRAP_FACILITY: 'CANCEL_SCRAP_FACILITY',

  CANCEL_DELETE_FACILITY: 'CANCEL_DELETE_FACILITY',
  CONFIRM_DELETE_FACILITY: 'CONFIRM_DELETE_FACILITY',
  DELETE_FACILITY: 'DELETE_FACILITY',
  SUCCESS_DELETE_FACILITY: 'SUCCESS_DELETE_FACILITY',
  ERROR_DELETE_FACILITY: 'ERROR_DELETE_FACILITY',

  CHANGE_FACILITY_WEIGHT_TYPE: 'CHANGE_FACILITY_WEIGHT_TYPE',
  CHANGE_FACILITY_FREIGHT_TYPE: 'CHANGE_FACILITY_FREIGHT_TYPE',
  CHANGE_REGISTRATION_NUMBER_PLACE: 'CHANGE_REGISTRATION_NUMBER_PLACE',
  CHANGE_REGISTRATION_NUMBER_MOBILE_TYPE: 'CHANGE_REGISTRATION_NUMBER_MOBILE_TYPE',
  CHANGE_REGISTRATION_NUMBER_MOBILE_USES: 'CHANGE_REGISTRATION_NUMBER_MOBILE_USES',
  CHANGE_REGISTRATION_NUMBER_NUMBER: 'CHANGE_REGISTRATION_NUMBER_NUMBER',
  CHANGE_FACILITY_DRIVE_SYSTEM: 'CHANGE_FACILITY_DRIVE_SYSTEM',
  CHANGE_FACILITY_SHIFT_LEVER: 'CHANGE_FACILITY_SHIFT_LEVER',
  CHANGE_FACILITY_PARKING_BRAKE: 'CHANGE_FACILITY_PARKING_BRAKE',
  CHANGE_FACILITY_KEY_TYPE: 'CHANGE_FACILITY_KEY_TYPE',
  CHANGE_FACILITY_WITH_AIR_CONDITIONER: 'CHANGE_FACILITY_WITH_AIR_CONDITIONER',
  CHANGE_FIRST_REGISTRATION_DATE: 'CHANGE_FIRST_REGISTRATION_DATE',
  CHANGE_FACILITY_USE: 'CHANGE_FACILITY_USE',
};
