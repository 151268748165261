import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { Urls } from '../constant/url';
import { ActionTypes as types } from '../actionTypes/guestSupportActionTypes';
import API from '../api/guestSupportApi';
import {
  successSendRequest,
  errorSendRequest,
} from '../actions/guestSupportActions';
import { Messages as Message } from '../constant/messages';

function* sendGuestSupportRequestAsync(action) {
  const data = action.payload;
  const response = yield call(API.sendGuestSupportRequest, {
    name: data.name,
    email: data.email,
    content: data.content,
  });

  if (response.status === 200) {
    yield put(successSendRequest());
    yield put(push(Urls.SuccessRequestGuestSupport.path));
  } else { yield put(errorSendRequest([Message.ServerError])); }
}

function* watchSendGuestSupportRequestAsync() {
  yield takeEvery(types.SEND_GUEST_SUPPORT_REQUEST, sendGuestSupportRequestAsync);
}

export const guestSupportSaga = [
  watchSendGuestSupportRequestAsync(),
];
