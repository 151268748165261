import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

export const CommonDropDown = ({
  value, list, defaultValue = '0', label, inputId,
  onChange,
  fullWidth = false,
  disableUnderline = false,
  showLabel = true,
  required = false,
}) => (
  <FormControl fullWidth={fullWidth}>
    {showLabel
      ? <InputLabel htmlFor={inputId} required={required}>{label}</InputLabel>
      : false}

    <Select
      fullWidth={fullWidth}
      disableUnderline={disableUnderline}
      value={value}
      onChange={(e) => onChange(e)}
      inputProps={{ key: 'type', id: inputId }}
    >
      <MenuItem value={defaultValue} />
      {Array.isArray(list)
        ? list.map((l, i) => <MenuItem key={i} value={l.value}>{l.key}</MenuItem>)
        : Object.keys(list).map((k, i) => <MenuItem key={i} value={list[k].value}>{list[k].key}</MenuItem>)}
    </Select>
  </FormControl>
);
