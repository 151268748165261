import request from 'superagent';
import { Environment } from '../config/config';

function getRentalReasonMasters(payload) {
  return new Promise((resolve, reject) => {
    request
      .get(`${Environment.ApiUrl}/users/${payload.userId}/rental_reason_masters`)
      .set('Authorization', `Bearer ${payload.token}`)
      .end((err, res) => {
        if (err === null) {
          resolve({
            status: res.status,
            rentalReasonMasters: res.body.rental_reason_masters.map((m) => ({
              rentalReasonMasterId: m.id,
              name: m.name,
              code: m.code,
              sortNo: m.sort_no,
              colorCode: m.color_code,
              isAddedMaster: false,
              isDeleted: m.is_deleted,
            })),
          });
        } else { resolve({ status: res.status, setting: {} }); }
      });
  });
}

function saveRentalReasonMasters(payload) {
  return new Promise((resolve, reject) => {
    request
      .put(`${Environment.ApiUrl}/users/${payload.userId}/save_rental_reason_masters`)
      .set('Authorization', `Bearer ${payload.token}`)
      .send({
        rental_reason_masters: payload.rentalReasonMasters.map((m) => ({
          id: m.rentalReasonMasterId,
          name: m.name,
          code: m.code,
          sort_no: m.sortNo,
          color_code: m.colorCode,
          is_added_master: m.isAddedMaster,
          is_deleted: m.isDeleted,
        })),
      })
      .end((err, res) => {
        if (err === null) {
          resolve({ status: res.status });
        } else {
          resolve({ status: res.status });
        }
      });
  });
}

export default {
  getRentalReasonMasters,
  saveRentalReasonMasters,
};
