import React from 'react';
import { connect } from 'react-redux';
import { SupportForMember } from '../../../components/pages/member/SupportForMember';
import {
  changeContent,
  changeName,
  sendRequest,
} from '../../../actions/memberSupportActions';

export default connect(
  (state) => ({
    content: state.memberSupportReducer.content,
    name: state.memberSupportReducer.name,
    isLoading: state.memberSupportReducer.isLoading,
    messages: state.memberSupportReducer.messages,
  }),
  {
    changeContent,
    changeName,
    sendRequest,
  },
)(SupportForMember);
