export const ActionTypes = {
  CHANGE_DRAG_SNAP_VALUE: 'CHANGE_DRAG_SNAP_VALUE',
  CHANGE_DRAG_SNAP_UNIT: 'CHANGE_DRAG_SNAP_UNIT',
  SAVE_SETTING: 'SAVE_SETTING',
  SUCCESS_SAVE_SETTING: 'SUCCESS_SAVE_SETTING',
  ERROR_SAVE_SETTING: 'ERROR_SAVE_SETTING',

  GET_SETTING: 'GET_SETTING',
  SUCCESS_GET_SETTING: 'SUCCESS_GET_SETTING',
  ERROR_GET_SETTING: 'ERROR_GET_SETTING',
  CHANGE_HEADER_FORMAT: 'CHANGE_HEADER_FORMAT',

  CHANGE_SHOW_STAFF_IN_CARD: 'CHANGE_SHOW_STAFF_IN_CARD',
  CHANGE_SHOW_CUSTOMER_IN_CARD: 'CHANGE_SHOW_CUSTOMER_IN_CARD',
  CHANGE_SHOW_CUSTOMERS_CAR_IN_CARD: 'CHANGE_SHOW_CUSTOMERS_CAR_IN_CARD',

  CHANGE_LABEL_OF_STAFF_IN_CARD: 'CHANGE_LABEL_OF_STAFF_IN_CARD',
  CHANGE_LABEL_OF_CUSTOMER_IN_CARD: 'CHANGE_LABEL_OF_CUSTOMER_IN_CARD',
  CHANGE_LABEL_OF_CUSTOMERS_CAR_IN_CARD: 'CHANGE_LABEL_OF_CUSTOMERS_CAR_IN_CARD',
  CHANGE_DEFAULT_CALENDER_SPAN: 'CHANGE_DEFAULT_CALENDER_SPAN',
  CHANGE_SHOW_CAR_HINT_IN_CALENDER: 'CHANGE_SHOW_CAR_HINT_IN_CALENDER',
  CHANGE_SHOW_SCHEDULE_HINT_IN_CARD: 'CHANGE_SHOW_SCHEDULE_HINT_IN_CARD',
  CHANGE_BAN_DOUBLE_BOOK: 'CHANGE_BAN_DOUBLE_BOOK',
  CHANGE_IS_CARD_MOVABLE_SP: 'CHANGE_IS_CARD_MOVABLE_SP',
  CHANGE_IS_CARD_MOVABLE_PC: 'CHANGE_IS_CARD_MOVABLE_PC',
  CHANGE_IS_CARD_RESIZABLE_SP: 'CHANGE_IS_CARD_RESIZABLE_SP',
  CHANGE_IS_CARD_RESIZABLE_PC: 'CHANGE_IS_CARD_RESIZABLE_PC',
  CHANGE_CHECK_WARNING_AS_INITIAL_VALUE: 'CHANGE_CHECK_WARNING_AS_INITIAL_VALUE',

  CHANGE_RENTAL_REASON_NAME: 'CHANGE_RENTAL_REASON_NAME',
  MOVE_RENTAL_REASON_MASTER: 'MOVE_RENTAL_REASON_MASTER',
  CHANGE_RENTAL_REASON_COLOR: 'CHANGE_RENTAL_REASON_COLOR',
  OPEN_COLOR_PALLET: 'OPEN_COLOR_PALLET',
  ADD_NEW_RENTAL_REASON_MASTER: 'ADD_NEW_RENTAL_REASON_MASTER',
  DELETE_RENTAL_REASON_MASTER: 'DELETE_RENTAL_REASON_MASTER',
};
