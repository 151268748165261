export const ActionTypes = {

  CHANGE_CUSTOMER_NAME: 'CHANGE_CUSTOMER_NAME',
  CHANGE_CUSTOMER_DRIVER_TYPE: 'CHANGE_CUSTOMER_DRIVER_TYPE',
  CHANGE_CUSTOMER_MEMO: 'CHANGE_CUSTOMER_MEMO',

  NEW_CUSTOMER: 'NEW_CUSTOMER',
  EDIT_CUSTOMER: 'EDIT_CUSTOMER',
  CANCEL_EDIT_CUSTOMER: 'CANCEL_EDIT_CUSTOMER',

  ADD_CUSTOMER: 'ADD_CUSTOMER',
  SUCCESS_ADD_CUSTOMER: 'SUCCESS_ADD_CUSTOMER',
  ERROR_ADD_CUSTOMER: 'ERROR_ADD_CUSTOMER',

  SAVE_CUSTOMER: 'SAVE_CUSTOMER',
  SUCCESS_SAVE_CUSTOMER: 'SUCCESS_SAVE_CUSTOMER',
  ERROR_SAVE_CUSTOMER: 'ERROR_SAVE_CUSTOMER',

  SUCCESS_GET_CUSTOMERS: 'SUCCESS_GET_CUSTOMERS',
  ERROR_GET_CUSTOMERS: 'ERROR_GET_CUSTOMERS',

  CANCEL_DELETE_CUSTOMER: 'CANCEL_DELETE_CUSTOMER',
  CONFIRM_DELETE_CUSTOMER: 'CONFIRM_DELETE_CUSTOMER',
  DELETE_CUSTOMER: 'DELETE_CUSTOMER',
  SUCCESS_DELETE_CUSTOMER: 'SUCCESS_DELETE_CUSTOMER',
  ERROR_DELETE_CUSTOMER: 'ERROR_DELETE_CUSTOMER',

};
