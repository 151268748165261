import request from 'superagent';
import moment from 'moment';
import { Environment } from '../config/config';

/* eslint-disable handle-callback-err */

function createAccount(payload) {
  return new Promise((resolve, reject) => {
    request
      .post(`${Environment.ApiUrl}/users`)
      .type('form')
      .set('Content-Type', 'application/json')
      .send({ email: payload.email, password: payload.password })
      .end((err, res) => {
        resolve(res.body);
      });
  });
}

function confirmEmailVerification(payload) {
  return new Promise((resolve, reject) => {
    request
      .post(`${Environment.ApiUrl}/confirm_email_verification`)
      .type('form')
      .set('Content-Type', 'application/json')
      .send({ token: payload.token })
      .end((err, res) => {
        resolve(res.body);
      });
  });
}

function login(payload) {
  return new Promise((resolve, reject) => {
    request
      .post(`${Environment.ApiUrl}/login`)
      .type('form')
      .set('Content-Type', 'application/json')
      .send({ email: payload.email, password: payload.password })
      .end((err, res) => {
        resolve(res.body);
      });
  });
}

function logout(payload) {
  return new Promise((resolve, reject) => {
    request
      .delete(`${Environment.ApiUrl}/logout`)
      .set('Authorization', `Bearer ${payload.token}`)
      .end((err, res) => {
        resolve(res);
      });
  });
}

function fetchLoginState(payload) {
  return new Promise((resolve, reject) => {
    request
      .get(`${Environment.ApiUrl}/authenticate`)
      .set('Authorization', `Bearer ${payload.token}`)
      .end((err, res) => {
        if (err === null) {
          resolve({ status: res.status, user: res.body.user });
        } else {
          resolve({ status: res.status, user: res.body.user });
        }
      });
  });
}

function sendPasswordReset(payload) {
  return new Promise((resolve, reject) => {
    request
      .post(`${Environment.ApiUrl}/send_password_reset`)
      .type('form')
      .set('Content-Type', 'application/json')
      .send({ email: payload.email })
      .end((err, res) => {
        if (err === null) {
          resolve({ status: res.status });
        } else {
          resolve({ status: res.status });
        }
      });
  });
}

function checkValidityToChangePassword(payload) {
  return new Promise((resolve, reject) => {
    request
      .get(`${Environment.ApiUrl}/check_validity_to_change_password/${payload.id}`)
      .end((err, res) => {
        if (err === null) {
          resolve({ status: res.status });
        } else {
          resolve({ status: res.status });
        }
      });
  });
}

function registerNewPassword(payload) {
  return new Promise((resolve, reject) => {
    request
      .post(`${Environment.ApiUrl}/register_new_password`)
      .type('form')
      .set('Content-Type', 'application/json')
      .send({ id: payload.id, password: payload.password })
      .end((err, res) => {
        if (err === null) {
          resolve({ status: res.status });
        } else {
          resolve({ status: res.status });
        }
      });
  });
}

function getCustomers(payload) {
  return new Promise((resolve, reject) => {
    request
      .get(`${Environment.ApiUrl}/users/${payload.userId}/customers`)
      .set('Authorization', `Bearer ${payload.token}`)
      .end((err, res) => {
        if (err === null) {
          resolve({
            status: res.status,
            customers: res.body.customers.map((f) => ({
              customerId: f.id,
              name: f.name,
              driverType: f.driver_type,
              memo: f.memo,
              userId: f.user_id,
              isDeleted: f.is_deleted,
            })),
          });
        } else {
          resolve({
            status: res.status,
            customers: {},
          });
        }
      });
  });
}

function addCustomer(payload) {
  return new Promise((resolve, reject) => {
    request
      .post(`${Environment.ApiUrl}/users/${payload.userId}/customers`)
      .type('form')
      .set('Authorization', `Bearer ${payload.token}`)
      .send({
        name: payload.name,
        driver_type: payload.driverType,
        memo: payload.memo,
          is_deleted: false,
      })
      .end((err, res) => {
        if (err === null) { resolve({ status: res.status, customerId: res.body.id }); } else { resolve({ status: res.status, customerId: '' }); }
      });
  });
}

function saveCustomer(payload) {
  return new Promise((resolve, reject) => {
    request
      .put(
        `${Environment.ApiUrl}/users/${payload.userId}/customers/${payload.customerId}`
      )
      .set('Authorization', `Bearer ${payload.token}`)
      .send({
        name: payload.name,
        driver_type: payload.driverType,
        memo: payload.memo,
          is_deleted: false,
      })
      .end((err, res) => {
        if (err === null) {
          resolve({ status: res.status, customerId: res.body.id });
        } else {
          resolve({ status: res.status, customerId: '' });
        }
      });
  });
}

function deleteCustomer(payload) {
  return new Promise((resolve, reject) => {
    request
      .delete(
        `${Environment.ApiUrl}/users/${payload.userId}/customers/${payload.customerId}`
      )
      .set('Authorization', `Bearer ${payload.token}`)
      .end((err, res) => {
        resolve(res.body);
      });
  });
}

function getStaffs(payload) {
  return new Promise((resolve, reject) => {
    request
      .get(`${Environment.ApiUrl}/users/${payload.userId}/staffs`)
      .set('Authorization', `Bearer ${payload.token}`)
      .end((err, res) => {
        if (err === null) {
          resolve({
            status: res.status,
            staffs: res.body.staffs.map((f) => ({
              staffId: f.id,
              name: f.name,
              memo: f.memo,
              userId: f.user_id,
              retire: f.retire,
              retireDate: f.retire_date === null ? null : moment(f.retire_date),
                isDeleted: f.is_deleted,
            })),
          });
        } else {
          resolve({ status: res.status, staffs: [] });
        }
      });
  });
}

function addStaff(payload) {
  return new Promise((resolve, reject) => {
    request
      .post(`${Environment.ApiUrl}/users/${payload.userId}/staffs`)
      .type('form')
      .set('Authorization', `Bearer ${payload.token}`)
      .send({
        name: payload.staff.name,
        memo: payload.staff.memo,
        retire: payload.staff.retire,
        retire_date: payload.staff.retireDate,
          is_deleted: false,
      })
      .end((err, res) => {
        if (err === null) {
          resolve({ status: res.status, staffId: res.body.id });
        } else {
          resolve({ status: res.status, staffId: '' });
        }
      });
  });
}

function saveStaff(payload) {
  return new Promise((resolve, reject) => {
    request
      .put(
        `${Environment.ApiUrl}/users/${payload.userId}/staffs/${payload.staff.staffId}`
      )
      .set('Authorization', `Bearer ${payload.token}`)
      .send({
        name: payload.staff.name,
        memo: payload.staff.memo,
        retire: payload.staff.retire,
        retire_date:
          payload.staff.retireDate === null
            ? null
            : payload.staff.retireDate.format(),
          is_deleted: false,
      })
      .end((err, res) => {
        if (err === null) {
          resolve({ status: res.status, staffId: res.body.id });
        } else {
          resolve({ status: res.status, staffId: '' });
        }
      });
  });
}

function deleteStaff(payload) {
  return new Promise((resolve, reject) => {
    request
      .delete(
        `${Environment.ApiUrl}/users/${payload.userId}/staffs/${payload.staffId}`
      )
      .set('Authorization', `Bearer ${payload.token}`)
      .end((err, res) => {
        resolve(res.body);
      });
  });
}

function getFacilities(payload) {
  return new Promise((resolve, reject) => {
    request
      .get(`${Environment.ApiUrl}/users/${payload.userId}/facilities`)
      .set('Authorization', `Bearer ${payload.token}`)
      .end((err, res) => {
        if (err === null) {
          resolve({
            status: res.status,
            facilities: res.body.facilities.map((f) => ({
              facilityId: f.id,
              no: f.no,
              name: f.name,
              color: f.color,
              carType: f.car_type,
              expireDate: moment(f.expire_date),
              firstRegistrationDate:
                f.first_registration_date === null
                  ? null
                  : moment(f.first_registration_date),
              use: f.use,
              memo: f.memo,
              userId: f.user_id,
              weightType: f.weight_type,
              freightType: f.freight_type,
              registrationNumberPlace: f.registration_number_place,
              registrationNumberMobileType: f.registration_number_mobile_type,
              registrationNumberMobileUses: f.registration_number_mobile_uses,
              registrationNumberNumber: f.registration_number_number,
              driveSystem: f.drive_system,
              shiftLever: f.shift_lever,
              parkingBrake: f.parking_brake,
              keyType: f.key_type,
              withAirConditioner: f.with_air_conditioner,
              isScrapped: f.is_scrapped,
              scrappedDate:
                f.scrapped_date === null ? null : moment(f.scrapped_date),
                isDeleted: f.is_deleted,
            })),
          });
        } else {
          resolve({ status: res.status, facilities: [] });
        }
      });
  });
}

function addFacility(payload) {
  return new Promise((resolve, reject) => {
    request
      .post(`${Environment.ApiUrl}/users/${payload.userId}/facilities`)
      .type('form')
      .set('Authorization', `Bearer ${payload.token}`)
      .send({
        name: payload.facility.name,
        no: payload.facility.no,
        color: payload.facility.color,
        car_type: payload.facility.carType,
        expire_date: payload.facility.expireDate.format(),
        first_registration_date:
          payload.facility.firstRegistrationDate === null
            ? null
            : payload.facility.firstRegistrationDate.format(),
        use: payload.facility.use,
        memo: payload.facility.memo,
        weight_type: payload.facility.weightType,
        freight_type: payload.facility.freightType,
        registration_number_place: payload.facility.registrationNumberPlace,
        registration_number_mobile_type:
          payload.facility.registrationNumberMobileType,
        registration_number_mobile_uses:
          payload.facility.registrationNumberMobileUses,
        registration_number_number: payload.facility.registrationNumberNumber,
        drive_system: payload.facility.driveSystem,
        shift_lever: payload.facility.shiftLever,
        parking_brake: payload.facility.parkingBrake,
        key_type: payload.facility.keyType,
        with_air_conditioner: payload.facility.withAirConditioner,
        is_scrapped: payload.facility.isScrapped,
          is_deleted: false,
        scrapped_date:
          payload.facility.scrappedDate === null
            ? null
            : payload.facility.scrappedDate.format(),
      })
      .end((err, res) => {
        if (err === null) {
          resolve({ status: res.status, facilityId: res.body.id });
        } else {
          resolve({ status: res.status, facilityId: '' });
        }
      });
  });
}

function saveFacility(payload) {
  return new Promise((resolve, reject) => {
    request
      .put(
        `${Environment.ApiUrl}/users/${payload.userId}/facilities/${payload.facility.facilityId}`
      )
      .set('Authorization', `Bearer ${payload.token}`)
      .send({
        name: payload.facility.name,
        no: payload.facility.no,
        color: payload.facility.color,
        car_type: payload.facility.carType,
        expire_date: payload.facility.expireDate.format(),
        first_registration_date:
          payload.facility.firstRegistrationDate === null
            ? null
            : payload.facility.firstRegistrationDate.format(),
        use: payload.facility.use,
        memo: payload.facility.memo,
        weight_type: payload.facility.weightType,
        freight_type: payload.facility.freightType,
        registration_number_place: payload.facility.registrationNumberPlace,
        registration_number_mobile_type:
          payload.facility.registrationNumberMobileType,
        registration_number_mobile_uses:
          payload.facility.registrationNumberMobileUses,
        registration_number_number: payload.facility.registrationNumberNumber,
        drive_system: payload.facility.driveSystem,
        shift_lever: payload.facility.shiftLever,
        parking_brake: payload.facility.parkingBrake,
        key_type: payload.facility.keyType,
        with_air_conditioner: payload.facility.withAirConditioner,
        is_scrapped: payload.facility.isScrapped,
        scrapped_date:
          payload.facility.scrappedDate === null
            ? null
            : payload.facility.scrappedDate.format(),
          is_deleted: false,
      })
      .end((err, res) => {
        if (err === null) {
          resolve({ status: res.status, facilityId: res.body.id });
        } else {
          resolve({ status: res.status, facilityId: '' });
        }
      });
  });
}

function deleteFacility(payload) {
  return new Promise((resolve, reject) => {
    request
      .delete(
        `${Environment.ApiUrl}/users/${payload.userId}/facilities/${payload.facilityId}`
      )
      .set('Authorization', `Bearer ${payload.token}`)
      .end((err, res) => {
        resolve(res.body);
      });
  });
}

function getSchedules(payload) {
  return new Promise((resolve, reject) => {
    request
      .get(`${Environment.ApiUrl}/users/${payload.userId}/schedules/${payload.start.format()}/${payload.end.format()}`)
      .set('Authorization', `Bearer ${payload.token}`)
      .end((err, res) => {
        if (err === null) {
          resolve({
            status: res.status,
            schedules: res.body.schedules.map((s) => ({
              scheduleId: s.id,
              customerId: s.customer_id,
              freeFirstName: s.free_first_name,
              freeLastName: s.free_last_name,
              customersCar: s.customers_car,
              facilityId: s.facility_id,
              staffId: s.staff_id,
              start: moment(s.start),
              end: moment(s.end),
              memo: s.memo,
              warning: s.warning,
              rentalReason: s.rental_reason,
              userId: s.user_id,
              requestedCarType: s.requested_car_type,
              requestedWeightType: s.requested_weight_type,
              requestedFreightType: s.requested_freight_type,
              requestedDriveSystem: s.requested_drive_system,
              requestedShiftLever: s.requested_shift_lever,
              requestedParkingBrake: s.requested_parking_brake,
              requestedKeyType: s.requested_key_type,
              requestedWithAirConditioner: s.requested_with_air_conditioner,
                isDeleted: s.is_deleted,
            })),
          });
        } else {
          resolve({ status: res.status, schedules: [] });
        }
      });
  });
}

function addSchedule(payload) {
  return new Promise((resolve, reject) => {
    request
      .post(`${Environment.ApiUrl}/users/${payload.schedule.userId}/schedules`)
      .type('form')
      .set('Authorization', `Bearer ${payload.token}`)
      .send({
        customer_id: payload.schedule.customerId,
        free_first_name: payload.schedule.freeFirstName,
        free_last_name: payload.schedule.freeLastName,
        customers_car: payload.schedule.customersCar,
        staff_id: payload.schedule.staffId,
        facility_id: payload.schedule.facilityId,
        start: payload.schedule.start.format(),
        end: payload.schedule.end.format(),
        memo: payload.schedule.memo,
        rental_reason: payload.schedule.rentalReason,
        warning: payload.schedule.warning,
        requested_car_type: payload.schedule.requestedCarType,
        requested_weight_type: payload.schedule.requestedWeightType,
        requested_freight_type: payload.schedule.requestedFreightType,
        requested_drive_system: payload.schedule.requestedDriveSystem,
        requested_shift_lever: payload.schedule.requestedShiftLever,
        requested_parking_brake: payload.schedule.requestedParkingBrake,
        requested_key_type: payload.schedule.requestedKeyType,
        requested_with_air_conditioner:
          payload.schedule.requestedWithAirConditioner,
          is_deleted: false,
      })
      .end((err, res) => {
        if (err === null) {
          resolve({ status: res.status, scheduleId: res.body.id });
        } else {
          resolve({ status: res.status, scheduleId: '' });
        }
      });
  });
}

function saveSchedule(payload) {
  return new Promise((resolve, reject) => {
    request
      .put(
        `${Environment.ApiUrl}/users/${payload.schedule.userId}/schedules/${payload.schedule.scheduleId}`
      )
      .type('form')
      .set('Authorization', `Bearer ${payload.token}`)
      .send({
        customer_id: payload.schedule.customerId,
        free_first_name: payload.schedule.freeFirstName,
        free_last_name: payload.schedule.freeLastName,
        customers_car: payload.schedule.customersCar,
        staff_id: payload.schedule.staffId,
        facility_id: payload.schedule.facilityId,
        start: payload.schedule.start.format(),
        end: payload.schedule.end.format(),
        memo: payload.schedule.memo,
        rental_reason: payload.schedule.rentalReason,
        warning: payload.schedule.warning,
        requested_car_type: payload.schedule.requestedCarType,
        requested_weight_type: payload.schedule.requestedWeightType,
        requested_freight_type: payload.schedule.requestedFreightType,
        requested_drive_system: payload.schedule.requestedDriveSystem,
        requested_shift_lever: payload.schedule.requestedShiftLever,
        requested_parking_brake: payload.schedule.requestedParkingBrake,
        requested_key_type: payload.schedule.requestedKeyType,
        requested_with_air_conditioner:
          payload.schedule.requestedWithAirConditioner,
            is_deleted: false,
      })
      .end((err, res) => {
        resolve(res.body);
      });
  });
}

function deleteSchedule(payload) {
  return new Promise((resolve, reject) => {
    request
      .delete(
        `${Environment.ApiUrl}/users/${payload.userId}/schedules/${payload.scheduleId}`
      )
      .set('Authorization', `Bearer ${payload.token}`)
      .end((err, res) => {
        resolve(res.body);
      });
  });
}

/* eslint-disable handle-callback-err */

export default {
  createAccount,
  confirmEmailVerification,
  login,
  fetchLoginState,
  logout,
  sendPasswordReset,
  checkValidityToChangePassword,
  registerNewPassword,
  getCustomers,
  addCustomer,
  deleteCustomer,
  saveCustomer,
  getStaffs,
  addStaff,
  deleteStaff,
  saveStaff,
  getFacilities,
  addFacility,
  deleteFacility,
  saveFacility,
  getSchedules,
  addSchedule,
  saveSchedule,
  deleteSchedule,
};
