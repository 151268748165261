import React from 'react';
import { connect } from 'react-redux';
import Schedule from '../../../components/pages/member/Schedule';
import {
  addSchedule,
  cancelDeleteSchedule,
  cancelEditSchedule,
  changeEnd,
  closeFilterFacilityDialog,
  openFilterFacilityDialog,
  changeFilterState,
  closeSortFacilityDialog,
  openSortFacilityDialog,
  changeSortState,
  changeMemo,
  changeWarning,
  changeSelectedCustomer,
  changeSelectedFacility,
  changeFreeFirstName,
  changeFreeLastName,
  changeCustomersCar,
  changeSelectedRentalReason,
  changeSelectedStaff,
  changeStart,
  changeRequestedCarType,
  changeRequestedWeightType,
  changeRequestedFreightType,
  changeRequestedDriveSystem,
  changeRequestedShiftLever,
  changeRequestedParkingBrake,
  changeRequestedKeyType,
  changeRequestedWithAirConditioner,
  confirmDeleteSchedule,
  deleteSchedule,
  editSchedule,
  newSchedule,
  saveSchedule,
  toggleCarList,
  openScheduleCardContext,
  closeScheduleCardContext,
  openCanvasContext,
  closeCanvasContext,
  copySchedule,
  changeDefaultStartEnd,
  hover,
  hoverEnd,
  hoverOnGroups,
  hoverOnGroupsEnd,
  scrollOnDrag, updateCanvasTime, getSchedules
} from '../../../actions/scheduleActions';

export default connect(
  state => ({
    userId: state.userReducer.user.id,
    facilities: state.facilityReducer.facilities,
    customers: state.customerReducer.customers,
    staffs: state.staffReducer.staffs,
    schedules: state.scheduleReducer.schedules,
    setting: state.settingReducer.setting,
    rentalReasonMasters: state.settingReducer.rentalReasonMasters,
    messages: state.scheduleReducer.messages,
    deleteConfirmId: state.scheduleReducer.deleteConfirmId,
    editedSchedule: state.scheduleReducer.editedSchedule,
    isLoading: state.scheduleReducer.isLoading,

    previousCanvasTimeStart: state.scheduleReducer.previousCanvasTimeStart,
    previousCanvasTimeEnd: state.scheduleReducer.previousCanvasTimeEnd,
    defaultTimeStart: state.scheduleReducer.defaultTimeStart,
    defaultTimeEnd: state.scheduleReducer.defaultTimeEnd,

    isHover: state.scheduleReducer.isHover,
    isHoveringOnGroups: state.scheduleReducer.isHoveringOnGroups,

    isFilterFacilityDialogOpen: state.scheduleReducer.isFilterFacilityDialogOpen,
    filterState: state.scheduleReducer.filterState,
    isSortFacilityDialogOpen: state.scheduleReducer.isSortFacilityDialogOpen,
    sortState: state.scheduleReducer.sortState,

    showCarList: state.scheduleReducer.showCarList,
    itemContextObject: state.scheduleReducer.itemContextObject,
    canvasContextObject: state.scheduleReducer.canvasContextObject,
    copiedSchedule: state.scheduleReducer.copiedSchedule
  }),
  (dispatch) => ({
    getSchedules: (start, end) =>
      dispatch(getSchedules(start, end)),
    updateCanvasTime: (start, end) =>
      dispatch(updateCanvasTime(start, end)),

    changeSelectedCustomer: customerId => dispatch(changeSelectedCustomer(customerId)),
    changeSelectedFacility: facilityId => dispatch(changeSelectedFacility(facilityId)),
    changeFreeFirstName: name => dispatch(changeFreeFirstName(name)),
    changeFreeLastName: name => dispatch(changeFreeLastName(name)),
    changeCustomersCar: car => dispatch(changeCustomersCar(car)),
    changeSelectedStaff: staffId => dispatch(changeSelectedStaff(staffId)),
    changeSelectedRentalReason: reason => dispatch(changeSelectedRentalReason(reason)),
    changeMemo: m => dispatch(changeMemo(m)),
    changeWarning: () => dispatch(changeWarning()),
    changeStart: start => dispatch(changeStart(start)),
    changeEnd: end => dispatch(changeEnd(end)),

    changeRequestedCarType: value => dispatch(changeRequestedCarType(value)),
    changeRequestedWeightType: value => dispatch(changeRequestedWeightType(value)),
    changeRequestedFreightType: value => dispatch(changeRequestedFreightType(value)),
    changeRequestedDriveSystem: value => dispatch(changeRequestedDriveSystem(value)),
    changeRequestedShiftLever: value => dispatch(changeRequestedShiftLever(value)),
    changeRequestedParkingBrake: value => dispatch(changeRequestedParkingBrake(value)),
    changeRequestedKeyType: value => dispatch(changeRequestedKeyType(value)),
    changeRequestedWithAirConditioner: value => dispatch(changeRequestedWithAirConditioner(value)),

    cancelEditSchedule: () => dispatch(cancelEditSchedule()),

    newSchedule: (userId, facilityId, facilityName, startTime, endTime, checkWarningAsInitialValue) =>
      dispatch(newSchedule(userId, facilityId, facilityName, startTime, endTime, checkWarningAsInitialValue)),

    editSchedule: schedule => dispatch(editSchedule(schedule)),

    saveSchedule: schedule => dispatch(saveSchedule(schedule)),

    addSchedule: schedule => dispatch(addSchedule(schedule)),

    confirmDeleteSchedule: scheduleId => dispatch(confirmDeleteSchedule(scheduleId)),
    cancelDeleteSchedule: scheduleId => dispatch(cancelDeleteSchedule(scheduleId)),
    deleteSchedule: (userId, scheduleId) => dispatch(deleteSchedule(userId, scheduleId)),

    closeFilterFacilityDialog: () => dispatch(closeFilterFacilityDialog()),
    openFilterFacilityDialog: () => dispatch(openFilterFacilityDialog()),
    changeFilterState: state => dispatch(changeFilterState(state)),
    closeSortFacilityDialog: () => dispatch(closeSortFacilityDialog()),
    openSortFacilityDialog: () => dispatch(openSortFacilityDialog()),
    changeSortState: state => dispatch(changeSortState(state)),
    toggleCarList: () => dispatch(toggleCarList()),
    openScheduleCardContext: itemContextObject => dispatch(openScheduleCardContext(itemContextObject)),
    closeScheduleCardContext: () => dispatch(closeScheduleCardContext()),
    openCanvasContext: canvasContextObject => dispatch(openCanvasContext(canvasContextObject)),
    closeCanvasContext: () => dispatch(closeCanvasContext()),
    copySchedule: schedule => dispatch(copySchedule(schedule)),

    changeDefaultStartEnd: (start, end) => dispatch(changeDefaultStartEnd(start, end)),

    hover: () => dispatch(hover()),
    hoverEnd: () => dispatch(hoverEnd()),
    hoverOnGroups: () => dispatch(hoverOnGroups()),
    hoverOnGroupsEnd: () => dispatch(hoverOnGroupsEnd()),

    scrollOnDrag: (scheduleId, scheduleStart, scheduleEnd, visibleTimeStart, visibleTimeEnd) =>
      dispatch(scrollOnDrag(scheduleId, scheduleStart, scheduleEnd, visibleTimeStart, visibleTimeEnd))
  })
)(Schedule);
