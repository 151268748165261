import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Urls } from '../../constant/url';

export const SuccessCreateAccount = (props) => (props.isLoggedIn
  ? (<Redirect to={Urls.Schedule.path} />)
  : (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h4" color="inherit" style={{ flexGrow: 1 }} />
          <Button color="inherit" component={Link} to={Urls.Login.path}>ログイン</Button>
          <Button color="inherit" component={Link} to="/">アカウント作成</Button>
        </Toolbar>
      </AppBar>
      <main>
        <Grid container spacing={2} direction="column" justify="flex-start" style={{ padding: 20 }}>

          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant="h5">
              Email認証用メール送信完了
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant="body1">
              { props.emailValidationMailWasSentTo }
              へEmail認証用メールを送信しました。
              <br />
              メールを確認してアカウント作成を完了してください。
              <br />
              しばらくしてもメールが届かない場合は、スパムフォルダをご確認ください。
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Button variant="contained" color="primary" component={Link} to="/">トップへ戻る</Button>
          </Grid>
        </Grid>
      </main>
    </div>
  ));
