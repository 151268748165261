import request from 'superagent';
import { Environment } from '../config/config';

function sendGuestSupportRequest(payload) {
  return new Promise((resolve, reject) => {
    request
      .post(`${Environment.ApiUrl}/guest_supports`)
      .type('form')
      .send({
        name: payload.name,
        email: payload.email,
        content: payload.content,
      })
      .end((err, res) => {
        if (err === null) {
          resolve({ status: res.status });
        } else {
          resolve({ status: res.status });
        }
      });
  });
}

export default {
  sendGuestSupportRequest,
};
