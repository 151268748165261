import React from 'react';
import { connect } from 'react-redux';
import { SuccessCreateAccount } from '../../components/pages/SuccessCreateAccount';

export default connect(
  (state) => ({
    emailValidationMailWasSentTo: state.createAccountReducer.emailValidationMailWasSentTo,
  }),

  (dispatch) => ({
  }),
)(SuccessCreateAccount);
