import React from 'react';
import { connect } from 'react-redux';
import { Support } from '../../components/pages/Support';
import {
  changeContent,
  changeEmail,
  changeName,
  sendRequest,
} from '../../actions/guestSupportActions';

export default connect(
  (state) => ({
    content: state.guestSupportReducer.content,
    email: state.guestSupportReducer.email,
    name: state.guestSupportReducer.name,
    isLoading: state.guestSupportReducer.isLoading,
    messages: state.guestSupportReducer.messages,
  }),
  {
    changeContent,
    changeEmail,
    changeName,
    sendRequest,
  },
)(Support);
