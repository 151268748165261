import { ActionTypes as actions } from '../actionTypes/guestSupportActionTypes';

export const changeEmail = (email) => ({
  type: actions.CHANGE_GUEST_SUPPORT_EMAIL,
  payload: { email },
  meta: {},
  error: false,
});

export const changeContent = (content) => ({
  type: actions.CHANGE_GUEST_SUPPORT_CONTENT,
  payload: { content },
  meta: {},
  error: false,
});

export const changeName = (name) => ({
  type: actions.CHANGE_GUEST_SUPPORT_NAME,
  payload: { name },
  meta: {},
  error: false,
});

export const sendRequest = (name, email, content) => ({
  type: actions.SEND_GUEST_SUPPORT_REQUEST,
  payload: { name, email, content },
  meta: {},
  error: false,
});

export const successSendRequest = (response) => ({
  type: actions.SUCCESS_SEND_GUEST_SUPPORT_REQUEST,
  payload: response,
  meta: {},
  error: false,
});

export const errorSendRequest = (messages) => ({
  type: actions.ERROR_SEND_GUEST_SUPPORT_REQUEST,
  payload: { messages },
  meta: {},
  error: true,
});
