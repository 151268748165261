import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import Notifications from '@material-ui/icons/Notifications';
import Close from '@material-ui/icons/Close';
import PeopleIcon from '@material-ui/icons/SupervisedUserCircle';
import PersonIcon from '@material-ui/icons/Person';
import Motorcycle from '@material-ui/icons/Motorcycle';
import Settings from '@material-ui/icons/Settings';
import CalenderIcon from '@material-ui/icons/CalendarToday';
import ContactMail from '@material-ui/icons/ContactMail';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { User } from '../types/user';
import { Urls } from '../constant/url';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  toolBar: {
    justifyContent: 'space-between',
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    marginTop: 48,
    padding: 10,
    maxWidth: '100%',
  },
}));

type HeaderForMemberProps = {
  logout: () => void;
  user: User;
  currentPath: string;
  mobileOpen: boolean;
  toggleMenu: () => void;
};

const headers = [
  { url: Urls.Schedule, icon: <CalenderIcon /> },
  { url: Urls.Customer, icon: <PersonIcon /> },
  { url: Urls.Staff, icon: <PeopleIcon /> },
  { url: Urls.Facility, icon: <Motorcycle /> },
  { url: Urls.Setting, icon: <Settings /> },
];

const HeaderForMember: FC<HeaderForMemberProps> = (props) => {
  const {
    logout, currentPath, mobileOpen, toggleMenu, children,
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const drawer = (
    <div>
      <IconButton color="inherit" aria-label="Open drawer" onClick={() => toggleMenu()}>
        <Close />
      </IconButton>
      <Divider />
      <List>
        {headers.map((h, i) => (
          <ListItem
            component={Link}
            to={h.url.path}
            button
            disabled={currentPath === h.url.path}
            key={i}
          >
            <ListItemIcon>
              { h.icon }
            </ListItemIcon>
            <ListItemText primary={h.url.name} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem
          component={Link}
          to={Urls.SupportForMember.path}
          button
          disabled={currentPath === Urls.SupportForMember.path}
        >
          <ListItemIcon>
            <ContactMail />
          </ListItemIcon>
          <ListItemText primary={Urls.SupportForMember.name} />
        </ListItem>
      </List>
    </div>
  );

  const url = Object.values(Urls).find((u) => u.path === currentPath);
  const pageName = url !== undefined ? url.name : '';

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar className={classes.toolBar} variant="dense">
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={() => toggleMenu()}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" noWrap>
            {pageName}
          </Typography>
          <Tooltip title={(
            <span style={{ fontSize: 16, lineHeight: '20px', whiteSpace: 'pre-wrap' }}>
              予約の新規作成・編集はダブルクリック・ダブルタップに変更しました。
            </span>
          )}
          >
            <Notifications style={{ color: 'darkorange' }} />
          </Tooltip>
          <Button color="inherit" onClick={() => logout()}>ログアウト</Button>
        </Toolbar>
      </AppBar>

      <nav>
        <Drawer
          variant="temporary"
          classes={{ paper: classes.drawerPaper }}
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={() => toggleMenu()}
          ModalProps={{ keepMounted: true }}
        >
          {drawer}
        </Drawer>
      </nav>
      <main className={classes.content}>
        {children}
      </main>
    </div>
  );
};

export default HeaderForMember;
