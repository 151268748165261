import { createAction } from 'redux-actions';
import { ActionTypes as actions } from '../actionTypes/settingActionTypes';

export const getSetting = () => ({
  type: actions.GET_SETTING,
  payload: {},
  meta: {},
  error: false,
});

export const successGetSetting = (setting, rentalReasonMasters) => ({
  type: actions.SUCCESS_GET_SETTING,
  payload: {
    setting,
    rentalReasonMasters,
  },
  meta: {},
  error: false,
});

export const errorGetSetting = (messages) => ({
  type: actions.ERROR_GET_SETTING,
  payload: { messages },
  meta: {},
  error: true,
});

export const saveSetting = (setting, rentalReasonMasters) => ({
  type: actions.SAVE_SETTING,
  payload: {
    setting,
    rentalReasonMasters,
  },
  meta: {},
  error: false,
});

export const errorSaveSetting = createAction(actions.ERROR_SAVE_SETTING);
export const successSaveSetting = () => ({
  type: actions.SUCCESS_SAVE_SETTING,
  payload: {},
  meta: {},
  error: false,
});

export const changeDragSnapValue = (value) => ({
  type: actions.CHANGE_DRAG_SNAP_VALUE,
  payload: { value },
  meta: {},
  error: false,
});

export const changeDragSnapUnit = (unit) => ({
  type: actions.CHANGE_DRAG_SNAP_UNIT,
  payload: { unit },
  meta: {},
  error: false,
});

export const changeHeaderFormat = (format) => ({
  type: actions.CHANGE_HEADER_FORMAT,
  payload: { format },
  meta: {},
  error: false,
});

export const changeShowStaffInCard = () => ({
  type: actions.CHANGE_SHOW_STAFF_IN_CARD,
  meta: {},
  error: false,
});

export const changeShowCustomerInCard = () => ({
  type: actions.CHANGE_SHOW_CUSTOMER_IN_CARD,
  meta: {},
  error: false,
});

export const changeShowCustomersCarInCard = () => ({
  type: actions.CHANGE_SHOW_CUSTOMERS_CAR_IN_CARD,
  meta: {},
  error: false,
});

export const changeLabelOfStaffInCard = (label) => ({
  type: actions.CHANGE_LABEL_OF_STAFF_IN_CARD,
  payload: { label },
  meta: {},
  error: false,
});

export const changeLabelOfCustomerInCard = (label) => ({
  type: actions.CHANGE_LABEL_OF_CUSTOMER_IN_CARD,
  payload: { label },
  meta: {},
  error: false,
});

export const changeLabelOfCustomersCarInCard = (label) => ({
  type: actions.CHANGE_LABEL_OF_CUSTOMERS_CAR_IN_CARD,
  payload: { label },
  meta: {},
  error: false,
});

export const changeDefaultCalenderSpan = (span) => ({
  type: actions.CHANGE_DEFAULT_CALENDER_SPAN,
  payload: { span },
  meta: {},
  error: false,
});

export const changeShowCarHintInCalendar = () => ({
  type: actions.CHANGE_SHOW_CAR_HINT_IN_CALENDER,
  meta: {},
  error: false,
});

export const changeShowScheduleHintInCard = () => ({
  type: actions.CHANGE_SHOW_SCHEDULE_HINT_IN_CARD,
  meta: {},
  error: false,
});

export const changeBanDoubleBook = () => ({
  type: actions.CHANGE_BAN_DOUBLE_BOOK,
  meta: {},
  error: false,
});

export const changeIsCardMovableSp = () => ({
  type: actions.CHANGE_IS_CARD_MOVABLE_SP,
  meta: {},
  error: false
});

export const changeIsCardMovablePc = () => ({
  type: actions.CHANGE_IS_CARD_MOVABLE_PC,
  meta: {},
  error: false
});

export const changeIsCardResizableSp = () => ({
  type: actions.CHANGE_IS_CARD_RESIZABLE_SP,
  meta: {},
  error: false
});

export const changeIsCardResizablePc = () => ({
  type: actions.CHANGE_IS_CARD_RESIZABLE_PC,
  meta: {},
  error: false
});

export const changeCheckWarningAsInitialValue = () => ({
  type: actions.CHANGE_CHECK_WARNING_AS_INITIAL_VALUE,
  meta: {},
  error: false,
});

export const changeRentalReasonName = (rentalReasonMasterId, name) => ({
  type: actions.CHANGE_RENTAL_REASON_NAME,
  payload: {
    rentalReasonMasterId,
    name,
  },
  meta: {},
  error: false,
});

export const moveRentalReasonMaster = (draggedId, hoveredId) => ({
  type: actions.MOVE_RENTAL_REASON_MASTER,
  payload: {
    draggedId,
    hoveredId,
  },
  meta: {},
  error: false,
});

export const changeRentalReasonColor = (rentalReasonMasterId, color) => ({
  type: actions.CHANGE_RENTAL_REASON_COLOR,
  payload: {
    rentalReasonMasterId,
    color,
  },
  meta: {},
  error: false,
});

export const openColorPallet = (rentalReasonMasterId) => ({
  type: actions.OPEN_COLOR_PALLET,
  payload: {
    rentalReasonMasterId,
  },
  meta: {},
  error: false,
});

export const addNewRentalReasonMaster = () => ({
  type: actions.ADD_NEW_RENTAL_REASON_MASTER,
  meta: {},
  error: false,
});

export const deleteRentalReasonMaster = (rentalReasonMasterId) => ({
  type: actions.DELETE_RENTAL_REASON_MASTER,
  payload: {
    rentalReasonMasterId,
  },
  meta: {},
  error: false,
});
