import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import Swatches from 'react-color/lib/Swatches';
import IconButton from '@material-ui/core/IconButton';
import {
  Close, DeleteForever, Edit, DragHandle,
} from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const itemType = 'rentalReasonMaster';
const useStyles = makeStyles((theme) => ({
  dragCircle: {
    width: 48,
    height: 48,
    borderRadius: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
  },
}));
const RentalReasonMasterItem = ({
  master,
  changeRentalReasonName,
  deleteRentalReasonMaster,
  changeRentalReasonColor,
  moveRentalReasonMaster,
  openedColorPalletId,
  openColorPallet,
}) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: itemType,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const draggedId = item.master.rentalReasonMasterId;
      const hoveredId = master.rentalReasonMasterId;

      if (draggedId === hoveredId) {
        return;
      }

      moveRentalReasonMaster(draggedId, hoveredId);

      const draggedSortNo = item.master.sortNo;
      const hoveredSortNo = master.sortNo;
      item.master.sortNo = hoveredSortNo;
      master.sortNo = draggedSortNo;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: itemType, master },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));

  const classes = useStyles();

  return (
    <Grid ref={ref} container key={master.rentalReasonMasterId} style={{ opacity: isDragging ? 0 : 1 }}>
      <Grid item xs={3}>
        <div ref={drag} className={classes.dragCircle}>
          <DragHandle />
        </div>
      </Grid>
      <Grid item xs={4}>
        <TextField
          margin="normal"
          label="貸出理由"
          value={master.name}
          onChange={(e) => changeRentalReasonName(master.rentalReasonMasterId, e.target.value)}
        />
      </Grid>
      <Grid item xs={3} style={{ padding: 2 }}>
        <div style={{ backgroundColor: master.colorCode, height: '100%' }}>
          <IconButton onClick={(e) => openColorPallet(master.rentalReasonMasterId)}>
            <Edit />
          </IconButton>
        </div>
        <Dialog
          open={openedColorPalletId === master.rentalReasonMasterId}
          onClose={(e) => openColorPallet(null)}
        >
          <MuiDialogTitle style={{ textAlign: 'right' }}>
            <IconButton onClick={(e) => openColorPallet(null)}>
              <Close />
            </IconButton>
          </MuiDialogTitle>
          <div>
            <Swatches
              color={master.colorCode}
              onChange={(color, event) => changeRentalReasonColor(master.rentalReasonMasterId, color)}
            />
          </div>
        </Dialog>
      </Grid>
      <Grid item xs={2}>
        <Fab color="primary" size="small" onClick={(e) => deleteRentalReasonMaster(master.rentalReasonMasterId)}>
          <DeleteForever fontSize="small" />
        </Fab>
      </Grid>
    </Grid>
  );
};

export default RentalReasonMasterItem;
