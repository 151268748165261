import React from 'react';

export const Success = ({ visible, message }) => {
  const styles = {
    position: 'relative',
    padding: '.75rem 1.25rem',
    marginBottom: '1rem',
    border: '1px solid transparent',
    borderRadius: '.25rem',
    color: '#155724',
    backgroundColor: '#d4edda',
    borderColor: '#c3e6cb',
  };

  const element = visible ? <div style={styles}>{ message.join('\n') }</div> : false;
  return (element);
};
