import React from 'react';
import { connect } from 'react-redux';
import { ForgetPassword } from '../../components/pages/ForgetPassword';
import { changeRegisteredEmail, sendPasswordReset } from '../../actions/loginActions';

export default connect(
  (state) => ({
    registeredEmail: state.loginReducer.registeredEmail,
    isLoading: state.loginReducer.isLoading,
    errorMessages: state.loginReducer.errorMessages,
  }),

  (dispatch) => ({
    changeRegisteredEmail: (email) => dispatch(changeRegisteredEmail(email)),
    sendPasswordReset: (email) => dispatch(sendPasswordReset(email)),
  }),
)(ForgetPassword);
