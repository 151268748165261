export const ActionTypes = {

  CHANGE_EMAIL: 'CHANGE_EMAIL',
  VALIDATE_EMAIL: 'VALIDATE_EMAIL',
  FINISH_EMAIL_VALIDATION: 'FINISH_EMAIL_VALIDATION',

  PASSWORD_FOCUSED_ONCE: 'PASSWORD_FOCUSED_ONCE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  VALIDATE_PASSWORD: 'VALIDATE_PASSWORD',
  FINISH_PASSWORD_VALIDATION: 'FINISH_PASSWORD_VALIDATION',

  PASSWORD_CONFIRM_FOCUSED_ONCE: 'PASSWORD_CONFIRM_FOCUSED_ONCE',
  CHANGE_PASSWORD_CONFIRM: 'CHANGE_PASSWORD_CONFIRM',
  VALIDATE_PASSWORD_CONFIRM: 'VALIDATE_PASSWORD_CONFIRM',
  FINISH_PASSWORD_CONFIRM_VALIDATION: 'FINISH_PASSWORD_CONFIRM_VALIDATION',

  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  SUCCESS_CREATE_ACCOUNT: 'SUCCESS_CREATE_ACCOUNT',
  ERROR_CREATE_ACCOUNT: 'ERROR_CREATE_ACCOUNT',
  CONFIRM_EMAIL_VERIFICATION: 'CONFIRM_EMAIL_VERIFICATION',

};
