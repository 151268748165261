import React from 'react';
import { connect } from 'react-redux';
import { ConfirmEmailVerification } from '../../components/pages/ConfirmEmailVerification';
import { confirmEmailVerification } from '../../actions/createAccountActions';

export default connect(
  (state) => ({
  }),

  (dispatch) => ({
    confirmEmailVerification: (token) => dispatch(confirmEmailVerification(token)),
  }),
)(ConfirmEmailVerification);
