import {
  call, put, select, takeEvery, delay,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { Urls } from '../constant/url';
import { ActionTypes as types } from '../actionTypes/scheduleActionTypes';
import { ActionTypes as commonTypes } from '../actionTypes/actionTypes';
import API from '../api/api';
import {
  errorAddSchedule,
  errorDeleteSchedule,
  errorGetSchedules,
  errorSaveSchedule, GET_SCHEDULES,
  successAddSchedule,
  successDeleteSchedule,
  successGetSchedules,
  successSaveSchedule, updateCanvasTime,
} from '../actions/scheduleActions';
import * as actions from '../actions/actions';
import settingApi from '../api/settingApi';
import api from '../api/rentalReasonMastersApi';
import moment from "moment";

function* locationChangeAsync(action) {
  const path = action.payload.location.pathname;
  const state = yield select();
  if (path === Urls.Schedule.path && state.loginReducer.isLoggedIn) {
    const token = localStorage.getItem('TOKEN');
    const settingResponse = yield call(settingApi.getSetting, { userId: state.userReducer.user.id, token });
    if (settingResponse.status === 200 && settingResponse.setting !== null) {
      const { setting } = settingResponse;
      const now = moment().local();
      const boundStart = now.clone()
        .add(-setting.defaultCalenderSpan, "days");
      const boundEnd = now.clone()
        .add(setting.defaultCalenderSpan * 2, "days");

      yield put(updateCanvasTime(boundStart.valueOf(), boundEnd.valueOf()));

      const customerResponse = yield call(API.getCustomers, { userId: state.userReducer.user.id, token });
      const staffResponse = yield call(API.getStaffs, { userId: state.userReducer.user.id, token });
      const scheduleResponse = yield call(API.getSchedules,
        { userId: state.userReducer.user.id, token, start: boundStart, end: boundEnd });
      const facilityResponse = yield call(API.getFacilities, { userId: state.userReducer.user.id, token });
      const rentalReasonMasterResponse = yield call(api.getRentalReasonMasters, { userId: state.userReducer.user.id, token });

      if (customerResponse.status === 200 && staffResponse.status === 200 && scheduleResponse.status === 200 && facilityResponse.status === 200) {
        yield put(successGetSchedules(scheduleResponse.schedules, customerResponse.customers,
          facilityResponse.facilities, staffResponse.staffs, settingResponse.setting, rentalReasonMasterResponse.rentalReasonMasters));
      } else if (customerResponse.status === 401 || staffResponse.status === 401 || scheduleResponse.status === 401 || facilityResponse.status === 401) {
        yield put(actions.clearState());
        yield put(push(Urls.Login.path));
      } else { yield put(errorGetSchedules(scheduleResponse.messages)); }
    } else if (settingResponse.status === 401) {
      yield put(actions.clearState());
      yield put(push(Urls.Login.path));
    } else {
      yield put(errorGetSchedules(settingResponse.messages));
    }
  }
}

function* watchLocationChangeAsync() {
  yield takeEvery(commonTypes.LOCATION_CHANGE, locationChangeAsync);
}

function* getSchedulesAsync(action) {
  yield delay(500);
  const state = yield select();
  if (state.loginReducer.isLoggedIn) {
    const token = localStorage.getItem('TOKEN');
    const settingResponse = yield call(settingApi.getSetting, { userId: state.userReducer.user.id, token });
    if (settingResponse.status === 200 && settingResponse.setting !== null) {
      const customerResponse = yield call(API.getCustomers, { userId: state.userReducer.user.id, token });
      const staffResponse = yield call(API.getStaffs, { userId: state.userReducer.user.id, token });
      const scheduleResponse = yield call(API.getSchedules,
        { userId: state.userReducer.user.id, token, start: action.payload.start, end: action.payload.end });
      const facilityResponse = yield call(API.getFacilities, { userId: state.userReducer.user.id, token });
      const rentalReasonMasterResponse = yield call(api.getRentalReasonMasters, { userId: state.userReducer.user.id, token });

      if (customerResponse.status === 200 && staffResponse.status === 200 && scheduleResponse.status === 200 && facilityResponse.status === 200) {
        yield put(successGetSchedules(scheduleResponse.schedules, customerResponse.customers,
          facilityResponse.facilities, staffResponse.staffs, settingResponse.setting, rentalReasonMasterResponse.rentalReasonMasters));
      } else if (customerResponse.status === 401 || staffResponse.status === 401 || scheduleResponse.status === 401 || facilityResponse.status === 401) {
        yield put(actions.clearState());
        yield put(push(Urls.Login.path));
      } else { yield put(errorGetSchedules(scheduleResponse.messages)); }
    } else if (settingResponse.status === 401) {
      yield put(actions.clearState());
      yield put(push(Urls.Login.path));
    } else {
      yield put(errorGetSchedules(settingResponse.messages));
    }
  }
}

function* watchGetSchedulesAsync() {
  yield takeEvery(GET_SCHEDULES, getSchedulesAsync);
}

function* addScheduleAsync(action) {
  const token = localStorage.getItem('TOKEN');
  const response = yield call(API.addSchedule, { token, schedule: action.payload.schedule });

  if (response.status === 200) {
    const savedSchedule = {
      ...action.payload.schedule,
      scheduleId: response.scheduleId,
    };
    yield put(successAddSchedule(savedSchedule));
  } else if (response.status === 401) {
    yield put(actions.clearState());
    yield put(push(Urls.Login.path));
  } else { yield put(errorAddSchedule(response.messages)); }
}

function* watchAddScheduleAsync() {
  yield takeEvery(types.ADD_SCHEDULE, addScheduleAsync);
}

function* saveScheduleAsync(action) {
  const token = localStorage.getItem('TOKEN');
  const { schedule } = action.payload;
  const response = yield call(API.saveSchedule, { token, schedule });

  if (response.status === 200) { yield put(successSaveSchedule(schedule)); } else if (response.status === 401) {
    yield put(actions.clearState());
    yield put(push(Urls.Login.path));
  } else { yield put(errorSaveSchedule(response.messages)); }
}

function* watchSaveScheduleAsync() {
  yield takeEvery(types.SAVE_SCHEDULE, saveScheduleAsync);
}

function* deleteScheduleAsync(action) {
  const token = localStorage.getItem('TOKEN');
  const data = action.payload;
  const response = yield call(API.deleteSchedule, { userId: data.userId, scheduleId: data.scheduleId, token });

  if (response.status === 200) { yield put(successDeleteSchedule(data.scheduleId)); } else { yield put(errorDeleteSchedule(response.messages)); }
}

function* watchDeleteScheduleAsync() {
  yield takeEvery(types.DELETE_SCHEDULE, deleteScheduleAsync);
}

export const scheduleSaga = [
  watchLocationChangeAsync(),
  watchGetSchedulesAsync(),
  watchAddScheduleAsync(),
  watchSaveScheduleAsync(),
  watchDeleteScheduleAsync(),
];
