import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Urls } from '../../constant/url';

export const ConfirmEmailVerification = withRouter((props) => {
  const {
    confirmEmailVerification,
    match,
  } = props;

  useEffect(() => {
    confirmEmailVerification(match.params.token);
  }, [confirmEmailVerification, match.params.token]);

  return (
    <div style={{ opacity: 0.3 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h4" color="inherit" style={{ flexGrow: 1 }} />
          <Button color="inherit" component={Link} to={Urls.Login.path}>ログイン</Button>
          <Button color="inherit" component={Link} to="/">アカウント作成</Button>
        </Toolbar>
      </AppBar>
      <main>
        <Grid container spacing={2} direction="column" justify="flex-start" style={{ margin: 20 }}>

          <Grid item xs={12} style={{ position: 'relative' }}>

            <CircularProgress
              color="primary"
              style={{
                position: 'fixed', top: '50%', left: '50%', zIndex: 1400,
              }}
            />
          </Grid>

        </Grid>
      </main>
    </div>
  );
});
