import React from 'react';
import { connect } from 'react-redux';
import { Staff } from '../../../components/pages/member/Staff';
import {
  addStaff,
  cancelDeleteStaff,
  cancelEditStaff,
  changeStaffMemo,
  changeStaffName,
  confirmDeleteStaff,
  deleteStaff,
  confirmRetireStaff,
  cancelRetireStaff,
  editStaff,
  newStaff,
  saveStaff,
} from '../../../actions/staffActions';

export default connect(
  (state) => ({
    staffs: state.staffReducer.staffs,
    staffName: state.staffReducer.staffName,
    messages: state.staffReducer.messages,
    userId: state.userReducer.user.id,
    confirmDeleteId: state.staffReducer.confirmDeleteId,
    confirmRetireId: state.staffReducer.confirmRetireId,
    editingStaff: state.staffReducer.editingStaff,
    isLoading: state.staffReducer.isLoading,
  }),
  (dispatch) => ({
    changeStaffName: (name) => dispatch(changeStaffName(name)),
    changeStaffMemo: (memo) => dispatch(changeStaffMemo(memo)),

    newStaff: () => dispatch(newStaff()),
    editStaff: (staff) => dispatch(editStaff(staff)),
    cancelEditStaff: () => dispatch(cancelEditStaff()),
    addStaff: (staff) => dispatch(addStaff(staff)),
    saveStaff: (staff) => dispatch(saveStaff(staff)),

    deleteStaff: (userId, staffId) => dispatch(deleteStaff(userId, staffId)),
    cancelDeleteStaff: () => dispatch(cancelDeleteStaff()),
    confirmDeleteStaff: (staffId) => dispatch(confirmDeleteStaff(staffId)),
    confirmRetireStaff: (staffId) => dispatch(confirmRetireStaff(staffId)),
    cancelRetireStaff: () => dispatch(cancelRetireStaff()),

  }),
)(Staff);
