import React, { memo } from "react";
import moment from "moment";
import withWidth from "@material-ui/core/withWidth";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { DriveSystem, TransmissionTypes } from "../../constant/common";

const useStyle = makeStyles((theme) => ({
  container: {
    height: "100%",
    width: "100%",
    lineHeight: 1.1,
    whiteSpace: "normal",
    fontSize: "0.9em",
  },
  containerMt: {
    height: "100%",
    width: "100%",
    lineHeight: 1.1,
    whiteSpace: "normal",
    fontSize: "0.9em",
    backgroundColor: "lightblue",
  },
  carNumber: {
    fontSize: 22,
  },
  transmissionType: {
    fontSize: 16,
  },
  transmissionTypeMt: {
    fontSize: 16,
    color: "blue",
  },
  trialCarMark: {
    fontSize: 12,
    borderRadius: 8,
    border: "solid 1px green",
    color: "green",
    fontWeight: "bold",
  },
  loanerCarMark: {
    fontSize: 12,
    borderRadius: 8,
    border: "solid 1px blue",
    color: "blue",
    fontWeight: "bold",
  },
}));

const CarListItem = memo(({ car, width }) => {
  const carNumber =
    car.registrationNumberPlace +
    car.registrationNumberMobileType +
    car.registrationNumberMobileUses +
    car.registrationNumberNumber;
  const driveSystem = DriveSystem.find((s) => s.value === car.driveSystem);
  const driveSystemName = driveSystem === undefined ? "--" : driveSystem.key;
  const transmissionType = TransmissionTypes.find(
    (t) => t.value === car.carType
  );
  const transmissionTypeName =
    transmissionType === undefined ? "--" : transmissionType.key;
  const otherSpecs = `${driveSystemName} ${transmissionTypeName} ${car.color}`;
  const today = moment();
  const restDays = car.expireDate.diff(today, "days");
  const isExpired = car.expireDate <= today;

  const intel = new Intl.DateTimeFormat("ja-JP-u-ca-japanese", { era: "long" });
  const expireDate = intel.format(car.expireDate.toDate());

  const expireDateColor = isExpired
    ? "red"
    : restDays <= 30
    ? "darkorange"
    : "black";

  const classes = useStyle();
  return width === "lg" || width === "xl" ? (
    <div
      className={car.carType === 2 ? classes.containerMt : classes.container}
    >
      <Grid container>
        <Grid item lg={1} xl={1} className={classes.carNumber}>
          {car.no === "" ? "" : `${car.no}.`}
        </Grid>
        <Grid item lg={2} xl={2}>
          <span
            className={
              car.carType === 2
                ? classes.transmissionTypeMt
                : classes.transmissionType
            }
          >
            {transmissionTypeName}
          </span>
          &nbsp;
          {driveSystemName}
        </Grid>
        <Grid item lg={6} xl={6}>
          {car.use === 1 && <span className={classes.loanerCarMark}>代</span>}
          {car.use === 2 && <span className={classes.trialCarMark}>試</span>}
          {car.name}
          {car.color}
          <br />
          <span style={{ color: expireDateColor }}>{expireDate}</span>
        </Grid>
        <Grid item lg={3} xl={3}>
          {carNumber}
        </Grid>
      </Grid>
    </div>
  ) : (
    <div
      className={car.carType === 2 ? classes.containerMt : classes.container}
    >
      {car.no === "" ? "" : `${car.no}.`}
      {car.use === 1 && <span className={classes.loanerCarMark}>代</span>}
      {car.use === 2 && <span className={classes.trialCarMark}>試</span>}
      {` ${car.name}`}
      {carNumber !== "" && (
        <>
          <br />
          {carNumber}
        </>
      )}
      {otherSpecs !== "" && (
        <>
          <br />
          {transmissionTypeName}
          {driveSystemName}
          {car.color}
        </>
      )}
      <br />
      <span style={{ color: expireDateColor }}>{expireDate}</span>
    </div>
  );
});

export default withWidth()(CarListItem);
