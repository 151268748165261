import { connectRouter, routerMiddleware } from 'connected-react-router';
import {
  createStore, applyMiddleware, compose, combineReducers,
} from 'redux';
import { logger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/rootSaga';

import { ActionTypes } from '../actionTypes/actionTypes';
import { loginReducer } from '../reducers/loginReducer';
import { userReducer } from '../reducers/userReducer';
import { createAccountReducer } from '../reducers/createAccountReducer';
import { customerReducer } from '../reducers/customerReducer';
import { staffReducer } from '../reducers/staffReducer';

import { uiReducer } from '../reducers/uiReducer';
import { facilityReducer } from '../reducers/facilityReducer';
import { scheduleReducer } from '../reducers/scheduleReducer';
import { settingReducer } from '../reducers/settingReducer';
import { guestSupportReducer } from '../reducers/guestSupportReducer';
import { memberSupportReducer } from '../reducers/memberSupportReducer';

export const rootStore = (history) => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewareForRouter = routerMiddleware(history);

  const combinedReducer = combineReducers({
    loginReducer,
    createAccountReducer,
    userReducer,
    customerReducer,
    staffReducer,
    facilityReducer,
    scheduleReducer,
    settingReducer,
    uiReducer,
    guestSupportReducer,
    memberSupportReducer,
    router: connectRouter(history),
  });

  const rootReducer = (state, action) => {
    if (action.type === ActionTypes.CLEAR_STATE) {
      state = {
        ...state,
        loginReducer: undefined,
        createAccountReducer: undefined,
        userReducer: undefined,
        customerReducer: undefined,
        staffReducer: undefined,
        facilityReducer: undefined,
        scheduleReducer: undefined,
        settingReducer: undefined,
        guestSupportReducer: undefined,
        memberSupportReducer: undefined,
        uiReducer: undefined,
      };
    }

    return combinedReducer(state, action);
  };

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const initialState = {};
  const store = process.env.NODE_ENV === 'development'
    ? createStore(
      rootReducer,
      initialState,
      composeEnhancers(applyMiddleware(
        sagaMiddleware,
        logger,
        middlewareForRouter,
      )),
    )
    : createStore(
      rootReducer,
      initialState,
      applyMiddleware(
        sagaMiddleware,
        middlewareForRouter,
      ),
    );

  sagaMiddleware.run(rootSaga);

  return store;
};
