import React from 'react';
import { connect } from 'react-redux';
import { ResetPassword } from '../../components/pages/ResetPassword';
import {
  checkValidityToChangePassword,
  changeNewPassword,
  changeNewPasswordConfirm,
  registerNewPassword,
  validateNewPassword,
  validateNewPasswordConfirm,
} from '../../actions/loginActions';

export default connect(
  (state) => ({
    newPassword: state.loginReducer.newPassword,
    newPasswordConfirm: state.loginReducer.newPasswordConfirm,
    isLoading: state.loginReducer.isLoading,
    errorServerMessage: state.loginReducer.errorServerMessage,
    errorNewPasswordMessage: state.loginReducer.errorNewPasswordMessage,
    errorNewPasswordConfirmMessage: state.loginReducer.errorNewPasswordConfirmMessage,
  }),

  (dispatch) => ({
    checkValidityToChangePassword: (id) => dispatch(checkValidityToChangePassword(id)),
    changeNewPassword: (pass) => dispatch(changeNewPassword(pass)),
    changeNewPasswordConfirm: (pass) => dispatch(changeNewPasswordConfirm(pass)),
    registerNewPassword: (id, password, passwordConfirm) => dispatch(registerNewPassword(id, password, passwordConfirm)),
    validateNewPassword: (pass) => dispatch(validateNewPassword(pass)),
    validateNewPasswordConfirm: (pass) => dispatch(validateNewPasswordConfirm(pass)),
  }),
)(ResetPassword);
