import { fromJS, List } from "immutable";
import { Urls } from "../constant/url";
import { createReducer, updateState } from "./utility";

export const initialFacilityState = {
  facilities: [],
  facilityName: "",
  carType: 0,
  messages: [],
  confirmDeleteId: -1,
  confirmScrapId: -1,
  editingFacility: null,
  isLoading: false,
};

const locationChange = (state, action) => {
  if (action.payload.location.pathname === Urls.Facility.path) {
    return updateState(state, { isLoading: true, messages: [] });
  }

  return state;
};

const updateFacilities = (state, action) =>
  updateState(state, {
    facilities: action.payload.facilities,
    isLoading: false,
  });

const errorGetFacilities = (state, action) =>
  updateState(state, { messages: action.payload.messages, isLoading: false });

const changeFacilityName = (state, action) =>
  fromJS(state).setIn(["editingFacility", "name"], action.payload.name).toJS();

const changeFacilityNo = (state, action) =>
  fromJS(state)
    .setIn(
      ["editingFacility", "no"],
      action.payload.no !== "" ? action.payload.no : 0
    )
    .toJS();

const changeFacilityColor = (state, action) =>
  fromJS(state)
    .setIn(["editingFacility", "color"], action.payload.color)
    .toJS();

const changeFacilityCarType = (state, action) =>
  fromJS(state)
    .setIn(["editingFacility", "carType"], action.payload.carType)
    .toJS();

const changeExpireDate = (state, action) =>
  fromJS(state)
    .setIn(["editingFacility", "expireDate"], action.payload.expireDate)
    .toJS();

const changeFacilityMemo = (state, action) =>
  fromJS(state).setIn(["editingFacility", "memo"], action.payload.memo).toJS();

const changeFacilityWeightType = (state, action) =>
  fromJS(state)
    .setIn(["editingFacility", "weightType"], action.payload.weightType)
    .toJS();

const changeFacilityFreightType = (state, action) =>
  fromJS(state)
    .setIn(["editingFacility", "freightType"], action.payload.freightType)
    .toJS();

const changeRegistrationNumberPlace = (state, action) =>
  fromJS(state)
    .setIn(
      ["editingFacility", "registrationNumberPlace"],
      action.payload.registrationNumberPlace
    )
    .toJS();

const changeRegistrationNumberMobileType = (state, action) =>
  fromJS(state)
    .setIn(
      ["editingFacility", "registrationNumberMobileType"],
      action.payload.registrationNumberMobileType
    )
    .toJS();

const changeRegistrationNumberMobileUses = (state, action) =>
  fromJS(state)
    .setIn(
      ["editingFacility", "registrationNumberMobileUses"],
      action.payload.registrationNumberMobileUses
    )
    .toJS();

const changeRegistrationNumberNumber = (state, action) =>
  fromJS(state)
    .setIn(
      ["editingFacility", "registrationNumberNumber"],
      action.payload.registrationNumberNumber
    )
    .toJS();

const changeFacilityDriveSystem = (state, action) =>
  fromJS(state)
    .setIn(["editingFacility", "driveSystem"], action.payload.driveSystem)
    .toJS();

const changeFacilityShiftLever = (state, action) =>
  fromJS(state)
    .setIn(["editingFacility", "shiftLever"], action.payload.shiftLever)
    .toJS();

const changeFacilityParkingBrake = (state, action) =>
  fromJS(state)
    .setIn(["editingFacility", "parkingBrake"], action.payload.parkingBrake)
    .toJS();

const changeFacilityKeyType = (state, action) =>
  fromJS(state)
    .setIn(["editingFacility", "keyType"], action.payload.keyType)
    .toJS();

const changeFacilityWithAirConditioner = (state, action) =>
  fromJS(state)
    .setIn(
      ["editingFacility", "withAirConditioner"],
      action.payload.withAirConditioner
    )
    .toJS();

const changeFirstRegistrationDate = (state, action) =>
  fromJS(state)
    .setIn(
      ["editingFacility", "firstRegistrationDate"],
      action.payload.firstRegistrationDate
    )
    .toJS();

const changeFacilityUse = (state, action) =>
  fromJS(state).setIn(["editingFacility", "use"], action.payload.use).toJS();

const newFacility = (state, action) =>
  updateState(state, { editingFacility: action.payload });

const editFacility = (state, action) =>
  updateState(state, { editingFacility: action.payload });

const cancelEditFacility = (state, action) =>
  updateState(state, { editingFacility: null });

const addFacility = (state, action) => updateState(state, { isLoading: true });

const successAddFacility = (state, action) => {
  const addedState = fromJS(state)
    .update("facilities", (facilities) =>
      facilities.push(action.payload.facility)
    )
    .set("editingFacility", null)
    .set("isLoading", false)
    .toJS();

  return addedState;
};

const errorAddFacility = (state, action) =>
  updateState(state, { messages: action.payload.messages, isLoading: false });

const saveFacility = (state, action) => updateState(state, { isLoading: true });

const successSaveFacility = (state, action) => {
  const { facility } = action.payload;
  const copyState = fromJS(state);
  const idx = copyState
    .get("facilities")
    .findIndex((f) => f.get("facilityId") === facility.facilityId);
  const newState = copyState
    .setIn(["facilities", idx], facility)
    .set("isLoading", false)
    .set("editingFacility", null)
    .toJS();

  return newState;
};

const errorSaveFacility = (state, action) =>
  updateState(state, { messages: action.payload.messages, isLoading: false });

const confirmScrapFacility = (state, action) =>
  updateState(state, { confirmScrapId: action.payload.facilityId });

const cancelScrapFacility = (state, action) =>
  updateState(state, { confirmScrapId: -1 });

const cancelDeleteFacility = (state, action) =>
  updateState(state, { confirmDeleteId: -1 });

const confirmDeleteFacility = (state, action) =>
  updateState(state, { confirmDeleteId: action.payload.facilityId });

const deleteFacility = (state, action) =>
  updateState(state, { confirmDeleteId: -1, isLoading: true });

const successDeleteFacility = (state, action) => {
  const deletedList = List(state.facilities)
    .filter((f) => f.facilityId !== action.payload.facilityId)
    .toJS();
  return updateState(state, { facilities: deletedList, isLoading: false });
};

const errorDeleteFacility = (state, action) =>
  updateState(state, { messages: action.payload.messages, isLoading: false });

const handlers = {
  "@@router/LOCATION_CHANGE": locationChange,
  SUCCESS_GET_SCHEDULES: updateFacilities,
  SUCCESS_GET_FACILITIES: updateFacilities,
  ERROR_GET_FACILITIES: errorGetFacilities,

  CHANGE_FACILITY_NAME: changeFacilityName,
  CHANGE_FACILITY_NO: changeFacilityNo,
  CHANGE_FACILITY_COLOR: changeFacilityColor,
  CHANGE_FACILITY_CAR_TYPE: changeFacilityCarType,
  CHANGE_EXPIRE_DATE: changeExpireDate,
  CHANGE_FACILITY_MEMO: changeFacilityMemo,

  CHANGE_FACILITY_WEIGHT_TYPE: changeFacilityWeightType,
  CHANGE_FACILITY_FREIGHT_TYPE: changeFacilityFreightType,
  CHANGE_REGISTRATION_NUMBER_PLACE: changeRegistrationNumberPlace,
  CHANGE_REGISTRATION_NUMBER_MOBILE_TYPE: changeRegistrationNumberMobileType,
  CHANGE_REGISTRATION_NUMBER_MOBILE_USES: changeRegistrationNumberMobileUses,
  CHANGE_REGISTRATION_NUMBER_NUMBER: changeRegistrationNumberNumber,
  CHANGE_FACILITY_DRIVE_SYSTEM: changeFacilityDriveSystem,
  CHANGE_FACILITY_SHIFT_LEVER: changeFacilityShiftLever,
  CHANGE_FACILITY_PARKING_BRAKE: changeFacilityParkingBrake,
  CHANGE_FACILITY_KEY_TYPE: changeFacilityKeyType,
  CHANGE_FACILITY_WITH_AIR_CONDITIONER: changeFacilityWithAirConditioner,
  CHANGE_FIRST_REGISTRATION_DATE: changeFirstRegistrationDate,
  CHANGE_FACILITY_USE: changeFacilityUse,

  NEW_FACILITY: newFacility,
  EDIT_FACILITY: editFacility,
  CANCEL_EDIT_FACILITY: cancelEditFacility,

  ADD_FACILITY: addFacility,
  SUCCESS_ADD_FACILITY: successAddFacility,
  ERROR_ADD_FACILITY: errorAddFacility,

  SAVE_FACILITY: saveFacility,
  SUCCESS_SAVE_FACILITY: successSaveFacility,
  ERROR_SAVE_FACILITY: errorSaveFacility,

  CONFIRM_SCRAP_FACILITY: confirmScrapFacility,
  CANCEL_SCRAP_FACILITY: cancelScrapFacility,

  CANCEL_DELETE_FACILITY: cancelDeleteFacility,
  CONFIRM_DELETE_FACILITY: confirmDeleteFacility,

  DELETE_FACILITY: deleteFacility,
  SUCCESS_DELETE_FACILITY: successDeleteFacility,
  ERROR_DELETE_FACILITY: errorDeleteFacility,
};

export const facilityReducer = createReducer(initialFacilityState, handlers);
