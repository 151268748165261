import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withRouter } from 'react-router';
import { Urls } from '../../constant/url';
import { Error } from '../message/Error';

export const ResetPassword = withRouter((props) => {
  const {
    checkValidityToChangePassword,
    match,
    newPassword,
    changeNewPassword,
    newPasswordConfirm,
    changeNewPasswordConfirm,
    registerNewPassword,
    validateNewPassword,
    validateNewPasswordConfirm,
    errorServerMessage,
    errorNewPasswordMessage,
    errorNewPasswordConfirmMessage,
    isLoading,
  } = props;

  useEffect(() => {
    checkValidityToChangePassword(match.params.id);
  }, [checkValidityToChangePassword, match.params.id]);

  return props.isLoggedIn
    ? (<Redirect to={Urls.Schedule.path} />)
    : (
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h4" color="inherit" style={{ flexGrow: 1 }} />
            <Button color="inherit" component={Link} to={Urls.Login.path}>ログイン</Button>
            <Button color="inherit" component={Link} to="/">アカウント作成</Button>
          </Toolbar>
        </AppBar>
        <main>
          <Grid container spacing={2} direction="column" justify="flex-start" style={{ padding: 20 }}>

            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography variant="h5">
                パスワード再設定
              </Typography>
              <Error visible={errorServerMessage.length > 0} message={errorServerMessage} />
            </Grid>

            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <TextField
                label="新しいパスワード"
                type="password"
                value={newPassword}
                onChange={(e) => changeNewPassword(e.target.value)}
                onBlur={() => validateNewPassword(newPassword)}
                style={{ width: 240 }}
              />
              <Typography color="textSecondary">
                半角英数字8〜12文字で入力してください。
              </Typography>
              <Error visible={errorNewPasswordMessage.length > 0} message={errorNewPasswordMessage} />
            </Grid>

            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <TextField
                label="新しいパスワード(確認)"
                type="password"
                value={newPasswordConfirm}
                onChange={(e) => changeNewPasswordConfirm(e.target.value)}
                onBlur={() => validateNewPasswordConfirm(newPasswordConfirm)}
                style={{ width: 240 }}
              />
              <Error visible={errorNewPasswordConfirmMessage.length > 0} message={errorNewPasswordConfirmMessage} />
            </Grid>

            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                color="primary"
                disabled={isLoading}
                onClick={(e) => registerNewPassword(match.params.id, newPassword, newPasswordConfirm)}
              >
                パスワードを変更
              </Button>
            </Grid>
          </Grid>
        </main>
      </div>
    );
});
