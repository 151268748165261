import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Urls } from '../../../constant/url';

export const SuccessRequestMemberSupport = (props) => (
  <Grid container spacing={2} direction="column" justify="flex-start" style={{ padding: 20 }}>

    <Grid item xs={12} style={{ textAlign: 'center' }}>
      <Typography variant="h5">
        お問合せメール送信完了
      </Typography>
    </Grid>

    <Grid item xs={12} style={{ textAlign: 'center' }}>
      <Typography variant="body1">
        ご連絡ありがとうございます。
        内容を確認次第、早急に担当者よりお返事いたします。
      </Typography>
    </Grid>

    <Grid item xs={12} style={{ textAlign: 'center' }}>
      <Button variant="contained" color="primary" component={Link} to={Urls.Schedule.path}>メインページへ戻る</Button>
    </Grid>
  </Grid>
);
