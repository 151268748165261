import { createReducer, updateState } from './utility';

export const initialUiState = {
  content: '',
  email: '',
  name: '',
  isLoading: false,
  messages: [],
};

const changeName = (state, action) => updateState(state, { name: action.payload.name });
const changeEmail = (state, action) => updateState(state, { email: action.payload.email });
const changeContent = (state, action) => updateState(state, { content: action.payload.content });
const sendRequest = (state, action) => updateState(state, { isLoading: true });
const successSendRequest = (state, action) => updateState(state, {
  isLoading: false, name: '', email: '', content: '',
});
const errorSendRequest = (state, action) => updateState(state, { messages: action.payload.messages, isLoading: false });

const handlers = {
  CHANGE_GUEST_SUPPORT_EMAIL: changeEmail,
  CHANGE_GUEST_SUPPORT_CONTENT: changeContent,
  CHANGE_GUEST_SUPPORT_NAME: changeName,
  SEND_GUEST_SUPPORT_REQUEST: sendRequest,
  SUCCESS_SEND_GUEST_SUPPORT_REQUEST: successSendRequest,
  ERROR_SEND_GUEST_SUPPORT_REQUEST: errorSendRequest,
};

export const guestSupportReducer = createReducer(initialUiState, handlers);
