import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Urls } from '../../constant/url';

export const SuccessRequestGuestSupport = (props) => (
  <div>
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h4" color="inherit" style={{ flexGrow: 1 }} />
        <Button color="inherit" component={Link} to={Urls.Login.path}>ログイン</Button>
        <Button color="inherit" component={Link} to="/">アカウント作成</Button>
      </Toolbar>
    </AppBar>
    <main>
      <Grid container spacing={2} direction="column" justify="flex-start" style={{ padding: 20 }}>

        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant="h5">
            お問合せメール送信完了
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant="body1">
            ご連絡ありがとうございます。
            内容を確認次第、早急に担当者よりお返事いたします。
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button variant="contained" color="primary" component={Link} to="/">トップへ戻る</Button>
        </Grid>
      </Grid>
    </main>
  </div>
);
