import { ActionTypes as actions } from '../actionTypes/memberSupportActionTypes';

export const changeContent = (content) => ({
  type: actions.CHANGE_MEMBER_SUPPORT_CONTENT,
  payload: { content },
  meta: {},
  error: false,
});

export const changeName = (name) => ({
  type: actions.CHANGE_MEMBER_SUPPORT_NAME,
  payload: { name },
  meta: {},
  error: false,
});

export const sendRequest = (name, content) => ({
  type: actions.SEND_MEMBER_SUPPORT_REQUEST,
  payload: { name, content },
  meta: {},
  error: false,
});

export const successSendRequest = (response) => ({
  type: actions.SUCCESS_SEND_MEMBER_SUPPORT_REQUEST,
  payload: response,
  meta: {},
  error: false,
});

export const errorSendRequest = (messages) => ({
  type: actions.ERROR_SEND_MEMBER_SUPPORT_REQUEST,
  payload: { messages },
  meta: {},
  error: true,
});
