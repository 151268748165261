import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { CommonDropDown } from './CommonDropDown';
import { Error } from './message/Error';
import {
  TransmissionTypes, DriveSystem, FreightType, WeightType, ShiftLever, ParkingBrake, KeyType, WithOrWithout, Use,
} from '../constant/common';

export const FacilityEditDialog = ({
  userId, title, facility, isOpen,
  cancelEditFacility, addFacility, saveFacility,
  changeFacilityNo, changeFacilityName, changeFacilityColor,
  changeFacilityCarType, changeFacilityWeightType, changeFacilityFreightType,
  changeExpireDate, changeFacilityMemo,
  changeRegistrationNumberPlace, changeRegistrationNumberMobileType,
  changeRegistrationNumberMobileUses, changeRegistrationNumberNumber,
  changeFacilityDriveSystem, changeFacilityShiftLever,
  changeFacilityParkingBrake, changeFacilityKeyType,
  changeFacilityWithAirConditioner,
  changeFirstRegistrationDate,
  changeFacilityUse,
  isLoading, messages,
}) => {
  if (facility === null) { return false; }

  const canSave = facility.carType < 0 || !facility.name || !facility.expireDate.isValid();

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={(e) => cancelEditFacility(e)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle id="alert-dialog-title" disableTypography>
          <Typography variant="h4">
            {title}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <div style={{ position: 'relative' }}>

            <Grid
              container
              spacing={2}
              style={{
                opacity: isLoading ? 0.3 : 1,
                height: '100%',
                width: '100%',
                marginTop: 40,
              }}
            >

              {messages.length > 0
              && (
              <Grid item xs={12}>
                <Error visible={messages.length > 0} message={messages} />
              </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="番号"
                  type="number"
                  value={facility.no}
                  onChange={(e) => changeFacilityNo(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  label="車名"
                  value={facility.name}
                  onChange={(e) => changeFacilityName(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="色"
                  value={facility.color}
                  onChange={(e) => changeFacilityColor(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <CommonDropDown
                  showLabel
                  fullWidth={true}
                  defaultValue={0}
                  list={Use}
                  label="用途"
                  inputId={`use-${facility.facilityId.toString()}`}
                  value={facility.use}
                  onChange={(e) => changeFacilityUse(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">
                  登録番号
                </Typography>
              </Grid>

              <Grid item lg={3} md={3} sm={12}>
                <TextField
                  margin="normal"
                  label="使用の本拠地"
                  placeholder="品川"
                  value={facility.registrationNumberPlace}
                  onChange={(e) => changeRegistrationNumberPlace(e.target.value)}
                />
              </Grid>

              <Grid item lg={3} md={3} sm={12}>

                <TextField
                  margin="normal"
                  label="種別分類番号"
                  placeholder="500"
                  value={facility.registrationNumberMobileType}
                  onChange={(e) => changeRegistrationNumberMobileType(e.target.value)}
                />
              </Grid>

              <Grid item lg={3} md={3} sm={12}>

                <TextField
                  margin="normal"
                  label="事業用判別文字"
                  placeholder="さ"
                  value={facility.registrationNumberMobileUses}
                  onChange={(e) => changeRegistrationNumberMobileUses(e.target.value)}
                />
              </Grid>

              <Grid item lg={3} md={3} sm={12}>
                <TextField
                  margin="normal"
                  label="指定番号"
                  placeholder="2345"
                  value={facility.registrationNumberNumber}
                  onChange={(e) => changeRegistrationNumberNumber(e.target.value)}
                />
              </Grid>

              <Grid item lg={3} md={6} sm={12}>
                <CommonDropDown
                  showLabel
                  fullWidth
                  defaultValue={-1}
                  list={TransmissionTypes}
                  label="マニュアル/オートマ"
                  inputId={`at_mt_type${facility.facilityId.toString()}`}
                  value={facility.carType}
                  onChange={(e) => changeFacilityCarType(e.target.value)}
                />
              </Grid>

              <Grid item lg={3} md={6} sm={12}>
                <CommonDropDown
                  showLabel
                  fullWidth
                  defaultValue={-1}
                  list={WeightType}
                  label="軽自動車・普通"
                  inputId={`weight-type${facility.facilityId.toString()}`}
                  value={facility.weightType}
                  onChange={(e) => changeFacilityWeightType(e.target.value)}
                />
              </Grid>

              <Grid item lg={3} md={6} sm={12}>
                <CommonDropDown
                  showLabel
                  fullWidth
                  defaultValue={-1}
                  list={FreightType}
                  label="車体の形状"
                  inputId={`freight-type${facility.facilityId.toString()}`}
                  value={facility.freightType}
                  onChange={(e) => changeFacilityFreightType(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="expire-date"
                  label="車検期限"
                  type="date"
                  fullWidth
                  value={facility.expireDate.format('YYYY-MM-DD')}
                  onChange={(e) => changeExpireDate(moment(e.target.value))}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="first-registration-date"
                  label="初年度"
                  type="month"
                  fullWidth
                  value={facility.firstRegistrationDate === null
                    ? null
                    : facility.firstRegistrationDate.format('YYYY-MM')}
                  onChange={(e) => {
                    const newDate = moment(e.target.value);
                    changeFirstRegistrationDate(newDate.isValid() ? newDate : null);
                  }}
                />
              </Grid>

              <Grid item lg={3} md={6} sm={12}>
                <CommonDropDown
                  showLabel
                  fullWidth
                  defaultValue={-1}
                  list={DriveSystem}
                  label="駆動方式"
                  inputId={`drive_system${facility.facilityId.toString()}`}
                  value={facility.driveSystem}
                  onChange={(e) => changeFacilityDriveSystem(e.target.value)}
                />
              </Grid>

              <Grid item lg={3} md={6} sm={12}>
                <CommonDropDown
                  showLabel
                  fullWidth
                  defaultValue={-1}
                  list={ShiftLever}
                  label="シフトレバー"
                  inputId={`shift_lever${facility.facilityId.toString()}`}
                  value={facility.shiftLever}
                  onChange={(e) => changeFacilityShiftLever(e.target.value)}
                />
              </Grid>

              <Grid item lg={3} md={6} sm={12}>
                <CommonDropDown
                  showLabel
                  fullWidth
                  defaultValue={-1}
                  list={ParkingBrake}
                  label="パーキングブレーキ"
                  inputId={`parking_brake${facility.facilityId.toString()}`}
                  value={facility.parkingBrake}
                  onChange={(e) => changeFacilityParkingBrake(e.target.value)}
                />
              </Grid>

              <Grid item lg={3} md={6} sm={12}>
                <CommonDropDown
                  showLabel
                  fullWidth
                  defaultValue={-1}
                  list={KeyType}
                  label="キータイプ"
                  inputId={`key_type${facility.facilityId.toString()}`}
                  value={facility.keyType}
                  onChange={(e) => changeFacilityKeyType(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <CommonDropDown
                  showLabel
                  fullWidth
                  defaultValue={-1}
                  list={WithOrWithout}
                  label="エアコンの有無"
                  inputId={`air_conditioner${facility.facilityId.toString()}`}
                  value={facility.withAirConditioner}
                  onChange={(e) => changeFacilityWithAirConditioner(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="memo"
                  label="メモ"
                  margin="normal"
                  variant="outlined"
                  multiline
                  fullWidth
                  value={facility.memo}
                  onChange={(e) => changeFacilityMemo(e.target.value)}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          {addFacility === null ? false
            : (
              <Button
                color="primary"
                variant="contained"
                disabled={canSave}
                onClick={(e) => addFacility(userId, facility)}
              >
                保存
              </Button>
            )}

          {saveFacility === null ? false
            : (
              <Button
                color="primary"
                variant="contained"
                disabled={canSave}
                onClick={(e) => saveFacility(userId, facility)}
              >
                保存
              </Button>
            )}

          <Button onClick={(e) => cancelEditFacility(e)} color="default" autoFocus variant="contained">
            キャンセル
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  );
};
