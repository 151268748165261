export const ActionTypes = {

  SUCCESS_GET_SCHEDULES: 'SUCCESS_GET_SCHEDULES',
  ERROR_GET_SCHEDULES: 'ERROR_GET_SCHEDULES',

  NEW_SCHEDULE: 'NEW_SCHEDULE',
  ADD_SCHEDULE: 'ADD_SCHEDULE',
  SUCCESS_ADD_SCHEDULE: 'SUCCESS_ADD_SCHEDULE',
  ERROR_ADD_SCHEDULE: 'ERROR_ADD_SCHEDULE',
  CHANGE_SELECTED_FACILITY: 'CHANGE_SELECTED_FACILITY',
  CHANGE_SELECTED_STAFF: 'CHANGE_SELECTED_STAFF',
  CHANGE_SELECTED_CUSTOMER: 'CHANGE_SELECTED_CUSTOMER',
  CHANGE_FREE_FIRST_NAME: 'CHANGE_FREE_FIRST_NAME',
  CHANGE_FREE_LAST_NAME: 'CHANGE_FREE_LAST_NAME',
  CHANGE_CUSTOMERS_CAR: 'CHANGE_CUSTOMERS_CAR',
  CHANGE_MEMO: 'CHANGE_MEMO',
  CHANGE_SELECTED_RENTAL_REASON: 'CHANGE_SELECTED_RENTAL_REASON',
  CHANGE_START: 'CHANGE_START',
  CHANGE_END: 'CHANGE_END',

  CHANGE_REQUESTED_CAR_TYPE: 'CHANGE_REQUESTED_CAR_TYPE',
  CHANGE_REQUESTED_WEIGHT_TYPE: 'CHANGE_REQUESTED_WEIGHT_TYPE',
  CHANGE_REQUESTED_FREIGHT_TYPE: 'CHANGE_REQUESTED_FREIGHT_TYPE',
  CHANGE_REQUESTED_DRIVE_SYSTEM: 'CHANGE_REQUESTED_DRIVE_SYSTEM',
  CHANGE_REQUESTED_SHIFT_LEVER: 'CHANGE_REQUESTED_SHIFT_LEVER',
  CHANGE_REQUESTED_PARKING_BRAKE: 'CHANGE_REQUESTED_PARKING_BRAKE',
  CHANGE_REQUESTED_KEY_TYPE: 'CHANGE_REQUESTED_KEY_TYPE',
  CHANGE_REQUESTED_WITH_AIR_CONDITIONER: 'CHANGE_REQUESTED_WITH_AIR_CONDITIONER',

  CANCEL_EDIT_SCHEDULE: 'CANCEL_EDIT_SCHEDULE',

  EDIT_SCHEDULE: 'EDIT_SCHEDULE',
  SAVE_SCHEDULE: 'SAVE_SCHEDULE',
  SUCCESS_SAVE_SCHEDULE: 'SUCCESS_SAVE_SCHEDULE',
  ERROR_SAVE_SCHEDULE: 'ERROR_SAVE_SCHEDULE',

  CANCEL_DELETE_SCHEDULE: 'CANCEL_DELETE_SCHEDULE',
  CONFIRM_DELETE_SCHEDULE: 'CONFIRM_DELETE_SCHEDULE',
  DELETE_SCHEDULE: 'DELETE_SCHEDULE',
  SUCCESS_DELETE_SCHEDULE: 'SUCCESS_DELETE_SCHEDULE',
  ERROR_DELETE_SCHEDULE: 'ERROR_DELETE_SCHEDULE',

  CHANGE_FILTER_TYPE: 'CHANGE_FILTER_TYPE',
  TOGGLE_CAR_LIST: 'TOGGLE_CAR_LIST',
  OPEN_SCHEDULE_CARD_CONTEXT: 'OPEN_SCHEDULE_CARD_CONTEXT',
  CLOSE_SCHEDULE_CARD_CONTEXT: 'CLOSE_SCHEDULE_CARD_CONTEXT',
  OPEN_CANVAS_CONTEXT: 'OPEN_CANVAS_CONTEXT',
  CLOSE_CANVAS_CONTEXT: 'CLOSE_CANVAS_CONTEXT',
  COPY_SCHEDULE: 'COPY_SCHEDULE',

  CHANGE_FILTER_STATE: 'CHANGE_FILTER_STATE',
  OPEN_FILTER_FACILITY_DIALOG: 'OPEN_FILTER_FACILITY_DIALOG',
  CLOSE_FILTER_FACILITY_DIALOG: 'CLOSE_FILTER_FACILITY_DIALOG',

  CHANGE_SORT_STATE: 'CHANGE_SORT_STATE',
  CHANGE_DEFAULT_TIME_START_END: 'CHANGE_DEFAULT_TIME_START_END',
  OPEN_SORT_FACILITY_DIALOG: 'OPEN_SORT_FACILITY_DIALOG',
  CLOSE_SORT_FACILITY_DIALOG: 'CLOSE_SORT_FACILITY_DIALOG',

  CHANGE_WARNING: 'CHANGE_WARNING',

  HOVER: 'HOVER',
  HOVER_END: 'HOVER_END',
  HOVER_ON_GROUPS: 'HOVER_ON_GROUPS',
  HOVER_ON_GROUPS_END: 'HOVER_ON_GROUPS_END',

  SCROLL_ON_DRAG: 'SCROLL_ON_DRAG',

};
