import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Img from '../../../img/top6-10.jpg';
import { Urls } from '../../constant/url';
import { Error } from '../message/Error';

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

const boxWidth = 360;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${Img})`,
    backgroundSize: 'cover',
    backgroundPosition: '100% 80%',
    width: '100vw',
    height: '100vh',
    position: 'relative',
  },
  buttonBox: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 16,
  },
  loginButtonRoot: {
    color: theme.palette.primary.contrastText,
  },
  box: {
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
    width: 300,
    padding: 20,
    borderRadius: 6,
    marginTop: 40,
    backgroundColor: 'white',
  },
  boxLeftLarge: {
    top: '56%',
    left: '16%',
    width: boxWidth,
    height: boxWidth * 1.618,
  },
  boxLeftMiddleAndSmall: {
    top: '44%',
    left: '50%',
    width: 300,
  },
}));

export const CreateAccount = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const width = useWidth();
  const boxLeftClass = width === 'xl' || width === 'lg'
    ? classes.boxLeftLarge
    : classes.boxLeftMiddleAndSmall;

  return props.isLoggedIn
    ? (<Redirect to={Urls.Schedule.path} />)
    : (
      <div className={classes.root}>
        <div className={classes.buttonBox}>
          <Button
            classes={{ root: classes.loginButtonRoot }}
            onClick={() => history.push('/login')}
          >
            ログイン
          </Button>
          <Button
            classes={{ root: classes.loginButtonRoot }}
            onClick={() => history.push('/support')}
          >
            お問合せ
          </Button>
          <Button
            classes={{ root: classes.loginButtonRoot }}
            href="https://qiita.com/manipulative/items/06ad94562f9ed245b58c"
            target="_blank"
            rel="noopener noreferrer"
          >
            サービスを支える技術
          </Button>
        </div>

        <main>
          <div>
            <Grid container spacing={2} className={`${classes.box} ${boxLeftClass}`}>

              <Grid item xs={12}>
                <Typography variant="h4" component="h1" color="textPrimary">
                  車検・代車の管理を始めましょう。
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h5" component="h2" color="textPrimary">
                  アカウント作成
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="standard-mail"
                  label="メールアドレス"
                  margin="normal"
                  fullWidth
                  value={props.email}
                  onChange={(e) => props.changeEmail(e)}
                  onBlur={() => props.validateEmail(props.email)}
                />

                <Error
                  visible={props.errorEmailMessage.length > 0}
                  message={props.errorEmailMessage}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="standard-password-input"
                  label="パスワード"
                  type="password"
                  autoComplete="new-password"
                  fullWidth
                  value={props.password}
                  onFocus={(e) => props.passwordFocusedOnce(e)}
                  onChange={(e) => props.changePassword(e)}
                  onBlur={() => props.validatePassword(props.password)}
                />
                <Typography color="textSecondary">
                  半角英数字記号8文字以上で入力してください。
                </Typography>
                <Error
                  visible={props.errorPasswordMessage.length > 0}
                  message={props.errorPasswordMessage}
                />

              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="standard-password-confirm-input"
                  label="パスワード確認"
                  type="password"
                  autoComplete="new-password"
                  fullWidth
                  value={props.passwordConfirm}
                  onFocus={(e) => props.passwordConfirmFocusedOnce(e)}
                  onChange={(e) => props.changePasswordConfirm(e)}
                  onBlur={() => props.validatePasswordConfirm(props.passwordConfirm)}
                />

                <Error
                  visible={props.errorPasswordConfirmMessage.length > 0}
                  message={props.errorPasswordConfirmMessage}
                />
              </Grid>
              <Grid item xs={12}>

                <Error
                  visible={props.errorServerMessage.length > 0}
                  message={props.errorServerMessage}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={(e) => props.createAccount(props.password, props.passwordConfirm, props.email)}
                >
                  アカウント作成
                </Button>
              </Grid>
            </Grid>
          </div>
        </main>
      </div>
    );
};
